import React, { useState } from "react";
import "./DonutChart.scss";
import Professional_Services from "../../../Icons/expense-categ/Professional-Services.png";
import ExpenseCard from "../../../components/ExpenseCard/ExpenseCard";
import Model from "@/components/Modal/Model";
import Donut from "@/components/Chart/DonutChart/Donut";
import customKeyMapping from "assets/icon/SVG/category";
import { categMap } from "data/data";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FormatNumber } from "Util/FormatName";

function DonutChart(props) {
  const { data, laoding } = props;
  const [isModel, setModel] = useState(false);

  let expenseList = null;
  if (!laoding) {
    expenseList = data.map((each) => {
      return {
        icons: Professional_Services,
        name: each.category,
        value: each.totalAmount,
      };
    });
  }
  const modelHandler = () => {
    setModel((prev) => !prev);
  };

  return (
    <div className="donut-container">
      {isModel && <Model data={expenseList} closeModel={modelHandler} />}
      <div className="donut-head">
        <div className="donut-heading">
          <p>Expense by Category</p>
        </div>
        <div className="donut-see-all">
          <p onClick={modelHandler}>See all</p>
        </div>
      </div>
      <div className="donut-body">
        <div className="donut-expense">
          {laoding ? (
            <div>
              <ExpenseCard loading /> <ExpenseCard loading />{" "}
              <ExpenseCard loading />
            </div>
          ) : (
            expenseList?.map((ele,idx) => {
              return (
                <ExpenseCard
                  icons={categMap[ele.name].icons}
                  name={ele.name}
                  value={"$" + FormatNumber(ele.value)}
                  loading={laoding}
                  key={idx}
                />
              );
            })
          )}
        </div>

        <div className="donut-chart">
          {laoding ? (
            <Skeleton circle={true} className="donut-circle" />
          ) : (
            <Donut data={expenseList} />
          )}
        </div>
      </div>
    </div>
  );
}

export default DonutChart;
