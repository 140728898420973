import { useState, useEffect } from "react";
import "./Login.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Home from "../../Icons/home 2.png";
import Taxfluence from "../../assets/icon/SVG/SVG_ICONS/Taxfluence.svg";
import axios from "axios";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Grid,
  Alert,
  Snackbar,
  Box,
  Tab,
  Button,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
  Checkbox,
  Link,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { trackUserMP,trackUserMPwithEmail } from "../../services/mixpanel";

const defaultTheme = createTheme();

export default function Login() {
  const [isShows, setIsShows] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOtp, setOtp] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState({});
  const [count, setCount] = useState(30);
  const [isResend, setResend] = useState(false);
  const [value, setValue] = useState("0");
  const handleChangeTab = (newValue) => {
    setValue(newValue);
  };
  const [formData, setFormData] = useState({
    id: "",
    password: "",
  });

  const navigate = useNavigate();
  
  const handleChange = (event) => {
    event.preventDefault();
    setValidation((prev) => ({
      ...prev,
      [name]: "",
    }));
    setIsError(false);
    setMessage("");
    const { name, value } = event.target;
    if (name === "checkbox") {
      //save token to system if value is "on"
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    //API for post data to backend
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/login`,
          formData
        )
        .then((res) => {
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            setValidation((prev) => ({
              ...prev,
              ...data.error,
            }));
            if (data.message) {
              setIsError(true);
              setMessage(data.message);
            }
            return;
          }
          setIsLoading(false);
          setisSuccess(true);
          setMessage("Login Successful");
          trackUserMPwithEmail('identify user',formData.email)
          window.location.href = "/";
          localStorage.setItem("userInfo", JSON.stringify(data));
          return;
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMessage("Login Failed!");
      setIsError(true);
      return;
    }
  };

  const HandleSendOTP = (event) => {
    setResend(false);
    setCount(30);
    event.preventDefault();
    setIsLoading(true);
    if (!formData.phone || formData.phone.length < 10) {
      setIsLoading(false);
      setValidation((prev) => ({
        ...prev,
        ["phone"]: "Please Enter valid Number",
      }));
      return;
    }
    //API for post data to backend
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/otp/login/send`,
          formData
        )
        .then((res) => {
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            setValidation((prev) => ({
              ...prev,
              ...data.error,
            }));
            if (data.message) {
              setIsError(true);
              setMessage(data.message);
            }
            return;
          }
          const newInterval = setInterval(() => {
            setCount((prev) => prev - 1);
          }, 1000);
          setTimeout(() => {
            clearInterval(newInterval);
            setResend(true);
          }, 30000);
          setIsLoading(false);
          setisSuccess(true);
          setMessage(data.message);
          setOtp(true);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMessage("OTP Sending Failed!");
      setIsError(true);
      return;
    }
  };

  const handleSubmitPhone = (event) => {
    event.preventDefault();
    setIsLoading(true);
    //API for post data to backend
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/otp/login`,
          formData
        )
        .then((res) => {
          const { data } = res;
          if (!data.success) {
            setIsLoading(false);
            setValidation((prev) => ({
              ...prev,
              ...data.error,
            }));
            return;
          }
          setIsLoading(false);
          setisSuccess(true);
          setMessage("Login Successful");
          // navigate("/");
          window.location.href = "/";
          localStorage.setItem("userInfo", JSON.stringify(data));
          return;
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMessage("Login Failed!");
      setIsError(true);
      return;
    }
  };

  useEffect(() => {
    trackUserMP("Sign In");
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        maxHeight: "100vh",
        display: "flex",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          minWidth: "100%",
          minHeight: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box
          item
          sx={{
            backgroundColor: "#B0D3FF",
            height: "100%",
            width: "130%",
            paddingTop: "8rem",
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Box
            component="img"
            alt="side-image"
            src={Home}
            sx={{
              width: "90%",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100",
            width: "100%",
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            justifyContent: {
              xs: "center",
            },
            marginTop: {
              sm: "2%",
              md: "0px",
            },
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={isSuccess}
            autoHideDuration={4000}
            onClose={() => setisSuccess("")}
          >
            <Alert severity="success">{message}</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={isError}
            autoHideDuration={4000}
            onClose={() => setIsError("")}
          >
            <Alert severity="error">{message}</Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mt: "30px" }}>
                <img
                  style={{ height: { xs: "1.4rem", md: "1.6rem" } }}
                  src={Taxfluence}
                />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "800",
                  fontSize: "20px",
                  lineHeight: "36px",
                  height: "40px",
                  mt: "10px",
                  mb: "20px",
                }}
              >
                Sign in to your account
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 1,
                width: { xs: "100%", sm: "35vw", lg: "25vw" },
              }}
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    margin: 0,
                    overflow: "scroll",
                    color: "#272727",
                    fontWeight: 500,
                  }}
                >
                  <TabList
                    onChange={(event, newValue) => handleChangeTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    allowScrollButtonsMobile
                    centered
                  >
                    <Tab label="Phone" value="0" sx={{ fontWeight: "550" }} />
                    <Tab label="Email" value="1" sx={{ fontWeight: "550" }} />
                  </TabList>
                </Box>
                <TabPanel value="1" style={{ padding: 0, paddingTop: "1rem" }}>
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: { xs: "100%", sm: "35vw", lg: "25vw" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          gap: "25px",
                        }}
                      >
                        <Box>
                          <Typography sx={{ marginBottom: "7px" }}>
                            Email Address
                          </Typography>
                          <TextField
                            name="email"
                            onChange={handleChange}
                            placeholder="email@gmail.com"
                            size="small"
                            fullWidth
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                            error={validation.email}
                            helperText={validation.email}
                          />
                        </Box>
                        <Box>
                          <Typography>Password</Typography>
                          <TextField
                            name="password"
                            onChange={handleChange}
                            autoComplete="false"
                            placeholder="Enter Password"
                            error={validation.password}
                            helperText={validation.password}
                            size="small"
                            fullWidth
                            type={isShows ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  onClick={() => setIsShows((prev) => !prev)}
                                  sx={{ cursor: "pointer" }}
                                >
                                  {isShows ? <VisibilityOff /> : <Visibility />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: "10px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox name="checkbox" onChange={handleChange} />
                          }
                          label="Remember me"
                        />
                        <Link
                          onClick={() => navigate("/forgotpassword")}
                          underline="none"
                          sx={{ fontWeight: "500", cursor: "pointer" }}
                        >
                          {"Forgot my Password"}
                        </Link>
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                        sx={{
                          backgroundColor: "#2C96EA",
                          margin: "50px 0 0 0",
                          fontWeight: "600",
                          textTransform: "none",
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress size="1.5rem" color="inherit" />
                        ) : (
                          "Sign In"
                        )}
                      </Button>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value="0" style={{ padding: 0, paddingTop: "1rem" }}>
                  <form onSubmit={isOtp ? handleSubmitPhone : HandleSendOTP}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: { xs: "100%", sm: "35vw", lg: "25vw" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          gap: "25px",
                        }}
                      >
                        <Box>
                          <Typography sx={{ marginBottom: "7px" }}>
                            Phone Number
                          </Typography>
                          <TextField
                            name="phone"
                            type="tel"
                            onChange={handleChange}
                            placeholder="Phone Number"
                            size="small"
                            pattern="[0-9]*"
                            fullWidth
                            error={validation.phone}
                            disabled={isOtp}
                            helperText={validation.phone}
                          />
                        </Box>
                        {isOtp && (
                          <Box>
                            <Typography>OTP</Typography>
                            <TextField
                              name="otp"
                              onChange={handleChange}
                              pattern="[0-9]*"
                              autoComplete="false"
                              placeholder="Enter OTP"
                              error={validation.otp}
                              helperText={validation.otp}
                              type="tel"
                              size="small"
                              fullWidth
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        {isOtp && (
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#2C96EA",
                              margin: "50px 0 0 0",
                              fontWeight: "600",
                              textTransform: "none",
                              width: "100%",
                            }}
                            disabled={!isResend}
                            onClick={HandleSendOTP}
                          >
                            {`Resend OTP ${count > 0 ? count : ""}`}
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isLoading}
                          sx={{
                            backgroundColor: "#2C96EA",
                            margin: "50px 0 0 0",
                            fontWeight: "600",
                            textTransform: "none",
                            width: "100%",
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress size="1.5rem" color="inherit" />
                          ) : isOtp ? (
                            "Sign In"
                          ) : (
                            "Send OTP"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </TabPanel>
              </TabContext>
            </Box>
            <Typography sx={{ mt: "20px" }}>
              Don't have an account?
              <Link
                onClick={() => navigate("/signup")}
                underline="none"
                sx={{ fontWeight: "500", cursor: "pointer" }}
              >
                {" Sign Up"}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
