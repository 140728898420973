import React, { useState } from "react";
import AmericanExp from "../../Icons/cards/American Express.svg";
import More from "../../Icons/more.png";

function LinkedAccountMobile({
  icons = AmericanExp,
  status = "Active",
  cardName = "Main Credit Card",
  cardType = "Credit Card",
  LastUpadte = "Updated 4 Days Ago",
  Mask_Num = "**** **** **** 1234",
  id = 2,
  deleteCardHandler,
  acc_id,
  OpendModel,
  CloseModel,
  isMore = false,
  selectedID
}) {
  const onClickDelete = () => {
    if (window.confirm("Are you sure you want to delete this card?")) {
      deleteCardHandler(acc_id);
    }
  };

  return (
    <div className="border rounded-3 bg-white p-3 d-flex flex-row gap-3 align-items-center position-relative" onClick={CloseModel}>
      <div className="col-1">
        <img
          style={{ height: "2rem" }}
          className="img-fluid"
          src={icons}
          alt="card_logo"
        />
      </div>
      {isMore && selectedID==id && (
        <div
          className="more-model"
          style={{
            position: "absolute",
            right: "10px",
            top: "15px",
            background: "white",
            padding: "10px",
            color: "red",
            borderRadius: "5px",
            border: "1px solid red",
            textAlign: "center",
            difplay: "flex",
            width:"100px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
          }}
        >
          <div className="d-flex flex-row gap-2 align-content-center justify-content-center">
          <i className="bi bi-trash"></i> 
            <div style={{ fontSize: "12px" }} onClick={onClickDelete}>
              Delete
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-column gap-1 col-11">
        <div className="col-12 pe-3 d-flex flex-row justify-content-between">
          {status == "Active" ? (
            <div
              className=" pe-3 ps-3 fw-bolder rounded-4"
              style={{
                backgroundColor: "#e6f8ee",
                color: "#21885e",
                fontSize: "12px",
              }}
            >
              {status}
            </div>
          ) : (
            <div
              className=" pe-3 ps-3 fw-bolder rounded-4"
              style={{
                backgroundColor: "#F2CDCA",
                color: "#FF1000",
                fontSize: "12px",
              }}
            >
              {status}
            </div>
          )}
          <div onClick={(e)=>OpendModel(e,id)}>
            <img
              style={{ height: "1.2rem" }}
              className="img-fluid"
              src={More}
              alt="card_logo"
            />
          </div>
        </div>
        <div className="d-flex fs-6 fw-bolder flex-row gap-3 justify-content-between">
          {cardName}
        </div>
        <div className="d-flex text-muted pe-3 flex-row gap-3 justify-content-between">
          <div style={{ fontSize: "14px" }}>{cardType}</div>
          <div style={{ fontSize: "14px" }}>{LastUpadte}</div>
        </div>
      </div>
    </div>
  );
}

export default LinkedAccountMobile;
