import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  
  },

};

const labels = Array.from({ length: 30 }, (_, i) => i + 1); // Create an array from 1 to 100

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10 })),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};


export default function Index(props) {

  const {loader} = props;
 

  if(loader) {
  return <div className="chart-skeleton">
  <Skeleton height='23vh' />
</div>

  }

  const {days,values} = props.data;

  const edittedData = values?.map((ele)=>`$${ele}`)

  const data = {
    labels:days,
    datasets: [
      {
        label: 'Dataset 1',
        data: values,
        borderColor: '#2C96EA',
        backgroundColor: '#2C96EA',
      }
    ],
  };



  // console.log('data==',data)
  return (
    <Line  options={options} data={data} />
  );
}
