import React, { createContext, useContext, useReducer } from "react";
import moment from 'moment-timezone';

// Function to get user info from localStorage
const getUserInfo = () => {
  const userInfo = localStorage.getItem("userInfo");
  return JSON.parse(userInfo) || {};
};
//setTime zone
let newDate = new Date();
let month = (0 + (newDate.getMonth() + 1).toString()).slice(-2);
let day = '01';
let year = newDate.getFullYear();

// Initial state
const initialState = {
  User: getUserInfo(),
  BearerToken: getUserInfo()?.token || "",
  globalFilter: {
    startDate: `${year}-${month}-${day}`,
    endDate:moment().tz("America/Los_Angeles").format("YYYY-MM-DD"),
    name: "This month",
  },
  PlaidLinkToken: "",
};

// Create context
const AppContext = createContext();

// Reducer function
function appReducer(store, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...store, User: action.payload };
    case "SET_BEARER_TOKEN":
      return { ...store, BearerToken: action.payload };
    case "SET_FILTER":
      // Update globalFilter in both state and session storage
      const updatedStore = { ...store, globalFilter: action.payload };
      sessionStorage.setItem("globalFilter", JSON.stringify(action.payload));
      return updatedStore;
    case "SET_PLAIDLINKED_TOKEN":
      return { ...store, PlaidLinkToken: action.payload };
    default:
      return store;
  }
}

// Context provider component
export function AppProvider({ children }) {
  // Check sessionStorage for globalFilter
  const storedFilter = JSON.parse(sessionStorage.getItem("globalFilter")) || {};

  // Use storedFilter if available, otherwise use initialState
  const [store, dispatch] = useReducer(appReducer, {
    ...initialState,
    globalFilter: { ...initialState.globalFilter, ...storedFilter },
  });

  return (
    <AppContext.Provider value={[store, dispatch]}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
