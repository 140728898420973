import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { TypeAnimation } from "react-type-animation";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
ReactGA.initialize('AW-11434864199/MBqICMK4j4cZEMfcyMwq');

const Loading = ({email}) => {
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/contact`, {
        contacts: [
          {
            email:email,
            custom_fields: {
              Bank_account_added: "true",
            },
          },
        ],
        list_id : "97443b11-35fd-4742-af9c-fa20570d6911",
      },
      {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .catch((error) => {
        console.log(error)
        return;
      });
  }, []);

  useEffect(()=>{
    ReactPixel.track('CompleteRegistration');
    TagManager.dataLayer({
      dataLayer: {
        event:'CompleteRegistration',
        myVariable:'CompleteRegistration',
      },
    });

    ReactGA.event({
      category: 'User',
      action: 'CompleteRegistration'
    });

  },[])

  return (
    <Backdrop
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
      open={true}
      onClick={() => {}}
    >
      <CircularProgress color="primary" />
      <TypeAnimation
        sequence={[
          // Same substring at the start will only be typed out once, initially
          "Loading",
          1000, // wait 1s before replacing "Mice" with "Hamsters"
          "Loading.",
          1000,
          "Loading..",
          1000,
          "Loading...",
          1000,
        ]}
        wrapper="span"
        speed={50}
        style={{ fontSize: "1.2em", display: "inline-block" }}
        repeat={Infinity}
        cursor={false}
      />
    </Backdrop>
  );
};

export default Loading;
