import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Profile.scss";
import Service from "services/services";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAppContext } from "Store/AppContext";
import { useNavigate } from "react-router-dom";
const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

const Profile = () => {
  const [formData, setFormData] = useState({});
  const [store, dispatch] = useAppContext();
  const [isloading, setLoading] = useState(false);
  const [isSucc, setSucc] = useState(false);
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    sessionStorage.removeItem("globalFilter");
    dispatch({
      type: "SET_USER",
      payload: {},
    });
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFormData({
          ...res.data.user,
          last_name: res.data.user.name?.split(" ")[1] || "",
          name: res.data.user.name?.split(" ")[0],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return;
      });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setSucc(false);
  };

  const handleDeleteUser = () => {
    if (window.confirm("Are you sure you want to delete the account ?")) {
      setLoading(true);
      axios
        .delete(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/profile`,{
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          localStorage.removeItem("userInfo")
          setLoading(false);
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return;
        });
    }
  };

  const handleSave = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/profile`,
        { ...formData, name: `${formData.name} ${formData.last_name}` },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setSucc(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return;
      });
  };

  return (
    <div className="profile-container">
      <div className="profile-head">
        <div className="profile-heading">
          <h1 className="profile-heading-text fw-bolder">Profile Settings</h1>
        </div>
        <div className="profile-heading-btn">
          <Button className="btn1" variant="outlined" style={{color:"#0371F7",fontWeight:"500"}}>
            Cancel
          </Button>
          <Button className="btn2" variant="contained" onClick={handleSave}>
            Save Changes
          </Button>
        </div>
      </div>
      <div className="profile-person-info">
        <div className="personal-info">
          <text className="personal-info-heading">Personal Info</text>
          <p className="personal-info-para">
            Please provide your basic personal information. This helps us to
            personalize your experience and ensure seamless communication with.
            Your data is stored securely.
          </p>
          <div className="d-flex flex-row gap-2">
            <Button
              className="btn3"
              color="error"
              variant="contained"
              onClick={handleDeleteUser}
            >
              Delete Account
            </Button>
            <Button
              className="btn2 bg-danger"
              style={{ fontSize: "0.7rem" }}
              variant="contained"
              onClick={logoutHandler}
            >
              Logout
            </Button>
          </div>
        </div>
        <div className="personal-info">
          <div className="personal-info-input">
            <Typography className="input-label-text">First Name</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  type="text"
                  placeholder="Your Name"
                  size="small"
                />
              </>
            )}
          </div>
          <div className="personal-info-input">
            <Typography className="input-label-text">Email Address</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="email@email.com"
                  size="small"
                />
              </>
            )}
          </div>
          <div className="personal-info-input">
            <Typography className="input-label-text">Account Type</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  type="text"
                  value={formData.accountType}
                  placeholder="Your Account Type"
                  name="accountType"
                  onChange={handleChange}
                  size="small"
                  disabled
                />
              </>
            )}
          </div>
        </div>
        <div className="personal-info">
          <div className="personal-info-input">
            <Typography className="input-label-text">Last Name</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  type="text"
                  placeholder="Your Last Name"
                  size="small"
                />
              </>
            )}
          </div>
          <div className="personal-info-input">
            <Typography className="input-label-text">Phone</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  type="text"
                  value={formData.phone}
                  placeholder="0000000000000000"
                  name="phone"
                  onChange={handleChange}
                  size="small"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="profile-person-addr">
        <div className="personal-info">
          <h4 className="personal-info-heading">Address</h4>
          <p className="personal-info-para">
            Please enter your current address details accurately. This
            information is crucial for any location-based deductions. Your
            address will be handled with strict confidentiality.
          </p>
        </div>
        <div className="personal-info">
          <div className="personal-info-input">
            <Typography className="input-label-text">State </Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  type="text"
                  value={formData.state}
                  name="state"
                  onChange={handleChange}
                  placeholder="State Name"
                  size="small"
                />
              </>
            )}
          </div>
          <div className="personal-info-input">
            <Typography className="input-label-text">Postal Code</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  type="text"
                  value={formData.zipCode}
                  name="zipCode"
                  onChange={handleChange}
                  placeholder="Postal Code"
                  size="small"
                />
              </>
            )}
          </div>
        </div>
        <div className="personal-info">
          <div className="personal-info-input">
            <Typography className="input-label-text">City</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  type="text"
                  value={formData.city}
                  placeholder="Your City"
                  name="city"
                  onChange={handleChange}
                  size="small"
                />
              </>
            )}
          </div>
          <div className="personal-info-input">
            <Typography className="input-label-text">Tax ID</Typography>
            {isloading ? (
              <Skeleton height={40} />
            ) : (
              <>
                <TextField
                  className="input-fields"
                  name="taxId"
                  value={formData.taxId}
                  onChange={handleChange}
                  type="text"
                  placeholder="0000000000000000"
                  size="small"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Snackbar autoHideDuration={6000} onClose={handleClose} open={isSucc}>
        <Alert severity="success">{"Profile Updated"}</Alert>
      </Snackbar>
    </div>
  );
};

export default Profile;
