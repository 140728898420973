import {
  Button,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { AppRegistration, DeleteOutline } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Add from "../../Icons/Add.png";
import LinkedAccount from "@/components/LinkedAccount/LinkedAccount";
import LinkedAccountMobile from "@/components/LinkedAccount/LinkedAccountMobile";
import Service from "services/services";
import { useAppContext } from "Store/AppContext";
import { trackUserMP } from "services/mixpanel";
import { is } from "date-fns/locale";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    textAlign: "start",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "start",
    color: "#808080",
    border: "none",
    height: "30px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9FAFB",
  },
  "&:nth-of-type(even)": {},
}));

const LinkedAccounts = () => {
  let token = JSON.parse(localStorage.getItem("userInfo"))?.token;
  const [bearerToken, setBearerToken] = useState("");
  const [linkToken, setLinkToken] = useState("");
  const [publicToken, setPublicToken] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [state, dispatch] = useAppContext();
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const loadAccountsData = async (accessToken) => {
    setLoading(true);
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/getLinkedAccounts`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (data.data.success === true) {
      setRows(data.data.accounts);
      console.log(data.data.accounts);
      setLoading(false);
    }
  };

  useEffect(() => {
    let userData = localStorage.getItem("userInfo");
    if (!userData) {
      navigate("/");
    }
    userData = JSON.parse(userData);
    let token = userData.token;
    setBearerToken(token);
    loadAccountsData(token);
  }, []);

  useEffect(() => {
    trackUserMP("Linked Accounts Page");
  }, []);

  const { open, ready } = usePlaidLink({
    token: state.PlaidLinkToken,
    onSuccess: async (public_token, metadata) => {
      let accessToken = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/create-access-token`,
        { public_token: public_token },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    },
  });

  //const { open, ready } =  Service.link_token()
  const removeHandler = (ele) => {
    const ArrayList = rows.filter((e) => e.account_id !== ele.account_id);
    setRows(ArrayList);
  };
  const servies = new Service();

  const deleteCardHandler = (acc_id) => {
    servies
      .disableCard({ account_id: acc_id })
      .then((res) => {
        loadAccountsData(bearerToken);
      })
      .catch((err) => {});
  };

  const [isMore, setMore] = useState(false);
  const [selecedID, setSelecedID] = useState(null);
  const CloseModel = (e) => {
    e.stopPropagation();
    setMore(false);
    setSelecedID(null);
  };
  const OpendModel = (e, id) => {
    e.stopPropagation();
    setSelecedID(id);
    setMore(true);
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-none d-sm-block">
        <div className="d-flex flex-row mt-2 mb-4 justify-content-between">
          <div className="fw-bolder" style={{ fontSize: "1.1rem" }}>
            Manage your Linked Accounts
          </div>
          <div>
            <div></div>
            <button
              type="button"
              class="btn btn-primary rounded-1 text-white p-2 pe-3 ps-3 d-flex flex-col gap-2 align-items-center justify-content-between"
              onClick={open}
            >
              <img
                style={{ height: "0.9rem", mt: -1 }}
                className="img-fluid "
                src={Add}
                alt="card_logo"
              />
              Add New
            </button>
          </div>
        </div>
        <div className="border rounded-2">
          <div className="col-12 p-3 pb-4 bg-white d-flex flex-row rounded-top-2">
            <div className="col-4">Name</div>
            <div className="col-2">Status</div>
            <div className="col-2">Number</div>
            <div className="col-2">Last Update</div>
            <div className="col-2"></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "34rem",
              overflowY: "scroll",
            }}
          >
            {rows.map((ele, id) => {
              return (
                <LinkedAccount
                  deleteCardHandler={deleteCardHandler}
                  status={"Active"}
                  cardName={ele?.bankAccount?.name}
                  cardType={ele?.bankAccount?.subtype}
                  LastUpadte={moment(
                    ele?.bankAccount?.updatedAt,
                    "YYYY-MM-DD HH:mm:ss.SSS [UTC]"
                  ).tz("America/New_York")
                    .startOf("hour")
                    .fromNow()}
                  Mask_Num={"**** **** ****" + " " + ele?.bankAccount?.mask}
                  id={id}
                  acc_id={ele?.bankAccount?.account_id}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="d-sm-none" onClick={CloseModel}>
        <div className="d-flex flex-column gap-3 mt-3 justify-content-between">
          <div className="fw-bolder mb-1" style={{ fontSize: "1.1rem" }}>
            Manage your Linked Accounts
          </div>
          {rows.map((ele, id) => {
            return (
              <LinkedAccountMobile
                deleteCardHandler={deleteCardHandler}
                status={"Active"}
                cardName={ele?.bankAccount?.name}
                cardType={ele?.bankAccount?.subtype}
                LastUpadte={moment(
                  ele?.bankAccount?.updatedAt,
                  "YYYY-MM-DD HH:mm:ss.SSS [UTC]"
                ).tz("America/New_York")
                  .startOf("hour")
                  .fromNow()}
                Mask_Num={"**** **** ****" + " " + ele?.bankAccount?.mask}
                id={id}
                acc_id={ele?.bankAccount?.account_id}
                isMore={isMore}
                setMore={setMore}
                CloseModel={CloseModel}
                selectedID={selecedID}
                OpendModel={OpendModel}
              />
            );
          })}
          <div>
            <button
              type="button"
              class="btn btn-primary mt-2 col-12 rounded-1 text-white p-2 pe-3 ps-3 d-flex flex-col gap-2 align-items-center justify-content-center"
              onClick={open}
            >
              <img
                style={{ height: "0.9rem", mt: -1 }}
                className="img-fluid "
                src={Add}
                alt="card_logo"
              />
              Add New
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedAccounts;
