import Layout from "hoc/Layout";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "style/sass/style.scss";
import Overview from "pages/Overview";
import Login from "./pages/Sigin/Login";
import SignupStepper from "./pages/Signup/SignupStepper";
import Expenses from "./pages/Expenses";
import Reports from "pages/Reports/Reports";
import Settings from "pages/Settings/Settings";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import { AppProvider, useAppContext } from "./Store/AppContext";
import ForgotPassword from "pages/Sigin/ForgotPassword";

import { useEffect } from "react";
import axios from "axios";
function App() {
  const [store, dispatch] = useAppContext();
  const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

  useEffect(() => {
    async function fetch() {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/create-link-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({
        type: "SET_PLAIDLINKED_TOKEN",
        payload: response.data.link_token,
      });
    }
    if (token) {
      fetch();
    }
  }, []);

  if (!token) {
    return (
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route exact path="/signup" element={<SignupStepper />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    );
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout heading={"Overview"}>
                <Overview />
              </Layout>
            }
          />
          <Route exact path="/signup" element={<SignupStepper />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            exact
            path="/transaction"
            element={
              <Layout heading={"Your Expenses"}>
                <Expenses />
              </Layout>
            }
          />
          <Route
            exact
            path="/reports"
            element={
              <Layout heading={"Reports"} isHide={false} isReport={true}>
                <Reports />
              </Layout>
            }
          />
          <Route
            exact
            path="/taxcalculation"
            element={
              <Layout isHide={true}>
                <PageNotFound />
              </Layout>
            }
          />
          <Route
            exact
            path="/help"
            element={
              <Layout isHide={true}>
                <PageNotFound />
              </Layout>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <Layout heading={"Settings"} isHide={true}>
                <Settings />
              </Layout>
            }
          />
          <Route
            exact
            path="/linkaccount"
            element={
              <Layout heading={"Settings"} isHide={true}>
                <Settings />
              </Layout>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
