import React, { useState } from "react";
import {
  Box,
  Typography,
  Radio,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/lab";

function Step4({
  formData,
  activeStep,
  setActivesteps,
  prevHandler,
  setFormData,
}) {
  const [isError, setError] = useState(false);
  const [msg, setMsg] = useState(false);
  const [selectedAns,setSelectedAns] = useState("");
  const Data = ["I started this year", "I started before this year"];
  const Months = [
    "Select a month",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let Years = ["Select a year"];
  for (let i = 1990; i < 2023; i++) {
    Years.push(`${i}`);
  }
  const Headings = [
    `When did you start working as a ${formData.Questions["What profession did you earn income with?"]}?`,
    "This info allows us to find tax write-offs for the months you’ve been working.",
  ];

  const onChangeHandler = (e, idx) => {
    if (idx == -1) {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        Questions: {
          ...formData.Questions,
          [selectedAns]:{...formData.Questions[selectedAns],[name]:value}
        },
      }));
    } else {
      const { name } = e.target;
      const value = Data[idx];
    
      if (Data[idx] == "I started this year") {
        setFormData((prev) => ({
          ...prev,
          Questions: {
            ...formData.Questions,
            [name]: { "start": value, year: "2023" },
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          Questions: {
            ...formData.Questions,
            [name]:{ "start": value,},
          },
        }));
      }
      setSelectedAns(name);
    }
    console.log(formData)
  };

  const nextHandler = (event) => {
      setActivesteps((prev) => prev + 1);
  };
  const setShow = () => {
    setError(false);
  };

  return (
    <div class="container step-container">
      <div class="row justify-content-center">
        <form onSubmit={nextHandler}>
          <div className="form-container">
            <div class="text-center col-12 d-flex-col  mb-3 ">
              <div className="fw-medium fs-6">{Headings[0]}</div>
              <div className="sub-heading">{Headings[1]}</div>
            </div>
            {Data.map((ele, id) => {
              return (
                <>
                  <div key={id} class="col-12 mb-3 p-3 border rounded-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      name={Headings[0]}
                      id="inlineRadio2"
                      onChange={(e) => onChangeHandler(e, id)}
                      checked={formData.Questions[Headings[0]]?.start == ele}
                      required
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-lg ms-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                    <label class="form-check-label ms-2" for="exampleCheck1">
                      {ele}
                    </label>
                  </div>
                </>
              );
            })}
            {formData.Questions[Headings[0]]?.start == "I started this year" && (
              <>
                {" "}
                <label class="form-check-label" for="exampleCheck1">
                  Which Month in 2023?
                </label>
                <select
                  class="form-select mb-4 mt-1 rounded-1"
                  required
                  name="month"
                  value={formData.Questions[Headings[0]].month}
                  onChange={(e) => onChangeHandler(e, -1)}
                  aria-label="Default select example"
                >
                  {Months.map((ele, id) => {
                    return (
                      <option key={id} value={ele} required>
                        {ele}
                      </option>
                    );
                  })}
                </select>{" "}
              </>
            )}
            {formData.Questions[Headings[0]]?.start ==
              "I started before this year" && (
              <>
                {" "}
                <label class="form-check-label mb-1" for="exampleCheck1">
                  Which year?
                </label>
                <select
                  class="form-select mb-4 mt-3 rounded-1"
                  required
                  name="year"
                  value={formData.Questions[Headings[0]].year}
                  onChange={(e) => onChangeHandler(e, -1)}
                  aria-label="Default select example"
                >
                  {Years.map((ele, id) => {
                    return (
                      <option key={id} value={ele} required>
                        {ele}
                      </option>
                    );
                  })}
                </select>{" "}
                <select
                  class="form-select mb-4 mt-3 rounded-1"
                  required
                  name="month"
                  value={formData.Questions[Headings[0]].month}
                  onChange={(e) => onChangeHandler(e, -1)}
                  aria-label="Default select example"
                >
                  {Months.map((ele, id) => {
                    return (
                      <option key={id} value={ele} required>
                        {ele}
                      </option>
                    );
                  })}
                </select>
              </>
            )}
          </div>
          <div className="col-12 d-flex justify-content-between">
            <button
              type="submit"
              class="btn col-5 btn-outline-primary p-2 rounded-2"
              onClick={prevHandler}
            >
              Back
            </button>
            <button
              type="submit"
              class="btn col-5 p-2 btn-primary rounded-2 text-light"
              //onClick={() => setActivesteps((prev) => prev + 1)}
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <Snackbar onClose={setShow} autoHideDuration={12000} open={isError}>
        <Alert severity="error" sx={{ color: "red" }}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Step4;
