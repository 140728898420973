import axios from 'axios';
const API_URL = process.env.REACT_APP_BACKEND_HOST

let APIKit = axios.create({
    baseURL: API_URL,
    timeout: 30000,
});

const token = JSON.parse(localStorage.getItem("userInfo"))?.token
const user_id = JSON.parse(localStorage.getItem("userInfo"))?._id
//const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZWEzYjI3YWZmODU3NTdmMTBiOTJiNCIsImlhdCI6MTY5NjE0NjE2NywiZXhwIjoxNjk4NzM4MTY3fQ.OkrzWtzzLjrkl_KgmyleG1ZieaAYxemNOzYD5LKYzvM';
class Service {


    createCancelTokenSource() {
        return axios.CancelToken.source();
      }

    getLinkedAccounts = async (payload={}) => {

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
        return APIKit.post('/api/v1/banking/getLinkedAccounts',payload,apiHeaders);
    };


    crateAccess = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/create-access-token', {public_token:'public-sandbox-4182c019-786c-4d66-8fbe-e74c3e9b30fb'}, apiHeaders);
    };

    lastRequest = null;

    cancelLastRequest() {
        if (this.lastRequest) {
          this.lastRequest.cancel('Request canceled due to a new request');
        }
      }

    getExpences = async (payload) => {
      
        this.cancelLastRequest();
        const cancelTokenSource = this.createCancelTokenSource();
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              cancelToken: cancelTokenSource.token, 
        };
    this.lastRequest = cancelTokenSource;
        return APIKit.post('/api/v1/banking/expenses', payload, apiHeaders);
    };

    getReport = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
          
        };
   
        return APIKit.post('/api/v1/banking/report', payload, apiHeaders);
    };


    disableCard = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
          
        };
   
        return APIKit.post('/api/v1/banking/card-disable', payload, apiHeaders);
    };

    getDashboardAnalytics = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/dashboard-analytics', payload, apiHeaders);
    };

    getExpensesbyday = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/expensesbyday', payload, apiHeaders);
    };


    getExpensesbyCategory = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/expensesbycategory', payload, apiHeaders);
    };


    getExpensesbyAccount = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/expensesbyaccount', payload, apiHeaders);
    };


    getExpencesAnalytics = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/expense-analytics', payload, apiHeaders);
    };

    markExpense = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/mark/expense-type', payload, apiHeaders);
    };
    markIncome = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/mark/income', payload, apiHeaders);
    };


    markCategory = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.post('/api/v1/banking/mark/category', payload, apiHeaders);
    };
    getUser = async() => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.get('/api/users', apiHeaders);
    };

    updateUser = async (payload) => {
      
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
        };
   
        return APIKit.put('/api/users',payload, apiHeaders);
    };

}

export default Service;