import mixpanel from "mixpanel-browser";


mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

async function trackUserMP(screen) {
    mixpanel.track(screen, {
    time: Date.now(),
  });
  
}

async function trackUserMPwithEmail(screen, email) {
    mixpanel.track(screen, {
    distinct_id: email,
    email:email,
    time: Date.now(),
  });
}

async function trackUserMPSignup(screen,key,value) {
    mixpanel.track(screen, {
    [key]:value,
    time: Date.now(),
  });
}


export { trackUserMPwithEmail, trackUserMP ,trackUserMPSignup};
