
const FormatName = (text) => {
  console.log(text)
  const words = text.split("_").join(" ");
  console.log(words)
  return words.length > 27 ? words.split(0,27)[0]:words;
};


const FormatNumber = (input) => {
  const num = Number(input);
  if (Number.isNaN(num)) {
    return "N/A";
  }
  const numParts = num.toFixed(2).split(".");
  const integerPart = new Intl.NumberFormat('en-US').format(numParts[0])
  const decimalPart = numParts[1] || "00";
  const finalValue = integerPart + "." + decimalPart;
  return finalValue;
};

module.exports = { FormatName,FormatNumber };
