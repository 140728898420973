import React from "react";

function PageNotFound() {
  return (
    <div
      style={{
        height: "80vh",
        width: "80vw",
        textAlign: "center",
      }}
    >
      <h1>PageNotFound</h1>
    </div>
  );
}

export default PageNotFound;
