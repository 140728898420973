import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import leftArrowIcon from "../../assets/icon/SVG/leftArrow.svg";
import rightArrowIcon from "../../assets/icon/SVG/rightArrow.svg";
import reviewIcon from "../../assets/icon/SVG/Needs-Review-normal.svg";
import notExpenseIcon from "../../assets/icon/SVG/Not-a-Expense-normal.svg";
import notSureIcon from "../../assets/icon/SVG/Not-Sure-normal.svg";
import nondeductibleIcon from "../../assets/icon/SVG/nondedutct.svg";
import incomeIcon from "../../assets/icon/SVG/income.svg";
import expenseIcon from "../../assets/icon/SVG/Expense-normal.svg";
import filterIcon from "../../assets/icon/SVG/Filter-Expenses.svg";
import plusIcon from "../../assets/icon/SVG/Plus.svg";
import "./Swipe.scss";
import classes from "./tab.module.scss";
import Tiles from "../Tiles/Tiles";
import Service from "services/services";
import { useAppContext } from "Store/AppContext";
import CreditCard from "./CreditCard";
import CreditCardLoader from "./CreditCardLoader";
import ExpenseCard from "../ExpenseCard/ExpenseCard";
import ExpenseMainCard from "./ExpenseMainCard";
import ExpenseLoader from "./ExpenseLoader";
import CreditCardMobile from "./CreditCardMobile";
import FilterCheckbox from "../FilterCard/FilterCheckbox";
import ExpenseMobileCard from "./ExpenseMobileCard";
import FilterRadio from "../FilterCard/FilterRadio";

import { categMap } from "../../data/data";
import moment from "moment";
import { FormatNumber } from "Util/FormatName";
import { trackUserMP } from "services/mixpanel";
import { set } from "date-fns";

let token = JSON.parse(localStorage.getItem("userInfo"))?.token;

const tabsMaping = {
  NOT_SURE: "Not Sure",
  NOT_EXPENSE: "Not Expense",
  EXPENSE: "Expense",
  NEEDS_REVIEW: "Need Review",
  INCOMES: "Income",
  NON_DEDUCTIVES: "Non Deductible",
};

const categories = [
  "Advertising",
  "Assert",
  "Car and Truck",
  "Card Repayment",
  "Charitable Contributions",
  "Commission & Fees",
  "Contract Labor",
  "Dividends Income",
  "Entertainment",
  "Home Material",
  "Home Office",
  "House Repair",
  "Insurance",
  "Income",
  "Interest Paid",
  "Interest Income",
  "Loan Repayment",
  "Loan Proceeds",
  "Non-Deductible",
  "Professional Services",
  "Rent and Lease",
  "Repairs",
  "Meals",
  "Other",
  "Office Expenses",
  "Retirement Income",
  "Supplies",
  "Taxes and Licenses",
  "Tax Refunds",
  "Travel",
  "Transfer Out",
  "Transfer In",
  "Utilities",
];

const incomeTypes = [
  "Dividends Income",
  "Income",
  "Interest Income",
  "Retirement Income",
];
const notBulkSelect = [
  "Dividends Income",
  "Income",
  "Interest Income",
  "Retirement Income",
  "Non-Deductible",
];
function Index() {
  const perPage = 20;
  const [swipeSide, setSwipeSide] = useState("");
  const [selectedTranx, setSelectedTranx] = useState();
  const [selectCatModalChangeCat, setselectCatModalChangeCat] = useState(false);
  const [state] = useAppContext();
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [expences, setExpences] = useState([]);
  const [markingProgress, setMarkingProgress] = useState({
    id: null,
    type: null,
  });
  const [changeCatLoader, setChangeCatLoader] = useState(false);

  //call for change category in mobile expense chard
  const changeCategoryHandler = (clientCategory) => {
    setChangeCatLoader(true);
    const servies = new Service();
    const payload = {
      clientCategory,
      tranxIds: [selectedTranx.transaction_id],
      sourceCategory: activeTab,
    };

    servies
      .markCategory(payload)
      .then((res) => {
        setChangeCatLoader(false);
        setSelectCatModal(false);
        if (activeTab == "NON_DEDUCTIVES") {
          expenseHandler(
            "NEEDS_REVIEW",
            { ...selectedTranx, client_category: clientCategory },
            false,
            false
          );
        } else {
          getExpences(null, false, selectedTranx.transaction_id);

          //getExpencesCategoryChange(selectedTranx.transaction_id);
        }
        setselectCatModalChangeCat(false);
      })
      .catch((err) => {
        setChangeCatLoader(false);
      });
  };

  useEffect(() => {
    trackUserMP("Transactions Page");
  }, []);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e, idx) => {
    const card = document.getElementById(`card-${idx}`);
    if (touchStart - e.targetTouches[0].clientX < -100) {
      //card.classList.remove("left-swipe");
      //card.classList.add("right-swipe");
    } else if (touchStart - e.targetTouches[0].clientX > 100) {
      //card.classList.remove("right-swipe");
      //card.classList.add("left-swipe");
    }
    setTouchEnd(e.targetTouches[0].clientX);
  };
  const onTouchEnd = (name) => {
    // if (!touchStart || !touchEnd) return;
    // const distance = touchStart - touchEnd;
    // const isLeftSwipe = distance > minSwipeDistance;
    // const isRightSwipe = distance < -minSwipeDistance;
    // if (isLeftSwipe || isRightSwipe) {
    //   isLeftSwipe
    //     ? expenseHandler("NOT_EXPENSE", name, false)
    //     : expenseHandler("EXPENSE", name, false);
    // }
  };

  // Use state to manage the active tab
  const [activeTab, setActiveTab] = useState("NEEDS_REVIEW");
  const [expenceActive, setExpenceActive] = useState(false);

  const [totalExpense, setTotalExpense] = useState();
  const [accounts, setAccounts] = useState();
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [linkToken, setLinkToken] = useState("");
  const [analytics, setAnalytics] = useState({
    "New Expenses": 0,
    "Expenses Reviewed": 0,
    "Expenses to Review": 0,
  });
  const [selectCatModal, setSelectCatModal] = useState(false);
  const [addCardProcessing, setAddCardProcessing] = useState(false);

  const [categoryFilters, setCategoryFilters] = useState(categories);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const [analyticsLoader, setAnalyticsLoader] = useState(true);
  const [cardLoader, setCardLoader] = useState(true);
  const [expenseLoader, setExpenseLoader] = useState(true);
  const [activeExpensePage, setActiveExpensePage] = useState(1);
  const servies = new Service();

  const [selectedCardDtails, setSelectedCardDtails] = useState(null);

  const [bulkAction, setBulkAction] = useState(false);

  // Function to handle tab changes
  const handleTabChange = (type) => {
    setActiveExpensePage(1);
    resetBulkingActions();
    setActiveTab(type);
    setExpenseLoader(true);
    getExpences(type);
  };

  const [currentTranx, setCurrentTranx] = useState({});
  const [mobileMarkingModal, setMobileMarkingModal] = useState(false);

  const transactionMobileHandler = (tranx) => {
    setCurrentTranx(tranx);
    setMobileMarkingModal(true);
  };

  //
  useEffect(() => {
    setActiveExpensePage(1);
    getExpences(null, false);
  }, [categoryFilters]);

  const cardSelectHandler = (card) => {
    setActiveExpensePage(1);
    const { account_id } = card;
    const isExist = selectedAcc.includes(account_id);
    if (isExist && selectedAcc.length > 0) {
      const acc_ids = selectedAcc.filter((each) => {
        return each !== account_id;
      });

      setSelectedAcc([...acc_ids]);
      return;
    }

    if (!isExist) {
      setSelectedAcc([...selectedAcc, account_id]);
    }
  };

  const handleSyncTransactions = async (item_id) => {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/synctransactions`,
      { item_id: item_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const mobileCreditClickHandler = (val = true, card = null) => {
    const { account_id } = card;
    setExpenceActive(val);
    if (val) {
      setSelectedAcc([account_id]);
    }
  };

  const deleteCardHandler = (acc_id) => {
    servies
      .disableCard({ account_id: acc_id })
      .then((res) => {
        getLinkedAccounts();
      })
      .catch((err) => {});
  };
  const getExpences2 = (type = null, isInfinite = false) => {
    servies
      .getExpences({
        ...state.globalFilter,
        type: type ? type : activeTab,
        page: activeExpensePage,
        account_ids: selectedAcc,
        cats: categoryFilters,
      })
      .then(({ data }) => {
        if (isInfinite) {
          setExpences([...expences, ...data.result?.data]);
        } else {
          setExpences([...data.result?.data]);
        }
        setTotalExpense(data.result?.total);
        setExpenseLoader(false);
        setExpenseBottomLoader(false);
      })
      .catch((err) => {
        setExpenseLoader(false);
        setExpenseBottomLoader(false);
      });
  };

  const getExpences = async (
    type = null,
    isInfinite = false,
    transaction_id = null
  ) => {
    const currentIndex =
      expences.findIndex((each) => each.transaction_id == transaction_id) ||
      null;
    const currentPage = currentIndex
      ? Math.ceil((currentIndex + 1) / perPage)
      : null;

    servies
      .getExpences({
        ...state.globalFilter,
        type: type ? type : activeTab,
        page: currentPage || activeExpensePage,
        account_ids: selectedAcc,
        cats: categoryFilters,
      })
      .then(({ data }) => {
        if (currentPage) {
          setExpences([
            ...expences.slice(0, (currentPage - 1) * perPage),
            ...data.result?.data,
            ...expences.slice(currentPage * perPage),
          ]);
        } else {
          if (isInfinite) {
            setExpences([...expences, ...data.result?.data]);
          } else {
            setExpences([...data.result?.data]);
          }
        }
        setTotalExpense(data.result?.total);
        setExpenseLoader(false);
        setExpenseBottomLoader(false);
      })
      .catch((err) => {
        setExpenseLoader(false);
        setExpenseBottomLoader(false);
      });
  };

  const getExpencesCategoryChange = (selectedTrans_ID) => {
    const currentIndex = expences.findIndex(
      (each) => each.transaction_id == selectedTrans_ID
    );
    const currentPage = Math.ceil((currentIndex + 1) / perPage);
    servies
      .getExpences({
        ...state.globalFilter,
        type: activeTab,
        page: currentPage,
        account_ids: selectedAcc,
        cats: categoryFilters,
      })
      .then(({ data }) => {
        setExpences([
          ...expences.slice(0, (currentPage - 1) * perPage),
          ...data.result?.data,
          ...expences.slice(currentPage * perPage),
        ]);
        setTotalExpense(data.result?.total);
        setExpenseLoader(false);
        setExpenseBottomLoader(false);
      })
      .catch((err) => {
        setExpenseLoader(false);
        setExpenseBottomLoader(false);
      });
  };

  const getExpencesAnalytics = () => {
    setAnalyticsLoader(true);
    servies
      .getExpencesAnalytics({ ...state.globalFilter, account_ids: selectedAcc })
      .then(({ data }) => {
        setAnalytics(data.result);
        setAnalyticsLoader(false);
      })
      .catch((err) => {
        setAnalyticsLoader(false);
      });
  };

  const [isBulkMarking, setIsBulkMarking] = useState(false);

  const [expenseBulkingIds, setExpenseBulkingIds] = useState([]);

  const expenseBulkingHandler = (tranx) => {
    setBulkAction(false);
    if (
      expenseBulkingIds.find(
        (each) => each.transaction_id == tranx.transaction_id
      )
    ) {
      setExpenseBulkingIds(
        expenseBulkingIds.filter(
          (each) => each.transaction_id !== tranx.transaction_id
        )
      );
      return;
    }
    if (!notBulkSelect.includes(tranx.client_category)) {
      setExpenseBulkingIds([...expenseBulkingIds, tranx]);
    }
  };

  const resetBulkingActions = () => {
    setIsBulkMarking(false);
    setExpenseBulkingIds([]);
    setSelectedBulkMarking(false);
    setBulkAction(false);
  };

  useEffect(() => {
    if (expenseBulkingIds.length > 0) {
      setSelectedBulkMarking(true);
    } else {
      setSelectedBulkMarking(false);
    }
  }, [expenseBulkingIds]);

  const [selectedBulkMarking, setSelectedBulkMarking] = useState(false);

  const expenseHandler = async (
    type,
    tranx,
    isBulk = false,
    incomeType = false
  ) => {
    if (type == activeTab && !incomeType) {
      getExpences(activeTab, false, tranx?.transaction_id);

      return;
    }

    if (incomeType) {
      tranx = { ...tranx, type: incomeType };
    }

    let ids = [tranx?.transaction_id];
    let tranxDetails = [tranx];

    if (isBulk) {
      setIsBulkMarking(true);
      tranxDetails = expenseBulkingIds;
    }
    setMarkingProgress({
      id: tranx?.transaction_id,
      type: type,
      incomeType: incomeType,
    });

    await servies.markExpense({
      tranxDetails: tranxDetails,
      expenceType: type,
      sourceCategory: activeTab,
    });

    setIsBulkMarking(false);
    setBulkAction(false);
    setMarkingProgress({ id: null, type: null });
    if (!isBulk) {
      const filterExpences = expences.filter(
        (each) => !ids.includes(each.transaction_id)
      ); //remaining expense
      setExpences(filterExpences);
      setTotalExpense((prev) => prev - 1);
    }
    if (isBulk || incomeType) {
      setExpenseLoader(true);
      getExpences(activeTab, false);
      resetBulkingActions();
    }

    if (activeTab == "NEEDS_REVIEW" || type == "NEEDS_REVIEW") {
      getExpencesAnalytics();
      getLinkedAccounts();
    }
    setMobileMarkingModal(false);
  };

  const markIncome = async (
    type,
    tranx,
    isBulk = false,
    incomeType = false
  ) => {
    if (type == activeTab && !incomeType) {
      return;
    }

    if (incomeType) {
      tranx = { ...tranx, type: incomeType };
    }

    let ids = [tranx?.transaction_id];
    let tranxDetails = [tranx];

    if (isBulk) {
      setIsBulkMarking(true);
      tranxDetails = expenseBulkingIds;
      ids = expenseBulkingIds.map((each) => each.transaction_id);
    }
    setMarkingProgress({
      id: tranx?.transaction_id,
      type: type,
      incomeType: incomeType,
    });

    await servies.markIncome({
      // tranxDetails: tranxDetails,
      tranxIds: ids,
      incomeType: incomeType,
      acc_ids: selectedAcc,
      // sourceCategory:activeTab
    });

    setIsBulkMarking(false);
    setBulkAction(false);
    setMarkingProgress({ id: null, type: null });
    if (!isBulk) {
      setExpences(
        expences.filter((each) => !ids.includes(each.transaction_id))
      );
    }
    if (isBulk || incomeType) {
      setExpenseLoader(true);
      getExpences(activeTab, false);

      resetBulkingActions();
    }

    if (activeTab == "NEEDS_REVIEW") {
      getExpencesAnalytics();
      getLinkedAccounts();
    }

    setMobileMarkingModal(false);
  };

  useEffect(() => {
    if (selectedAcc.length === 0) {
      getExpencesAnalytics();
      setExpenseLoader(true);
      getExpences();
      return;
    }
    if (selectedAcc.length == 1) {
      setSelectedCardDtails(
        accounts.find((each) => each?.bankAccount?.account_id == selectedAcc[0])
          ?.bankAccount
      );
    }
    getExpencesAnalytics();
    setExpenseLoader(true);
    getExpences();
  }, [selectedAcc]);

  const getLinkedAccounts = () => {
    servies
      .getLinkedAccounts({ ...state.globalFilter })
      .then(({ data }) => {
        const accounts = data.accounts;
        setCardLoader(false);
        if (accounts && accounts.length == 0) {
          setAccounts([]);
          return;
        }
        // if (selectedAcc.length > 0) {
        //   //setSelectedAcc([...selectedAcc]);
        // }
        // else {
        //   setSelectedAcc([
        //     ...selectedAcc,
        //     accounts[0]?.bankAccount?.account_id,
        //   ]);
        // }
        setAccounts(accounts);
      })
      .catch((err) => {
        setCardLoader(false);
      });
  };

  useEffect(() => {
    setCardLoader(true);
    getLinkedAccounts();
    getExpences();
    getExpencesAnalytics();
  }, [state.globalFilter]);

  // useEffect(() => {
  //   if (selectedAcc.length === 0) {
  //     return;
  //   }
  //   getExpencesAnalytics();
  //   setExpenseLoader(true);
  //   getExpences();
  // }, [state.globalFilter]);

  const [expenseBottomLoader, setExpenseBottomLoader] = useState(false);

  useEffect(() => {
    if (activeExpensePage == 1) return;

    getExpences(null, true);
  }, [activeExpensePage]);

  const lastItemRef = useRef(null);
  const lastItemRefMobile = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const lastEntry = entries[0];

      if (lastEntry.isIntersecting) {
        if (expenseBottomLoader || totalExpense <= activeExpensePage * perPage)
          return;
        setExpenseBottomLoader(true);
        setActiveExpensePage(activeExpensePage + 1);
      }
    });

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }
    if (lastItemRefMobile.current) {
      observer.observe(lastItemRefMobile.current);
    }

    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current);
      }
      if (lastItemRefMobile.current) {
        observer.unobserve(lastItemRefMobile.current);
      }
      // Make sure to disconnect the observer when the component unmounts
      observer.disconnect();
    };
  }, [expences]);

  const { open, ready } = usePlaidLink({
    token: state.PlaidLinkToken,
    onSuccess: async (public_token, metadata) => {
      let accessToken = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/create-access-token`,
        { public_token: public_token },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setAddCardProcessing(false);
      getLinkedAccounts();
    },
    onExit: () => {
      setAddCardProcessing(false);
    },
  });

  let bulkActionJSX = (bulkAction || selectedBulkMarking) && !isBulkMarking && (
    <div className="d-flex">
      <button
        disabled={isBulkMarking}
        style={{ cursor: "pointer", background: "white" }}
        onClick={
          activeTab == "INCOMES"
            ? markIncome.bind(this, "EXPENSE", null, true)
            : expenseHandler.bind(this, "EXPENSE", null, true)
        }
      >
        <img src={expenseIcon} alt="expenseIcon"></img>
      </button>
      <button
        isabled={isBulkMarking}
        style={{ cursor: "pointer", background: "white" }}
        onClick={expenseHandler.bind(this, "NOT_EXPENSE", null, true)}
      >
        <img src={notExpenseIcon} alt="notExpenseIcon" className="mx-4"></img>
      </button>
      <button
        isabled={isBulkMarking}
        style={{ cursor: "pointer", background: "white" }}
        onClick={expenseHandler.bind(this, "NOT_SURE", null, true)}
      >
        <img src={notSureIcon} alt="notSureIcon"></img>
      </button>
    </div>
  );

  if (activeTab == "INCOMES") {
    bulkActionJSX = (bulkAction || selectedBulkMarking) && !isBulkMarking && (
      <div className="d-flex">
        <button
          disabled={isBulkMarking}
          style={{ cursor: "pointer", background: "white" }}
          onClick={markIncome.bind(this, "EXPENSE", null, true, "PERSONAL")}
        >
          <img src={expenseIcon} alt="expenseIcon"></img>
        </button>
        <button
          disabled={isBulkMarking}
          style={{ cursor: "pointer", background: "white" }}
          onClick={markIncome.bind(this, "EXPENSE", null, true, "BUSINESS")}
        >
          <img src={notExpenseIcon} alt="notExpenseIcon" className="mx-4"></img>
        </button>
      </div>
    );
  }

  const divPostion = useRef(null);
  const [scrollPositionLeft, setScrollPositionLeft] = useState(0);

  const scrollHandler = (e) => {
    setScrollPositionLeft(divPostion.current.scrollLeft);
  };

  const filterCatetoryHandler = (name) => {
    if (!name) {
      if (categoryFilters.length == 0) {
        setCategoryFilters(categories);
        return;
      }
      setCategoryFilters([]);
      return;
    }
    if (categoryFilters.includes(name)) {
      setCategoryFilters([...categoryFilters.filter((each) => each !== name)]);
    } else {
      setCategoryFilters([...categoryFilters, name]);
    }
  };

  return (
    <div className="container m=0">
      {selectCatModal && (
        <div>
          <div
            onClick={() => {
              setSelectCatModal(false);
            }}
            className={[classes["back-drop"]].join(" ")}
          ></div>
          <div className={[classes["modal"]].join(" ")}>
            <FilterCheckbox
              selectedAll={categoryFilters.length == categories.length}
              setCategoryFilters={filterCatetoryHandler}
              categoryFilters={categoryFilters}
            />
          </div>
        </div>
      )}

      {mobileMarkingModal && (
        <div>
          <div
            onClick={() => {
              setMobileMarkingModal(false);
            }}
            className={[classes["back-drop"]].join(" ")}
          ></div>
          <div
            className={[classes["modal"]].join(" ")}
            style={{ width: "100%" }}
          >
            <ExpenseMobileCard
              setMobileMarkingModal={setMobileMarkingModal}
              data={currentTranx}
              expenseHandler={expenseHandler}
              type={activeTab}
              markingProgress={markingProgress}
              getExpences={getExpences}
              expenseBulkingIds={expenseBulkingIds}
              expenseBulkingHandler={expenseBulkingHandler}
              isBulkMarking={isBulkMarking}
              bulkAction={bulkAction}
              classes={classes}
              markIncome={markIncome}
            />
          </div>
        </div>
      )}

      {!expenceActive ? (
        <div className="row mb-4 expense-sub-container">
          {Object.keys(analytics).map((key) => {
            const each = analytics[key];

            return (
              <div
                key={key}
                className={[classes["analytics-container"], "col-md-4"].join(
                  " "
                )}
              >
                {key == "New Transactions" ? (
                  <Tiles
                    loading={analyticsLoader}
                    name={key}
                    value={`$ ${FormatNumber(each)}`}
                  />
                ) : (
                  <Tiles loading={analyticsLoader} name={key} value={each} />
                )}
              </div>
            );
          })}
        </div>
      ) : null}

      {selectCatModalChangeCat && (
        <div>
          <div
            onClick={() => {
              setSelectCatModal(false);
              setselectCatModalChangeCat(false);
            }}
            className={[classes["back-drop"]].join(" ")}
          ></div>
          <div className={[classes["modal"]].join(" ")}>
            <FilterRadio
              changeCategoryHandler={changeCategoryHandler}
              changeCatLoader={changeCatLoader}
              classes={classes}
              selectedTranx={selectedTranx}
            />
          </div>
        </div>
      )}

      <div className={[classes["filter-desk"]].join(" ")}>
        <div className="d-flex  overflow-auto">
          <div
            onClick={() => {
              handleTabChange("NEEDS_REVIEW");
            }}
            className={[
              classes[activeTab == "NEEDS_REVIEW" ? "active" : ""],
            ].join(" ")}
          >
            <button className={[classes["filter-item"], "btn"].join(" ")}>
              <img src={reviewIcon} alt="need review"></img>
              <p className="mb-0 text-nowrap">Needs Review</p>
            </button>
          </div>
          <div
            onClick={() => {
              handleTabChange("EXPENSE");
            }}
            className={[classes[activeTab == "EXPENSE" ? "active" : ""]].join(
              " "
            )}
          >
            <button className={[classes["filter-item"], "btn"].join(" ")}>
              <img src={expenseIcon} alt="need review"></img>
              <p className="mb-0 text-nowrap">Expense</p>
            </button>
          </div>
          <div
            onClick={() => {
              handleTabChange("NOT_EXPENSE");
            }}
            className={[
              classes[activeTab == "NOT_EXPENSE" ? "active" : ""],
            ].join(" ")}
          >
            <button className={[classes["filter-item"], "btn"].join(" ")}>
              <img src={notExpenseIcon} alt="need review"></img>
              <p className="mb-0 text-nowrap">Not an Expense</p>
            </button>
          </div>

          <div
            onClick={() => {
              handleTabChange("NOT_SURE");
            }}
            className={[classes[activeTab == "NOT_SURE" ? "active" : ""]].join(
              " "
            )}
          >
            <button className={[classes["filter-item"], "btn"].join(" ")}>
              <img src={notSureIcon} alt="need review"></img>
              <p className="mb-0 text-nowrap">Not Sure</p>
            </button>
          </div>
          <div
            onClick={() => {
              handleTabChange("NON_DEDUCTIVES");
            }}
            className={[
              classes[activeTab == "NON_DEDUCTIVES" ? "active" : ""],
            ].join(" ")}
          >
            <button className={[classes["filter-item"], "btn"].join(" ")}>
              <img src={nondeductibleIcon} alt="need review"></img>
              <p className="mb-0 text-nowrap">Non Deductible</p>
            </button>
          </div>
          <div
            onClick={() => {
              handleTabChange("INCOMES");
            }}
            className={[classes[activeTab == "INCOMES" ? "active" : ""]].join(
              " "
            )}
          >
            <button className={[classes["filter-item"], "btn"].join(" ")}>
              <img src={incomeIcon} alt="need review"></img>
              <p className="mb-0 text-nowrap">Income</p>
            </button>
          </div>
        </div>
        <hr className="mt-0"></hr>
      </div>

      <div className="row">
        <div
          className={[classes["credit-card-container-desk"], "col-md-4"].join(
            " "
          )}
        >
          <div className={[classes["card-heading"]]}>
            <p className="mb-0">Your Cards</p>
            <button
              type="button"
              className="align-items-center btn btn-primary d-flex text-white"
              onClick={() => {
                if (addCardProcessing) return;
                setAddCardProcessing(true);
                open();
              }}
            >
              {addCardProcessing ? null : (
                <img
                  src={plusIcon}
                  style={{ marginRight: "3px" }}
                  alt="Mark"
                ></img>
              )}
              {addCardProcessing ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Add New"
              )}
            </button>
          </div>

          <div
            className={[
              classes["scroll-y-container-acc"],
              "scroll-y-container",
            ].join(" ")}
          >
            {cardLoader || !accounts ? (
              <div>
                <CreditCardLoader />
                <CreditCardLoader />
                <CreditCardLoader />
              </div>
            ) : (
              accounts.map((each, idx) => {
                return (
                  <CreditCard
                    deleteCardHandler={deleteCardHandler}
                    loading={cardLoader}
                    cardSelectHandler={cardSelectHandler}
                    each={each}
                    classes={classes}
                    account_ids={selectedAcc}
                    handleSyncTransactions={handleSyncTransactions}
                    key={idx}
                    selectedAccs={selectedAcc}
                  />
                );
              })
            )}

            {!cardLoader && accounts.length == 0 ? (
              <div className="text-center">
                <strong>You’re all caught up!</strong>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className={[
            classes["credit-card-container-mobile"],
            "col-md-4",
            classes[!expenceActive ? "active" : ""],
          ].join(" ")}
        >
          <div className={[classes["card-heading"]]}>
            <div>
              <p className="mb-0">Your Cards</p>
              <small className="text-center text-muted fw-light d-block">
                Tap to see your expenses
              </small>
            </div>
            <button
              type="button"
              className="align-items-center btn btn-primary d-flex text-white"
              onClick={() => {
                if (addCardProcessing) return;
                setAddCardProcessing(true);
                open();
              }}
            >
              {addCardProcessing ? null : (
                <img
                  src={plusIcon}
                  style={{ marginRight: "3px" }}
                  alt="Mark"
                ></img>
              )}
              {addCardProcessing ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Add New"
              )}
            </button>
          </div>

          <div className={[classes["mobile-credit-scroll-container"]]}>
            {cardLoader || !accounts ? (
              <div>
                <CreditCardLoader />
                <CreditCardLoader />
                <CreditCardLoader />
              </div>
            ) : (
              accounts.map((each, idx) => {
                return (
                  <CreditCardMobile
                    deleteCardHandler={deleteCardHandler}
                    loading={cardLoader}
                    mobileCreditClickHandler={mobileCreditClickHandler}
                    each={each}
                    classes={classes}
                    account_ids={selectedAcc}
                    handleSyncTransactions={handleSyncTransactions}
                    key={idx}
                  />
                );
              })
            )}

            {!cardLoader && accounts.length == 0 ? (
              <div className="text-center">
                <strong>You’re all caught up!</strong>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className={[classes["expence-container-desk"], "col-md-8 "].join(" ")}
        >
          <div className="card">
            <div className="card-body">
              <div
                className={[classes["expence-cat-heading"], "row"].join(" ")}
              >
                <div className="col-md-8">
                  <small className="text-center text-muted fw-light">
                    Credit Card
                  </small>
                  <div className="d-flex align-items-center">
                    <p className="mb-0">
                      {selectedAcc && selectedAcc.length > 1
                        ? "Multi cards selected"
                        : selectedCardDtails && selectedCardDtails.mask
                        ? "Ending in " + selectedCardDtails.mask
                        : ""}{" "}
                    </p>
                    <small
                      className={[
                        "text-center",
                        "text-muted",
                        "fw-light",
                        "ml-2",
                        classes["expence-count"],
                      ].join(" ")}
                    >
                      {totalExpense}{" "}
                      {tabsMaping[activeTab] === "Expense"
                        ? "Expense(s)"
                        : "Transaction(s)"}
                    </small>
                  </div>
                </div>
                <div
                  className={[
                    classes["filter-btn"],
                    "col-md-4",
                    tabsMaping[activeTab] === "Non Deductible" ? "d-none" : "",
                  ].join(" ")}
                >
                  <button
                    type="button"
                    className="btn btn-primary  d-flex align-items-center border-0"
                    style={{ backgroundColor: "#B0D3FF" }}
                    onClick={() => {
                      setSelectCatModal(true);
                    }}
                  >
                    <img
                      src={filterIcon}
                      style={{ marginRight: "10px" }}
                      alt="Mark"
                    ></img>
                    Filter Expense
                  </button>
                </div>
              </div>
              <div className={[classes["see-all"]].join(" ")}>
                <div
                  onClick={() => {
                    if (bulkAction) {
                      setExpenseBulkingIds([]);
                    } else {
                      setExpenseBulkingIds([
                        ...expences.filter((each) => {
                          return ![...incomeTypes, "Non-Deductible"].includes(
                            each.client_category
                          );
                        }),
                      ]);
                    }
                    setBulkAction(!bulkAction);
                  }}
                >
                  <input
                    className="border-2 border-secondary-subtle form-check-input fs-5"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={bulkAction}
                  />

                  <small
                    className={[
                      "text-center",
                      "text-muted",
                      "fw-light",
                      "ml-2",
                      classes["select-all"],
                    ].join(" ")}
                  >
                    Select All
                  </small>
                </div>

                {bulkActionJSX}

                {isBulkMarking && (
                  <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>

              <hr></hr>

              <div
                className={[
                  classes["scroll-y-container"],
                  "epense-desk-container",
                ].join(" ")}
              >
                {expenseLoader || !expences ? (
                  <div>
                    <ExpenseLoader classes={classes} />
                    <ExpenseLoader classes={classes} />
                    <ExpenseLoader classes={classes} />
                  </div>
                ) : (
                  expences.map((each, idx) => {
                    return (
                      <ExpenseMainCard
                        expenseBulkingIds={expenseBulkingIds}
                        expenseBulkingHandler={expenseBulkingHandler}
                        isBulkMarking={isBulkMarking}
                        refrence={
                          idx === expences.length - 1 ? lastItemRef : null
                        }
                        type={activeTab}
                        classes={classes}
                        each={each}
                        getExpencesCategoryChange={getExpencesCategoryChange}
                        expenseHandler={expenseHandler}
                        markIncome={markIncome}
                        markingProgress={markingProgress}
                      />
                    );
                  })
                )}

                {!expenseLoader && expences.length == 0 ? (
                  <div className="text-center">
                    <strong>You’re all caught up!</strong>
                  </div>
                ) : (
                  ""
                )}

                {expenseBottomLoader && (
                  <p className="text-center">
                    <strong>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </strong>
                  </p>
                )}
              </div>

              {/* {totalExpense / 10 >= 1 && (
                <nav aria-label="Page navigation example" className="mt-4">
                  <ul className="pagination justify-content-center">
                    {createArrayFromNumber(totalExpense / 10).map((each) => {
                      return (
                        <li
                          onClick={() => {
                            setFilter({ ...filter, page: each });
                          }}
                          className="page-item"
                        >
                          <a className="page-link" href="#">
                            {each}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              )} */}
            </div>
          </div>
        </div>

        <div
          className={[
            classes["expence-container-mobile"],
            "col-md-8 ",
            classes[expenceActive ? "active" : ""],
          ].join(" ")}
        >
          <div className="">
            <div className="">
              <div
                className={[classes["expence-cat-heading"], "row"].join(" ")}
              >
                <div className="col-md-8">
                  <div
                    className="d-flex align-items-center"
                    onClick={mobileCreditClickHandler.bind(this, false)}
                  >
                    <div>
                      <img src={leftArrowIcon} alt="option"></img>
                    </div>
                    <div style={{ flex: 1, marginLeft: "0.5rem" }}>
                      <small className="text-center text-muted fw-light">
                        Credit Card
                      </small>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0">
                          {selectedAcc && selectedAcc.length > 1
                            ? "Multi cards selected"
                            : selectedCardDtails && selectedCardDtails.mask
                            ? "Ending in " + selectedCardDtails.mask
                            : ""}
                        </p>
                        <small
                          className={[
                            "text-center",
                            "text-muted",
                            "fw-light",
                            "ml-2",
                            classes["expence-count"],
                          ].join(" ")}
                        >
                          {/* ({totalExpense} {tabsMaping[activeTab]}) */}
                          {totalExpense}{" "}
                          {tabsMaping[activeTab] === "Expense"
                            ? "Expense(s)"
                            : "Transaction(s)"}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row p-0 m-0">
                {scrollPositionLeft > 10 && (
                  <img src={leftArrowIcon} alt="option"></img>
                )}
                <div
                  className={[classes["filter-mobile"]].join(" ")}
                  ref={divPostion}
                  onScroll={scrollHandler}
                >
                  <div
                    onClick={() => {
                      handleTabChange("NEEDS_REVIEW");
                    }}
                    className={[
                      classes[activeTab == "NEEDS_REVIEW" ? "active" : ""],
                    ].join(" ")}
                  >
                    <button
                      className={[classes["filter-item"], "btn"].join(" ")}
                    >
                      <img src={reviewIcon} alt="need review"></img>
                      <p className="mb-0">Needs Review</p>
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      handleTabChange("EXPENSE");
                    }}
                    className={[
                      classes[activeTab == "EXPENSE" ? "active" : ""],
                    ].join(" ")}
                  >
                    <button
                      style={{ width: "8rem" }}
                      className={[classes["filter-item"], "btn"].join(" ")}
                    >
                      <img src={expenseIcon} alt="need review"></img>
                      <p className="mb-0">Expense</p>
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      handleTabChange("NOT_EXPENSE");
                    }}
                    className={[
                      classes[activeTab == "NOT_EXPENSE" ? "active" : ""],
                    ].join(" ")}
                  >
                    <button
                      style={{ width: "10.5rem" }}
                      className={[classes["filter-item"], "btn"].join(" ")}
                    >
                      <img src={notExpenseIcon} alt="need review"></img>
                      <p className="mb-0">Not an Expense</p>
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      handleTabChange("NOT_SURE");
                    }}
                    className={[
                      classes[activeTab == "NOT_SURE" ? "active" : ""],
                    ].join(" ")}
                  >
                    <button
                      style={{ width: "10.5rem" }}
                      className={[classes["filter-item"], "btn"].join(" ")}
                    >
                      <img src={notSureIcon} alt="need review"></img>
                      <p className="mb-0">Not Sure</p>
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      handleTabChange("NON_DEDUCTIVES");
                    }}
                    className={[
                      classes[activeTab == "NON_DEDUCTIVES" ? "active" : ""],
                    ].join(" ")}
                  >
                    <button
                      style={{ width: "10.5rem" }}
                      className={[classes["filter-item"], "btn"].join(" ")}
                    >
                      <img src={nondeductibleIcon} alt="need review"></img>
                      <p className="mb-0">Non Deductible</p>
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      handleTabChange("INCOMES");
                    }}
                    className={[
                      classes[activeTab == "INCOMES" ? "active" : ""],
                    ].join(" ")}
                  >
                    <button
                      style={{ width: "10.5rem" }}
                      className={[classes["filter-item"], "btn"].join(" ")}
                    >
                      <img src={incomeIcon} alt="need review"></img>
                      <p className="mb-0">Income</p>
                    </button>
                  </div>
                </div>
                {scrollPositionLeft < 420 && (
                  <img src={rightArrowIcon} alt="option"></img>
                )}
              </div>
              <div>
                <div
                  className={[
                    classes["filter-btn"],
                    "col-md-4",
                    "mt-3",
                    tabsMaping[activeTab] === "Non Deductible" ? "d-none" : "",
                  ].join(" ")}
                >
                  <button
                    onClick={() => {
                      setSelectCatModal(true);
                    }}
                    type="button"
                    className="btn btn-primary w-100 "
                  >
                    <img src={filterIcon} alt="need review"></img>
                    <p className="mb-0">Filter Expense</p>
                  </button>
                </div>

                {/* <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button type="button" className="btn btn-outline-primary">
                    Left
                  </button>
                  <button type="button" className="btn btn-outline-primary">
                    Middle
                  </button>
                  <button type="button" className="btn btn-outline-primary">
                    Right
                  </button>
                </div> */}
              </div>

              <div className={[classes["see-all"]].join(" ")}>
                <div
                  onClick={() => {
                    if (bulkAction) {
                      setExpenseBulkingIds([]);
                    } else {
                      setExpenseBulkingIds([
                        ...expences.filter((each) => {
                          return ![...incomeTypes, "Non-Deductible"].includes(
                            each.client_category
                          );
                        }),
                      ]);
                    }
                    setBulkAction(!bulkAction);
                  }}
                >
                  <input
                    className="border-2 border-secondary-subtle form-check-input fs-5"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={bulkAction}
                  />

                  <small
                    className={[
                      "text-center",
                      "text-muted",
                      "fw-light",
                      "ml-2",
                      classes["select-all"],
                    ].join(" ")}
                  >
                    Select All
                  </small>
                </div>

                {(bulkAction || selectedBulkMarking) && !isBulkMarking && (
                  <div className="d-flex">
                    <button
                      disabled={isBulkMarking}
                      style={{ cursor: "pointer" }}
                      onClick={expenseHandler.bind(this, "EXPENSE", null, true)}
                    >
                      <img src={expenseIcon} alt="expenseIcon"></img>
                    </button>
                    <button
                      isabled={isBulkMarking}
                      style={{ cursor: "pointer" }}
                      onClick={expenseHandler.bind(
                        this,
                        "NOT_EXPENSE",
                        null,
                        true
                      )}
                    >
                      <img
                        src={notExpenseIcon}
                        alt="notExpenseIcon"
                        className="mx-4"
                      ></img>
                    </button>
                    <button
                      isabled={isBulkMarking}
                      style={{ cursor: "pointer" }}
                      onClick={expenseHandler.bind(
                        this,
                        "NOT_SURE",
                        null,
                        true
                      )}
                    >
                      <img src={notSureIcon} alt="notSureIcon"></img>
                    </button>
                  </div>
                )}

                {isBulkMarking && (
                  <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
              <hr></hr>

              <div className={[classes["mobile-expense-scroll-container"]]}>
                {expenseLoader ? (
                  <div>
                    <ExpenseLoader type="mobile" classes={classes} />
                    <ExpenseLoader type="mobile" classes={classes} />
                    <ExpenseLoader type="mobile" classes={classes} />
                  </div>
                ) : (
                  expences.map((each, idx) => {
                    return (
                      <div
                        ref={
                          idx === expences.length - 1 ? lastItemRefMobile : null
                        }
                        key={each._id}
                        id={`card-${each._id}`}
                        className={[
                          "card",
                          "p-3",
                          classes["expence-card"],
                        ].join(" ")}
                        onTouchStart={onTouchStart}
                        onTouchMove={(e) => onTouchMove(e, each._id)}
                        onTouchEnd={() => onTouchEnd(each)}
                      >
                        <div className="row">
                          <div className="col-md-7">
                            <div className="align-items-center d-flex gap-3">
                              <div>
                                <input
                                  className="border-2 border-secondary-subtle form-check-input fs-5"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  checked={expenseBulkingIds.find(
                                    (eachEx) =>
                                      eachEx.transaction_id ==
                                      each.transaction_id
                                  )}
                                  onClick={expenseBulkingHandler.bind(
                                    this,
                                    each
                                  )}
                                />
                              </div>
                              <div
                                className={[classes["price"]].join("")}
                                onClick={() => {
                                  transactionMobileHandler(each);
                                }}
                              >
                                <div
                                  className={classes["category-container"]}
                                  style={{ width: "fit-content" }}
                                >
                                  <div
                                    className={classes["type"]}
                                    style={{
                                      backgroundColor:
                                        categMap[each.client_category]
                                          ?.backgroundColor,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedTranx(each);
                                      setselectCatModalChangeCat(true);
                                    }}
                                  >
                                    <img
                                      src={
                                        categMap[each.client_category]?.icons
                                      }
                                      alt=""
                                    />

                                    <p
                                      className={[
                                        classes["truncate-text"],
                                        "font-weight-bold",
                                        "mb-0",
                                      ].join(" ")}
                                      style={{
                                        color:
                                          categMap[each.client_category]?.color,
                                      }}
                                    >
                                      {each.client_category}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={[classes["last-used"]].join(" ")}
                                >
                                  <p className="font-weight-bold fs-3 fw-bold mb-0 text-dark">
                                    ${FormatNumber(each.amount || 0)}
                                  </p>
                                  <p className="fs-6 text-secondary m-0">
                                    {moment(each.date).format("MM-DD-YYYY")}
                                  </p>
                                </div>
                                <p className="fs-6 text-secondary">
                                  {each.merchant_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}

                {!expenseLoader && expences.length == 0 ? (
                  <div className="text-center">
                    <strong>You’re all caught up!</strong>
                  </div>
                ) : (
                  ""
                )}

                {expenseBottomLoader && (
                  <p className="text-center">
                    <strong>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
