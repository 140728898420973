import React, { useState } from "react";
import "./Model.scss";
import ExpenseCard from "../ExpenseCard/ExpenseCard";
import Advertisement from "../../assets/icon/SVG/category/Advertisement.svg";
import Assets from "../../assets/icon/SVG/category/Assets.svg";
import Car_And_Truck from "../../assets/icon/SVG/category/Car and Truck.svg";
import Comissions_and_Fees from "../../assets/icon/SVG/category/Commission & Fees.svg";
import Contract_Labor from "../../assets/icon/SVG/category/Contract Labor.svg";
import Home_Office_Expenses from "../../assets/icon/SVG/category/Home Office.svg";
import Insurance from "../../assets/icon/SVG/category/Insurance.svg";
import Interest_Paid from "../../assets/icon/SVG/category/Interest Paid.svg";
import Meals from "../../assets/icon/SVG/category/Meals.svg";
import Office_Expenses from "../../assets/icon/SVG/category/Office Expenses.svg";
import Other from "../../assets/icon/SVG/category/Other.svg";
import Professional_Services from "../../assets/icon/SVG/category/Professional Services.svg";
import Repairs from "../../assets/icon/SVG/category/Repairs.svg";
import Rent_and_Lease from "../../assets/icon/SVG/category/Rent and Lease.svg";
import Supplies from "../../assets/icon/SVG/category/Supplies.svg";
import Taxes_and_Licenses from "../../assets/icon/SVG/category/Taxes and Licenses.svg";
import Travel from "../../assets/icon/SVG/category/Travel.svg";
import Utilities from "../../assets/icon/SVG/category/Utilities.svg";

import Donut from "../Chart/DonutChart/Donut";
import customKeyMapping from "assets/icon/SVG/category";
import { FormatNumber } from "Util/FormatName";
function Model({ closeModel, data }) {
  // const expenseList = data.map((each) => {
  //   return {
  //     icons: Professional_Services,
  //     name: each.category,
  //     value: each.totalAmount,
  //   };
  // });
  const [expenseList, setExpenseList] = useState([
    { icons: Advertisement, name: "Advertisement", value: "$8788.17" },
    { icons: Car_And_Truck, name: "Car And Truck", value: "$3784.11" },
    {
      icons: Home_Office_Expenses,
      name: "Home Office Expenses",
      value: "$174.11",
    },
    {
      icons: Comissions_and_Fees,
      name: "Comissions and Fees",
      value: "$37832.11",
    },
    {
      icons: Professional_Services,
      name: "Professional Services",
      value: "$784.11",
    },
    { icons: Advertisement, name: "Advertisement", value: "$8788.17" },
    { icons: Car_And_Truck, name: "Car And Truck", value: "$3784.11" },
    {
      icons: Home_Office_Expenses,
      name: "Home Office Expenses",
      value: "$174.11",
    },
    {
      icons: Comissions_and_Fees,
      name: "Comissions and Fees",
      value: "$37832.11",
    },
    {
      icons: Professional_Services,
      name: "Professional Services",
      value: "$784.11",
    },
    { icons: Advertisement, name: "Advertisement", value: "$8788.17" },
    { icons: Car_And_Truck, name: "Car And Truck", value: "$3784.11" },
    {
      icons: Home_Office_Expenses,
      name: "Home Office Expenses",
      value: "$174.11",
    },
    {
      icons: Comissions_and_Fees,
      name: "Comissions and Fees",
      value: "$37832.11",
    },
    {
      icons: Professional_Services,
      name: "Professional Services",
      value: "$784.11",
    },
    { icons: Advertisement, name: "Advertisement", value: "$8788.17" },
    { icons: Car_And_Truck, name: "Car And Truck", value: "$3784.11" },
    {
      icons: Home_Office_Expenses,
      name: "Home Office Expenses",
      value: "$174.11",
    },
    {
      icons: Comissions_and_Fees,
      name: "Comissions and Fees",
      value: "$37832.11",
    },
    {
      icons: Professional_Services,
      name: "Professional Services",
      value: "$784.11",
    },
    { icons: Advertisement, name: "Advertisement", value: "$8788.17" },
    { icons: Car_And_Truck, name: "Car And Truck", value: "$3784.11" },
    {
      icons: Home_Office_Expenses,
      name: "Home Office Expenses",
      value: "$174.11",
    },
    {
      icons: Comissions_and_Fees,
      name: "Comissions and Fees",
      value: "$37832.11",
    },
    {
      icons: Professional_Services,
      name: "Professional Services",
      value: "$784.11",
    },
    { icons: Advertisement, name: "Advertisement", value: "$8788.17" },
    { icons: Car_And_Truck, name: "Car And Truck", value: "$3784.11" },
    {
      icons: Home_Office_Expenses,
      name: "Home Office Expenses",
      value: "$174.11",
    },
    {
      icons: Comissions_and_Fees,
      name: "Comissions and Fees",
      value: "$37832.11",
    },
    {
      icons: Professional_Services,
      name: "Professional Services",
      value: "$784.11",
    },
  ]);
  return (
    <div className="model-container">
      <div className="model-heading">
        <div className="model-heading-text">
          <p className="main-text">Expense by Category</p>
          <p className="sub-text">See all your expeses</p>
        </div>
        <div className="model-close-btn" onClick={closeModel}>
          X
        </div>
      </div>
      <div className="model-donut-chart">
        <Donut data={data} />
      </div>
      {data?.length > 8 ? (
        <div className="model-expense-list-lar">
          <>
            <div className="model-expense-list-left">
              {data?.slice(0, data?.length / 2).map((ele) => {
                return (
                  <ExpenseCard
                    icons={ele.icons}
                    name={ele.name}
                    value={"$" + FormatNumber(ele.value)}
                  />
                );
              })}
            </div>
            <div className="model-expense-list-right">
              {data?.slice(data?.length / 2).map((ele) => {
                return (
                  <ExpenseCard
                    icons={ele.icons}
                    name={ele.name}
                    value={"$" + FormatNumber(ele.value)}
                  />
                );
              })}
            </div>{" "}
          </>
        </div>
      ) : (
        <div className="model-expense-list">
          <div className="model-expense-list-left-single">
            {data?.map((ele) => {
              return (
                <ExpenseCard
                  icons={customKeyMapping[ele.name]}
                  name={ele.name}
                  value={"$" + FormatNumber(ele.value)}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Model;
