import React from "react";
import "./Expense.scss";
import { FormatName } from "../../Util/FormatName";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Numbers } from "@mui/icons-material";

function ExpenseCard({ icons, name, value, loading }) {


  if(loading) {
  return <div className="donut-expense-item">
  <div className="left-item">
    <div className="left-item-icon">
      <Skeleton circle={true} height={40} width={40} />
    </div>
    <div className="left-item-text">
      <Skeleton width={100} />
    </div>
  </div>
</div>
    
    
  }
  return (
    <div className="donut-expense-item">
      <div className="left-item " >
        <div className="left-item-icon">
          <img className="left-item-icons" src={icons} />
        </div>
        <div className="left-item-text">
          <p>{name}</p>
        </div>
      </div>
      <div className="right-item">
        <div className="right-item-text">
          <p>{value}</p>
        </div>
      </div>
    </div>
  );
}

export default ExpenseCard;
