import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    InputLabel,
    FormControl,
    MenuItem,
    Select
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { AppRegistration, DeleteOutline } from "@mui/icons-material";
import axios from 'axios';
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      textAlign: "start",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "start",
      color: "#808080",
      border: "none",
      height: "30px",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9FAFB",
    },
    "&:nth-of-type(even)": {},
  }));

function Payment() {
  const [paymentAmount, setPaymentAmount] = useState(1000);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentType, setPaymentType] = useState('MONTHLY');
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);


  //const elements = useElements();

  const handlePayment = async () => {
    let userData = localStorage.getItem("userInfo");
    userData = JSON.parse(userData);
    let token = userData.token;

    //const cardElement = elements.getElement(CardElement);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/createPaymentIntent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ type: paymentType }),
    });

    const data = await response.json();
    if(!data.success){
    
        return;
    }
    setClientSecret(data.clientSecret);

    const stripe = "" //await stripePromise;
    const { error } = await stripe.confirmCardPayment(data.clientSecret, {
      payment_method: {
        //card: cardElement,
      },
    });

    if (error) {
      console.error(error);
    } else {
        setLoading(true);
        setTimeout(() => {
            loadPaymentsData();
        },5000);
    }
  };

  const loadPaymentsData = async () => {
    setLoading(true);
    let userData = localStorage.getItem("userInfo");
    userData = JSON.parse(userData);
    let token = userData.token;
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/getAllPayments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (data.data.success === true) {
      setRows(data.data.payments);
      
      setLoading(false);
    }
  };


  

  const selectPaymentType = async (e) => {
    setPaymentType(e.target.value)
  }

  // useEffect(() => {
  //   loadPaymentsData();
  // },[])


  return(
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "100%",
        padding: "5px",
        gap: "10px", 
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          minWidth: "150px", 
        minHeight: "150px", 
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 550 }}>
            Manage your Payments
          </Typography>
        </Box>
        <div>
            <div>
                Select Payment Type:
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paymentType}
                        label="Payment Type"
                        onChange={selectPaymentType}
                    >
                        <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>
                        <MenuItem value={'QUATERLY'}>QUATERLY</MenuItem>
                        <MenuItem value={'YEARLY'}>YEARLY</MenuItem>
                    </Select>
                    <div>Price :: ${paymentType==="MONTHLY" ? '10' : paymentType==="QUATERLY" ? '20' : paymentType==="YEARLY" ? '50' : ''}</div>
                </FormControl>
            </div>
            <div >
             <h3>Enter the card details</h3>   
            {/* <CardElement /> */}
            </div>
            <button onClick={handlePayment}>Pay Now</button>
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          borderRadius: "5px",
          border: "1.5px solid #DBDBDB",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right" sx={{ width: "16%" }}>
                  Payment Id
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ width: "16%" }}>
                  Initiated On
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ width: "16%" }}>
                  Status
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ width: "16%" }}>
                    Completed On
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ width: "16%" }}>
                  Amount
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row,idx) => (
                <StyledTableRow key={idx}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ width: "32%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ color: "black" }}>
                          {row.id}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ width: "16%" }}>
                    {new Date(row.created*1000).toISOString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ width: "16%" }}>
                    <Box
                      sx={{
                        backgroundColor: "#E7F8EE",
                        width: "60px",
                        textAlign: "center",
                        color: "#1F885D",
                        p: 0.5,
                        borderRadius: "26px",
                      }}
                    >
                      "SUCCESS"
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ width: "16%" }}>
                    {row.completed}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ width: "16%" }}>
                    {row.amount}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Payment;
