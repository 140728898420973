import React, { useEffect, useState } from "react";
import Supplies from "../../Icons/expense-categ/Supplies.png";
import Expense from "../../assets/icon/SVG/expense.svg";
import notexpense from "../../assets/icon/SVG/Not-a-Expense.svg";
import notsure from "../../assets/icon/SVG/Not-Sure.svg";
import Personal from "../../assets/icon/SVG/Personal.svg";
import Service from "services/services";
import FilterRadio from "../FilterCard/FilterRadio";
import nondeductibleIcon from "../../assets/icon/SVG/nondedutct-blue.svg";
import income from "../../assets/icon/SVG/income-blue.svg";
import expenseBlue from "../../assets/icon/SVG/expense.svg";
import { categMap } from "../../data/data";
import moment from "moment";
import { FormatNumber } from "Util/FormatName";

function ExpenseMobileCard({
  data,
  expenseHandler,
  type,
  markingProgress,
  markIncome,
  getExpences,
  classes,
  setMobileMarkingModal,
}) {
  let isMarking = false;
  if (markingProgress.id == data.transaction_id) {
    isMarking = true;
  }

  const [selectCatModal, setSelectCatModal] = useState(false);
  const [selectedTranx, setSelectedTranx] = useState();

  const [changeCatLoader, setChangeCatLoader] = useState(false);

  const changeCategoryHandler = (clientCategory) => {
    setChangeCatLoader(true);
    const servies = new Service();
    const payload = {
      clientCategory,
      tranxIds: [selectedTranx.transaction_id],
      sourceCategory: type,
    };

    servies
      .markCategory(payload)
      .then((res) => {
      
        setChangeCatLoader(false);
        setSelectCatModal(false);
        if (type == "NON_DEDUCTIVES") {
          expenseHandler(
            "NEEDS_REVIEW",
            { ...selectedTranx, client_category: clientCategory },
            false,
            false
          );
        } else {
          getExpences(null,false,selectedTranx.transaction_id);
        }
      })
      .catch((err) => {
        setChangeCatLoader(false);
      });
    setMobileMarkingModal(false);
  };


  let actionJsx = (
    <div className="col-12 d-flex flex-column gap-2 justify-content-center align-items-center">
      <button
        disabled={type == "EXPENSE" || isMarking}
        onClick={expenseHandler.bind(this, "EXPENSE", data, false, false)}
        style={{ backgroundColor: "white" }}
        className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
      >
        {isMarking && markingProgress.type == "EXPENSE" ? (
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <div>
              <img src={Expense} alt="supplies" />
            </div>
            <div>Expense</div>
          </div>
        )}
      </button>

      <button
        disabled={type == "NOT_EXPENSE" || isMarking}
        onClick={expenseHandler.bind(this, "NOT_EXPENSE", data, false, false)}
        style={{ backgroundColor: "white" }}
        className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
      >
        {isMarking && markingProgress.type == "NOT_EXPENSE" ? (
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <div>
              <img src={notexpense} alt="supplies" />
            </div>
            <div>Not an Expense</div>
          </div>
        )}
      </button>

      <button
        disabled={type == "NOT_SURE" || isMarking}
        onClick={expenseHandler.bind(this, "NOT_SURE", data, false, false)}
        style={{ backgroundColor: "white" }}
        className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
      >
        {isMarking && markingProgress.type == "NOT_SURE" ? (
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <div>
              <img src={notsure} alt="supplies" />
            </div>
            <div>Not Sure</div>
          </div>
        )}
      </button>
    </div>
  );

  const incomeTypes = [
    "Dividends Income",
    "Income",
    "Interest Income",
    "Retirement Income",
  ];

  if (incomeTypes.includes(data.client_category)) {
    actionJsx = (
      <div className="col-12 d-flex flex-column gap-2 justify-content-center align-items-center">
        <button
          disabled={data.type == "PERSONAL" || isMarking}
          onClick={
            type == "INCOMES"
              ? markIncome.bind(this, "INCOMES", data, false, "PERSONAL")
              : expenseHandler.bind(this, "INCOMES", data, false, "PERSONAL")
          }
          style={{ backgroundColor: "white" }}
          className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
        >
          {isMarking && markingProgress.incomeType == "PERSONAL" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div>
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={Personal}
                  alt="supplies"
                />
              </div>
              <div>Personal</div>
            </div>
          )}
        </button>

        <button
          disabled={data.type == "BUSINESS" || isMarking}
          onClick={
            type == "INCOMES"
              ? markIncome.bind(this, "INCOMES", data, false, "BUSINESS")
              : expenseHandler.bind(this, "INCOMES", data, false, "BUSINESS")
          }
          style={{ backgroundColor: "white" }}
          className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
        >
          {isMarking && markingProgress.incomeType == "BUSINESS" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div>
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={income}
                  alt="supplies"
                />
              </div>
              <div>Business</div>
            </div>
          )}
        </button>
        <button
          disabled={type == "NOT_SURE" || isMarking}
          onClick={expenseHandler.bind(this, "NOT_SURE", data, false, false)}
          style={{ backgroundColor: "white" }}
          className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
        >
          {isMarking && markingProgress.type == "NOT_SURE" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div>
                <img src={notsure} alt="supplies" />
              </div>
              <div>Not Sure</div>
            </div>
          )}
        </button>
      </div>
    );
  }

  if (data.client_category == "Non-Deductible") {
    actionJsx = (
      <div className="col-12 d-flex flex-column gap-2 justify-content-center align-items-center">
        <button
          disabled={type == "NON_DEDUCTIVES" || isMarking}
          onClick={expenseHandler.bind(
            this,
            "NON_DEDUCTIVES",
            data,
            false,
            false
          )}
          style={{ backgroundColor: "white" }}
          className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
        >
          {isMarking && markingProgress.type == "NON_DEDUCTIVES" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div>
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={nondeductibleIcon}
                  alt="supplies"
                />
              </div>
              <div> Non Deductible</div>
            </div>
          )}
        </button>

        <button
          disabled={data.type == "BUSINESS" || isMarking}
          onClick={() => {
            setSelectedTranx(data);
            setSelectCatModal(true);
          }}
          style={{ backgroundColor: "white" }}
          className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
        >
          {isMarking && markingProgress.incomeType == "BUSINESS" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div>
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={expenseBlue}
                  alt="supplies"
                />
              </div>
              <div>Deductible</div>
            </div>
          )}
        </button>
        <button
          disabled={type == "NOT_SURE" || isMarking}
          onClick={expenseHandler.bind(this, "NOT_SURE", data, false, false)}
          style={{ backgroundColor: "white" }}
          className="border rounded-1 p-3 fw-bolder gap-2 text-primary col-12 d-flex flex-row justify-content-center align-items-center"
        >
          {isMarking && markingProgress.type == "NOT_SURE" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div>
                <img src={notsure} alt="supplies" />
              </div>
              <div>Not Sure</div>
            </div>
          )}
        </button>
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-column gap-3 justify-content-center align-items-center border rounded-2 m-2 p-3"
      style={{ backgroundColor: "white" }}

    >
      {selectCatModal && (
        <div>
          <div
            onClick={() => {
              setSelectCatModal(false);
            }}
            className={[classes["back-drop"]].join(" ")}
          ></div>
          <div className={[classes["modal"]].join(" ")}>
            <FilterRadio
              changeCategoryHandler={changeCategoryHandler}
              changeCatLoader={changeCatLoader}
              classes={classes}
              selectedTranx={selectedTranx}
            />
          </div>
        </div>
      )}
      <div className="p-2 border-bottom gap-2 col-12 d-flex  flex-column  justify-content-center align-items-center" >
        <div
          className="d-flex gap-1 flex-row border rounded-5 ps-2 pe-2 justify-content-center align-items-center"
          style={{
            backgroundColor: categMap[data.client_category]?.backgroundColor,
          }}
        >
          <div>
            <img
              style={{ height: "21px", width: "21px" }}
              src={categMap[data.client_category]?.icons}
              alt=""
            />
          </div>
          <div
            style={{
              fontSize: "0.8rem",
              color: categMap[data.client_category]?.color,
            }}
          >
            {data.client_category}
          </div>
        </div>
        <div className="text-dark fs-1 fw-bold">
          ${FormatNumber(data.amount || 0)}
        </div>
        <div className="d-flex flex-row gap-1 text-muted mb-2">
          <div>{`${data.merchant_name}  | `}</div>
          <div>
            {moment(data.date).format("MM-DD-YYYY")}
          </div>
        </div>
      </div>
      {actionJsx}
    </div>
  );
}

export default ExpenseMobileCard;
