import React from "react";
import "./Navitem.scss";

function NavItem({ page_name, start_icon, end_icon, ActionToggle }) {
  return (
    <div className="navItem-container">
      <div className="navItem-subcontainer">
        <div className="navItem">
          <img className="sidebar-icons" src={start_icon} />
        </div>
        {ActionToggle && <div className="navItem-text">{page_name}</div>}
      </div>
      {ActionToggle && (
        <div className="navItem-sidebar-icons">
          <img className="sidebar-icons" src={end_icon} />
        </div>
      )}
    </div>
  );
}

export default NavItem;
