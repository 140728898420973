import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { useAppContext } from "Store/AppContext";
import Edits from "../../assets/icon/SVG/SVG_ICONS/Edits.svg";
import Skeleton from "react-loading-skeleton";
import { FormatNumber } from "Util/FormatName";
import { trackUserMP } from "services/mixpanel";
import { Alert, Snackbar } from "@mui/material";

function Reports() {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [otherCateg, setOtherCat] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [otherExpenses, setOtherExpenses] = useState([]);
  const [isloading, setLoading] = useState(true);
  const [isDownloading, setDownloading] = useState(false);
  const [isDownloaded, setDownloaded] = useState(false);
  const [store, dispatch] = useAppContext();

  useEffect(() => {
    trackUserMP("Reports Page");
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/report`,
        { ...store.globalFilter, profession_type: store.User.profession_type },
        {
          headers: {
            Authorization: `Bearer ${store.BearerToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const data = res.data;
        const otherCateg = data.OtherCategeory;
        setData(data.FinalData);
        setOtherCat(otherCateg);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        return;
      });
  }, [store.globalFilter]);

  const handleInputChange = (key, e) => {
    let { name, value } = e.target;

    if (name == "business") {
      value = value.split("%")[0];
      if (Number(value) > 100 || Number(value) < 0) {
        return;
      }
      const newArr = Data;
      const data = Data[key];
      const newData = {
        ...data,
        [name]: value,
        ["TaxDeduction"]: ((Number(value) * Number(data.amt)) / 100).toFixed(2),
      };
      newArr[key] = newData;
      setData([...newArr]);
    } else {
      const newArr = Data;
      const data = Data[key];
      const newData = {
        ...data,
        [name]: data[key]?.note ? data[key]?.note + value : value,
      };
      newArr[key] = newData;
      setData([...newArr]);
    }
  };

  const convertToCSV = () => {
    let csv1 =
      "Schedule C Category,,Line Number,,Gross Expense Amount,,Business-Use Percentage,,Tax Deduction,,Notes\n";
    for (const item of Data) {
      csv1 += `${item["Schedule C Category"]},,${item.line},,$${item.amt},,${
        item.business
      }%,,$${item.TaxDeduction || item.amt},,${item.note ? item.note : ""},,\n`;
    }
    return csv1;
  };


  const reportDownloadHandler = async () => {
    setDownloading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/expenses`,
        {
          type: "EXPENSE",
          ...store.globalFilter,
          profession_type: store.User.profession_type,
        },
        {
          headers: {
            Authorization: `Bearer ${store.BearerToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const data = res.data;
        const result = data.result.data;
        const Expense = result.filter(
          (ele) => !otherCateg.includes(ele.client_category)
        );

        const otherExpense = result.filter((ele) =>
          otherCateg.includes(ele.client_category)
        );

        const convertToCSVTransaction = () => {
          let csv2 =
            "Date,,Merchant,,Expense Amount,,Schedule C Category,,Expense Details\n";
          for (const item of Expense) {
            csv2 += `${item.date?.split("T")[0]},,${item.merchant_name},,${
              "$" + item.amount
            },,${item.client_category},,${item.category},,\n`;
          }

          return csv2;
        };

        const convertToCSVOtherTransaction = () => {
          let csv3 =
            "Date,,Merchant,,Expense Amount,,Schedule C Category,,Expense Details\n";

          for (const item of otherExpense) {
            csv3 += `${item.date?.split("T")[0]},,${item.merchant_name},,${
              "$" + item.amount
            },,${item.client_category},,${item.category}\n`;
          }

          return csv3;
        };

        const csvData = convertToCSV();
        const csvData2 = convertToCSVTransaction();
        const csvData3 = convertToCSVOtherTransaction();
        // Split the CSV data into rows
        const csvRows = csvData.split("\n");
        const csvRows2 = csvData2.split("\n");
        const csvRows3 = csvData3.split("\n");

        // Create a new workbook and add a worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(
          csvRows.map((row) => row.split(",,"))
        );
        XLSX.utils.book_append_sheet(wb, ws, "Schedule C Expense Categories");

        const ws2 = XLSX.utils.aoa_to_sheet(
          csvRows2.map((row) => row.split(",,"))
        );
        XLSX.utils.book_append_sheet(wb, ws2, "All Business Expenses");

        const ws3 = XLSX.utils.aoa_to_sheet(
          csvRows3.map((row) => row.split(",,"))
        );
        XLSX.utils.book_append_sheet(wb, ws3, "Others Expenses - Grouped");
        // Generate the Excel file and set it in the state
        XLSX.writeFile(wb, "Taxfluence Report.xlsx");

        setDownloading(false);
        setDownloaded(true);
      })

      .catch((error) => {
        console.log(error);
        setDownloading(false);
        return;
      });
  };

  return (
    <div class="container col-12">
      <Snackbar
        open={isDownloaded}
        autoHideDuration={6000}
        onClose={() => setDownloaded(false)}
        //anchorOrigin={{sm: {vertical: "top", horizontal: "center"},md:{vertical:"auto",horizontal:"auto"} }}
      >
        <Alert severity="success" sx={{ color: "green" }}>
          Report Downloaded Successfully
        </Alert>
      </Snackbar>
      <div className="col-12 pb-3  d-flex flex-row  justify-content-end ">
        <button
          type="button"
          class="btn btn-primary text-light p-2"
          onClick={reportDownloadHandler}
          disabled={isDownloading}
        >
          {isDownloading && (
            <div
              class="spinner-border spinner-border-sm me-2"
              role="status"
            ></div>
          )}
          Download CSV
        </button>
      </div>
      <div className="border border-2 rounded-2">
        <div className="d-flex d-md-none" style={{ margin: 0, padding: 0 }}>
          <div className="">
            <table className="table table-striped ">
              <thead
                className="rounded-top-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "white",
                }}
              >
                <th
                  className="text-primary col-2"
                  style={{
                    height: "5.3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Schedule Category
                </th>
              </thead>
              {isloading ? (
                <tbody>
                  <tr>
                    <td>
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {Data?.map((ele, id) => {
                    return (
                      <tr
                        key={id}
                        style={{
                          height: "5.3rem",
                        }}
                      >
                        <td
                          className="col-2"
                          style={{
                            backgroundColor: id % 2 === 0 ? "#eff7ff" : "white",
                            display: "flex",
                            width: "100%",
                            height: "5.3rem",
                            overflow: "hidden",
                            padding: "5px",
                            width: "13rem",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            boxSizing: "border-box",
                          }}
                        >
                          {ele["Schedule C Category"].split(0, 20)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          <div className="w-80" style={{ overflowX: "auto" }}>
            <table className="table table-striped fixed-width-table">
              <thead
                className="rounded-top-4"
                style={{
                  textAlign: "center",
                  backgroundColor: "white",
                }}
              >
                <th
                  className="text-primary col-1"
                  style={{
                    height: "5.3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Line #
                </th>
                <th
                  className="text-primary col-2"
                  style={{
                    height: "5.3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Gross Amount
                </th>
                <th
                  className="text-primary col-2"
                  style={{
                    height: "5.3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Business Use %
                </th>
                <th
                  className="text-primary col-2"
                  style={{
                    height: "5.3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Tax Deduction
                </th>
                <th
                  className="text-primary col-3"
                  style={{
                    height: "5.3rem",
                    display: "table-cell",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                  <img src={Edits} alt="edit icons" /> Notes
                </th>
              </thead>
              {isloading ? (
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                    <td>
                      {" "}
                      <Skeleton height={50} />{" "}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {Data.map((ele, id) => (
                    <tr
                      key={id}
                      style={{
                        textAlign: "center",
                        height: "5.3rem", // Set the fixed height for the row
                      }}
                    >
                      <td
                        className="col-1"
                        style={{
                          display: "table-cell",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {ele.line}
                      </td>
                      <td
                        className="col-2"
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {`$ ${FormatNumber(ele.amt || 0)}`}
                      </td>
                      <td
                        className="col-2"
                        style={{
                          display: "table-cell",
                          textAlign: "center",
                          width: "100%",
                          boxSizing: "border-box",
                          verticalAlign: "middle",
                        }}
                        data-column="business-use-percentage"
                      >
                        <input
                          value={ele["business"] + "%"}
                          name="business"
                          onChange={(e) => handleInputChange(id, e)}
                          style={{
                            border: "2px solid #C1C1C1",
                            boxSizing: "border-box",
                            borderRadius: "10px",
                            width: "100%",
                            background: "transparent",
                            textAlign: "center",
                            padding: "4px",
                          }}
                          
                        />
                      </td>
                      <td
                        className="col-2"
                        style={{
                          display: "table-cell",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {"$" + FormatNumber(ele.TaxDeduction || ele.amt)}
                      </td>
                      <td
                        className="col-3"
                        style={{
                          height: "5.3rem",
                          display: "flex",
                          width: "100%",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "border-box",
                        }}
                        data-column="notes"
                      >
                        <textarea
                          type="text"
                          value={ele.note ? ele.note : ""}
                          name="note"
                          style={{
                            background: "transparent",
                            height: "3.5rem",
                            width: "10rem",
                            boxSizing: "border-box",
                            border: "2px solid #C1C1C1",
                            borderRadius: "10px",
                            padding: "5px",
                            margin: 0,
                          }}
                          onChange={(e) => handleInputChange(id, e)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/* for wideer */}
        <div
          className="d-none d-md-block"
          style={{ overflowX: "auto", margin: 0, padding: 0 }}
        >
          <table className="table table-striped fixed-width-table">
            <thead
              className="rounded-top-4"
              style={{
                height: "5rem",
                textAlign: "center",
                backgroundColor: "white",
              }}
            >
              <th
                className="text-primary col-2"
                style={{ paddingBottom: "25px" }}
              >
                Schedule Category
              </th>
              <th
                className="text-primary col-1"
                style={{ paddingBottom: "25px" }}
              >
                Line #
              </th>
              <th
                className="text-primary col-2"
                style={{ paddingBottom: "25px" }}
              >
                Gross Amount
              </th>
              <th
                className="text-primary col-2"
                style={{ paddingBottom: "25px" }}
              >
                Business Use %
              </th>
              <th
                className="text-primary col-2"
                style={{ paddingBottom: "25px" }}
              >
                Tax Deduction
              </th>
              <th
                className="text-primary col-3"
                style={{ paddingBottom: "25px" }}
              >
                {" "}
                <img src={Edits} alt="edit icons" /> Notes
              </th>
            </thead>
            {isloading ? (
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton height={50} />{" "}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {Data?.map((ele, id) => (
                  <tr
                    key={id}
                    style={{
                      minHeight: "5rem",
                      textAlign: "center",
                    }}
                  >
                    <td
                      className="col-2"
                      style={{
                        backgroundColor: id % 2 == 0 ? "#eff7ff" : "white",
                        height: "5rem",
                        display: "table-cell",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {ele["Schedule C Category"]}
                    </td>
                    <td
                      className="col-1"
                      style={{
                        display: "table-cell",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {ele.line}
                    </td>
                    <td
                      className="col-2"
                      style={{
                        display: "table-cell",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {"$" + FormatNumber(ele.amt)}
                    </td>
                    <td
                      className="col-2"
                      style={{
                        display: "table-cell",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                      data-column="business-use-percentage"
                    >
                      <input
                        value={ele["business"] + "%"}
                        name="business"
                        onChange={(e) => handleInputChange(id, e)}
                        style={{
                          border: "2px solid #C1C1C1",
                          borderRadius: "10px",
                          width: "70px", // Adjust width to accommodate the percentage symbol
                          background: "transparent",
                          textAlign: "center", // Align text to the right for a better visual
                          padding: "5px",
                          display: "table-cell",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      />
                    </td>
                    <td
                      className="col-2"
                      style={{
                        display: "table-cell",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {"$" + FormatNumber(ele.TaxDeduction || ele.amt)}
                    </td>
                    <td
                      className="col-3"
                      style={{
                        height: "5rem",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      data-column="notes"
                    >
                      <textarea
                        type="text"
                        value={ele.note ? ele.note : ""}
                        name="note"
                        style={{
                          background: "transparent",
                          height: "100%",
                          minWidth: "100%",
                          border: "2px solid #C1C1C1",
                          boxSizing: "border-box",
                          borderRadius: "10px",
                          boxSizing: "border-box",
                          padding: "5px",
                        }}
                        onChange={(e) => handleInputChange(id, e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
export default Reports;
