import React, { useState, useEffect } from "react";
import NavItem from "./NavItem";
import "./Responsive_Navbar.scss";
import actionIcon from "../../assets/icon/SVG/SVG_ICONS/Nav-Bar.svg";
import editIcon from "../../assets/icon/SVG/SVG_ICONS/Edit.svg"
import adminIcon from "../../assets/icon/SVG/SVG_ICONS/User-Profile.svg"
import helpIcon from "../../assets/icon/SVG/SVG_ICONS/Help_Center.svg"
import homeIcon from "../../assets/icon/SVG/SVG_ICONS/Home.svg"
import bankIcon from "../../assets/icon/SVG/SVG_ICONS/Link-Bank.svg" 
import reportIcon from "../../assets/icon/SVG/SVG_ICONS/Reporting.svg";
import taxcalIcon from "../../assets/icon/SVG/SVG_ICONS/Calculator.svg"
import transIcon from "../../assets/icon/SVG/SVG_ICONS/Transaction.svg"
import humburgIcon from "../../assets/icon/SVG/SVG_ICONS/Hamburguer.svg"
import closeIcon from "../../assets/icon/SVG/SVG_ICONS/close.svg"
import Responsive_navItem from "./NavItem/Responsive_navItem";
import { useNavigate } from "react-router-dom";

function Responsive_Navbar({ setClose }) {
  const navigate = useNavigate();
  const [routeLocation, setLocation] = useState("/");
  useEffect(() => {
    const link = window.location.pathname;
    setLocation(link);
  }, [window.location.href]);

  const Style = {
    active: {
      backgroundColor: "#F3F4F6",
      borderRadius: "0.4rem",
      color:"#000",
    },
  };
  const sidebar = [
    { start_icon: homeIcon, page_name: "Home", end_icon: "", link: "/" },
    {
      start_icon: transIcon,
      page_name: "Transactions",
      end_icon: "",
      link: "/transaction",
    },
    {
      start_icon: reportIcon,
      page_name: "Reports",
      end_icon: "",
      link: "/reports",
    },
    // {
    //   start_icon: taxcalIcon,
    //   page_name: "Tax Calculators",
    //   end_icon: editIcon,
    //   link: "/taxcalculation",
    // },
    // {
    //   start_icon: helpIcon,
    //   page_name: "Help",
    //   end_icon: editIcon,
    //   link: "/help",
    // },
  ];

  const sidebar_bottom = [
    {
      start_icon: adminIcon,
      page_name: "Settings",
      end_icon: "",
      link: "/settings",
    },
    {
      start_icon: bankIcon,
      page_name: "Account(s)",
      end_icon: "",
      link: "/linkaccount",
    },
  ];

  return (
    <div className="sidebar-container-res">
      <div className="container-top-res">
        <div className="head-container">
          <h4 className="logo-heading">Taxfluence</h4>
          <div className="close-icon" onClick={()=>setClose(false)}>
            <img src={closeIcon} />
          </div>
        </div>
        {sidebar.map((ele, id) => {
          const isClicable = routeLocation == ele.link;
          return (
            <div
              key={id}
              style={isClicable ? Style.active : {}}
              onClick={() => {
                navigate(`${ele.link}`);
                setClose(false);
              }}
            >
              <Responsive_navItem
                start_icon={ele.start_icon}
                page_name={ele.page_name}
                end_icon={ele.end_icon}
              />
            </div>
          );
        })}
      </div>
      <div className="container-bottom-res">
        <h6 className="bottom-text-res">My Account</h6>
        <div className="bottom-element-res">
          {sidebar_bottom.map((ele, id) => {
            const isClicable = routeLocation == ele.link;
            return (
              <div
                id={id}
                style={isClicable ? Style.active : {}}
                onClick={() => {
                  navigate(`${ele.link}`);
                  setClose(false);
                }}
              >
                <Responsive_navItem
                  start_icon={ele.start_icon}
                  page_name={ele.page_name}
                  end_icon={ele.end_icon}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Responsive_Navbar;
