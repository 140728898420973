import React, { useState } from "react";
import { Box, Typography, MobileStepper } from "@mui/material";
import "./Signup.scss";
import Taxfluence from "../../Icons/Taxfluence.png";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step1 from "./Step1";
import { Link } from "react-router-dom";

function SignupStepper() {
  const [activeSteps, setActivesteps] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    comfirmPassword: "",
    accountType:"Independent/freelance",
    Questions: {
      "What kinds of work do you do?":"Independent/freelance"
    },
  });

  const nextHandler = (event) => {
    event.preventDefault();
    setActivesteps((activeSteps) => activeSteps + 1);
  };

  const prevHandler = (event) => {
    event.preventDefault();
    if(activeSteps > 0){
      setActivesteps((activeSteps) => activeSteps - 1);
    }
  };

  const changeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const setToken = (token) => {
    setFormData((formData) => ({
      ...formData,
      token: token,
    }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
  };

  return (
    <div className="stepper-container container-fluid d-flex  justify-content-center">
      <div className="stepper-sub-container col-12 bg-white col-lg-5 col-xl-4 col-md-7 col-sm-8 rounded-1 p-2 pt-4 d-flex-col ">
        <div className="col-12 mt-4 mb-4 d-flex justify-content-center">
          <img className="stepper-logo" src={Taxfluence} />
        </div>
        <div className="mt-2 mb-3 d-flex-col ">
          {activeSteps === 0 && (
            <Step1
              formData={formData}
              activeSteps={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
            />
          )}
          {/* {activeSteps === 1 && (
            <Step2
              formData={formData}
              activeStep={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
            />
          )} */}
       {activeSteps === 1 && (
            <Step3
              formData={formData}
              activeStep={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
            />
          )}
            {/*  {activeSteps === 3 && (
            <Step4
              formData={formData}
              activeStep={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
            />
          )} */}
          {activeSteps === 2 && (
            <Step5
              formData={formData}
              activeStep={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
            />
          )}
          {activeSteps === 3 && (
            <Step6
              formData={formData}
              activeStep={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
              setToken={setToken}
            />
          )}
          {activeSteps === 4 && (
            <Step7
              formData={formData}
              activeStep={activeSteps}
              setActivesteps={setActivesteps}
              setFormData={setFormData}
              prevHandler={prevHandler}
            />
          )}
        </div>
        <div>
        <div className="col-12 d-flex flex-col justify-content-center pb-4 pt-2 gap-1">
            {`Do you have an account?`} <Link to={'/login'}> Login</Link>
          </div>
          <div className="col-12 d-flex flex-col justify-content-center">{`Step ${
            activeSteps + 1
          } of 5`}</div>
          <div className="col-12 d-flex flex-col pb-4 justify-content-center">
            <MobileStepper
              steps={5}
              position="static"
              activeStep={activeSteps}
              //   nextButton={}
              //   backButton={}
            />
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default SignupStepper;

//5 compare pawsword
//6 verify OTP than create user
