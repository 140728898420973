import React from "react";
import "./Tiles.scss";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Tiles({ name, value, loading }) {

  if(loading) {
    return <div className="card p-3">
    <small className="fw-light text-muted">
      <Skeleton width={100} height={15} />
    </small>
    <div className="fs-4 fw-bold mb-0">
      <Skeleton style={{width:'40%',height:'10%'}} />
    </div>
  </div>
  }
  return (
    <div className="card p-3 ">
      <small className="fw-light text-muted">{name}</small>
      <div className="fs-4 fw-bold mb-0">{value}</div>
    </div>
  );
}

export default Tiles;
