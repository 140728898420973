import React from "react";

import vertical from "../../assets/icon/SVG/loading.svg";
import amx from "../../assets/icon/SVG/card/Bank.svg";
import updateIcon from "../../assets/icon/SVG/Update.svg";
import moment from "moment";
import "moment-timezone";
import { FormatNumber } from "Util/FormatName";

function CreditCardMobile({
  mobileCreditClickHandler,
  each,
  account_ids,
  classes,
  deleteCardHandler,
  handleSyncTransactions,
}) {
  each = each.bankAccount;

  const closeOtherDropdowns = (e, account_id) => {
    // Prevent the click event from propagating up the DOM tree
    e.stopPropagation();

    // Find and close other open dropdowns
    const openDropdowns = document.querySelectorAll(".dropdown-menu.show");
    openDropdowns.forEach((dropdown) => {
      if (dropdown.id !== account_id) {
        dropdown.classList.remove("show");
      }
    });
  };
  return (
    <div
      onClick={mobileCreditClickHandler.bind(this, true, each)}
      className={[
        "card",
        "border-2",
        account_ids.includes(each.account_id) ? "border-primary" : "",
        "p-3",
        classes["credit-card"],
      ].join(" ")}
    >
      <div className="d-flex flex-column justify-content-center align-items-start">
        <div className="d-flex justify-content-between w-100">
          <small className="text-center text-muted fw-light">
            {each.official_name} {each.mask}
          </small>
          <div className="dropdown">
            <img
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => {
                closeOtherDropdowns(e, each.account_id);
              }}
              src={vertical}
              alt="option"
            ></img>

            <ul
              id={each.account_id}
              className="dropdown-menu"
              aria-labelledby="dropdownMenuLink"
            >
              <li>
                <a
                  onClick={deleteCardHandler.bind(this, each.account_id)}
                  className="dropdown-item link-danger"
                  href="#"
                >
                  <i className="bi bi-trash"></i> Delete Card
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-center fs-4 fw-bold mb-0">
          ${FormatNumber(each.balances || 0)}
        </p>
      </div>
      <div className="d-flex justify-content-between  mt-3 flex-wrap">
        <div className="">
          <img src={amx} alt="amx"></img>
          <small
            style={{ marginLeft: "5px" }}
            className=" text-muted fw-light ml-"
          >
            Ending in {each.mask}
          </small>
        </div>

        <div className="d-flex align-items-center">
          <small
            className=" text-muted fw-light"
            style={{ marginRight: "5px" }}
          >
            updated at {new Date(each.updatedAt).toLocaleString()}
          </small>
          <button
            onClick={handleSyncTransactions.bind(this, each.item_id)}
            className="sync-icon rounded-1 align-items-center justify-content-center d-flex p-1"
          >
            <img src={updateIcon} alt="amx"></img>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreditCardMobile;
