import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import { AppProvider, useAppContext } from "./Store/AppContext";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";
// Initialize the Facebook Pixel with your Pixel ID
ReactPixel.init("425199968556158");

const tagManagerArgs = {
  gtmId: "G-LQGJWGK34H",
};

// Initialize Mixpanel with your token
mixpanel.init("YOUR_MIXPANEL_TOKEN");

// const tagManagerArgs = {
//     gtmId: "GTM-T93KMBC",
//   };

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <script type="text/javascript" id="zsiqchat">
        {`
          var $zoho = $zoho || {};
          $zoho.salesiq = $zoho.salesiq || { widgetcode: "siq375e9a287840858fc0b36f0cb56cd1f90ce5374b1e24ee8e6dad75ddc6e56725", values: {}, ready: function() {} };
          var d = document;
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.id = "zsiqscript";
          s.defer = true;
          s.src = "https://salesiq.zohopublic.com/widget";
          var t = d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s, t);
        `}
      </script>
    </Helmet>

    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
