import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { categMap } from 'data/data';

ChartJS.register( ArcElement,Tooltip, Legend);
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function Donut(props) {
  
  const values = props.data?.map(each=>{
    return each.value
  });
  const labels = props.data?.map(each=>{
    return each.name.split("_").join(" ")
  });
  const backgroundColors = labels?.map((ele) => categMap[ele].backgroundColor);
  const hoverBackgroundColors = backgroundColors.slice();
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: hoverBackgroundColors,
      },
    ],
  };
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend (labels)
      },
    },
  };

  return <Doughnut data={data} options={options} />;
}
