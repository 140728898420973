import React from "react";
import AmericanExp from "../../Icons/cards/American Express.svg";
import Deleted from "../../Icons/delete.png";
import Edit from "../../Icons/edit.png";

function LinkedAccount({
  icons = AmericanExp,
  status = "Active",
  cardName = "Main Credit Card",
  cardType = "Credit Card",
  LastUpadte = "Updated 4 Days Ago",
  Mask_Num="**** **** **** 1234",
  id=2,
  deleteCardHandler,
  acc_id
}) {

  const onClickDelete = () => {
    if(window.confirm("Are you sure you want to delete this card?")){
      deleteCardHandler(acc_id)
    }
    //deleteCardHandler.bind(this,acc_id)
  }

  const style = id%2==0 ? "p-3 d-flex flex-row justify-content-center align-items-center":"p-3 bg-white d-flex flex-row justify-content-center align-items-center"
  return (
    <div className={style}>
      <div className="col-4 gap-3 d-flex flex-row align-items-center">
        <div>
          <img
            style={{ height: "2rem" }}
            className="img-fluid"
            src={icons}
            alt="card_logo"
          />
        </div>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex  flex-row gap-3 justify-content-between" style={{fontSize:"15px",fontWeight:550 }}>
            {cardName}
          </div>
          <div className="d-flex text-muted pe-3 flex-row gap-3 justify-content-between" style={{fontSize:"14px" }}>
            {cardType}
          </div>
        </div>
      </div>
      <div className="col-2 d-flex">
        <div className="d-flex">
          {status == "Active" ? (
            <div
              className="pe-2 ps-2 rounded-4"
              style={{ backgroundColor: "#e6f8ee", color: "#21885e",fontSize:"14px"  }}
            >
              {status}
            </div>
          ) : (
            <div
              className=" pe-2 ps-2 rounded-4"
              style={{ backgroundColor: "#F2CDCA", color: "#FF1000",fontSize:"14px"  }}
            >
              {status}
            </div>
          )}
        </div>
      </div>
      <div className="col-2">
        <div className="d-flex text-muted pe-3 flex-row gap-3 justify-content-between" style={{fontSize:"14px" }}>
          {Mask_Num}
        </div>
      </div>
      <div className="col-2">
        <div className="d-flex text-muted pe-3 flex-row gap-3 justify-content-between" style={{fontSize:"14px" }}>
          {LastUpadte}
        </div>
      </div>
      <div className="col-2 d-flex flex-row gap-4">
        <div style={{cursor:"pointer"}}>
          <img
            style={{ height: "1.2rem" }}
            className="img-fluid"
            src={Edit}
            alt="card_logo"
            
          />
        </div>
        <div style={{cursor:"pointer"}}>
          <img
            style={{ height: "1.2rem" }}
            className="img-fluid"
            src={Deleted}
            alt="card_logo"
            onClick={onClickDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default LinkedAccount;