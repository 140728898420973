import Advertisement from './Advertisement.svg';
import Travel from './Travel.svg';
import Assets from './Assets.svg';
import Car from './Car and Truck.svg';
import Commission from './Commission & Fees.svg';
import Contract from './Contract Labor.svg';
import Home from './Home Office.svg';
import Insurance from './Insurance.svg';
import Interest from './Interest Paid.svg';
import Meals from './Meals.svg';
import Office from './Office Expenses.svg';
import Professional from './Professional Services.svg';
import Rent from './Rent and Lease.svg';
import Repairs from './Repairs.svg';
import Supplies from './Supplies.svg';
import Taxes from './Taxes and Licenses.svg';
import Utilities from './Utilities.svg';
import Other from './Other.svg';

import card_repayment from './Car Repayment.svg';
import Charitable_Contribution from './Charitable Contributions.svg';
import Dividends_income from './Dividends_Income.svg';
import Entertainment from './Entertainment.svg';
import Home_material from './Home Material.svg';
import House_repair from './House Repair.svg';
import Income from './Income.svg';
import Interest_Income from './Interest Income.svg';
import Loan_Proceeds from './Loan Proceeds.svg';
import Loan_Repayment from './Loan Repayment.svg';
import Non_deductible from './Non Deductible.svg';
import Retirement_Income from './Retirement Income.svg';
import taxrefund from './Tax Refund.svg';
import Transfer_in from './Transfer In.svg';
import Transfer_out from './Transfer Out.svg';



const customKeyMapping = {
  'Advertising': Advertisement,
  'Travel': Travel,
  'Assets': Assets,
  'Car and Truck': Car,
  'Commission & Fees': Commission,
  'Contract Labor': Contract,
  'Home Office': Home,
  'Insurance': Insurance,
  'Interest Paid': Interest,
  'Meals': Meals,
  'Office Expenses': Office,
  'Professional Services': Professional,
  'Rent and Lease': Rent,
  'Repairs': Repairs,
  'Supplies': Supplies,
  'Taxes and Licenses': Taxes,
  'Utilities': Utilities,
  'Other': Other,
  'Card Repayment':card_repayment,
  'Home Material':Home_material,
  'House Repair':House_repair,
  'Tax Refunds':taxrefund,
  'Loan Proceeds':Loan_Proceeds,
  'Transfer In':Transfer_in,
  'Retirement Income':Retirement_Income,
  'Dividends Income':Dividends_income,
  'Income':Income,
  'Loan Repayment':Loan_Repayment,
  'Charitable Contributions':Charitable_Contribution,
  'Transfer Out':Transfer_out,
  'Non Deductible':Non_deductible,
  'Non-Deductible':Non_deductible,
  'Interest Income':Interest_Income,
  'Entertainment':Entertainment,
};

export default customKeyMapping;
