import { Alert, Snackbar } from "@mui/material";
import { useAppContext } from "Store/AppContext";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import { Link } from "react-router-dom";
import { trackUserMPSignup } from "services/mixpanel";
import ReactGA from "react-ga";
ReactGA.initialize("AW-11434864199/MBqICMK4j4cZEMfcyMwq");

function Step6({
  formData,
  activeStep,
  setActivesteps,
  prevHandler,
  setFormData,
  setToken,
}) {
  const [phone, setPhone] = useState("");
  const [count, setCount] = useState(30);
  const [otp, setOTP] = useState("");
  const [isloading, setLoading] = useState(false);
  const [isOTPsent, setisOTPsent] = useState(false);
  const [store, dispatch] = useAppContext();
  const [msg, setMsg] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const [isfailure, setisFailure] = useState(false);
  const [validationError, setValidationErrors] = useState({});
  const [isVerified, setisVerified] = useState(false);
  const [isResend, setResend] = useState(false);

  const HandlerChange = (e) => {
    e.preventDefault();
    setValidationErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    const { name, value } = e.target;
    if (name == "phone") {
      setPhone(value);
    } else if (name == "otp") {
      setOTP(value);
    }
  };

  const sendOTPHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setCount(30);
    setisOTPsent(false);
    setResend(false);

    if (phone.length > 10) {
      setValidationErrors((prev) => ({
        ...prev,
        ["phone"]: "Please enter a valid phone number",
      }));
      setLoading(false);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/otp/register/send`,
        {
          phone,
        }
      )
      .then((res) => {
        const { data } = res;
        if (!data.success) {
          setMsg(data.message);
          setValidationErrors((prev) => ({
            ...prev,
            ...data.error,
          }));
          setLoading(false);
          return;
        }
        setisSuccess(true);
        setMsg(data.message);
        setisOTPsent(true);
        setLoading(false);
        const newInterval = setInterval(() => {
          setCount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(newInterval);
          setResend(true);
        }, 30000);
        return;
      })
      .catch((error) => {
        setisFailure(true);
        setMsg(error.message);
        setLoading(false);
        return;
      });
  };
  const verifyOTPHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/otp/verify`, {
        otp,
        phone,
      })
      .then((res) => {
        const { data } = res;
        if (!data.success) {
          setisFailure(true);
          setValidationErrors((prev) => ({
            ...prev,
            ...data.error,
          }));
          setMsg(data.message);
          setLoading(false);
          return;
        }
        axios
          .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/register`, {
            ...formData,
            otp,
            phone,
          })
          .then((res) => {
            const { data } = res;
            if (!data.success) {
              setisFailure(true);
              setMsg(data.message);
              setLoading(false);
              return;
            }

            // Call the Zoho endpoint to add the lead
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/zoho`,
                formData
              )
              .then((res) => {
                console.log("Lead added to Zoho CRM");
              })
              .catch((error) => {
                console.error("Error adding lead to Zoho CRM:", error);
              });

            setisSuccess(true);
            setMsg(data.message);
            setisOTPsent(true);
            dispatch({
              type: "SET_BEARER_TOKEN",
              payload: data.token,
            });
            dispatch({
              type: "SET_USER",
              payload: data.user,
            });
            setToken(data.token);
            localStorage.setItem("userInfo", JSON.stringify(data.user));

            setTimeout(() => {
              setActivesteps((prev) => prev + 1);
              setLoading(false);
            }, 1000);
            return;
          })
          .catch((error) => {
            setisFailure(true);
            setMsg(error.message);
            setLoading(false);
            return;
          });
      })
      .catch((erro) => {
        setisFailure(true);
        setMsg("Error in sending OTP");
        setLoading(false);
        return;
      });
  };

  const Headings = [
    "What’s your phone number?",
    "This will be used for logging you in. We’ll never spam or disclose your phone number to 3rd parties.",
  ];

  useEffect(() => {
    ReactPixel.track("Lead");

    TagManager.dataLayer({
      dataLayer: {
        event: "Lead-converted",
        myVariable: "Lead-converted",
      },
    });

    ReactGA.event({
      category: "User",
      action: "Lead",
    });
  }, []);

  useEffect(() => {
    trackUserMPSignup("Signup Step 4", "Phone Number", "User phone Number");
  }, []);
  //pushing

  return (
    <div class="container step-container">
      <div class="row justify-content-center">
        <form onSubmit={isOTPsent ? verifyOTPHandler : sendOTPHandler}>
          <div className="form-container">
            <div class="text-center col-12 d-flex-col  mb-3 ">
              <div className="fw-medium fs-6">{Headings[0]}</div>
              <div className="sub-heading">{Headings[1]}</div>
            </div>
            <div class="col-12 mb-2">
              <div class="mb-2">
                {!isOTPsent ? (
                  <>
                    <label for="Full Name" class="form-label">
                      {"Phone Number"}
                    </label>
                    <input
                      type="tel"
                      class={`form-control rounded-1 ${
                        validationError.phone && "is-invalid"
                      }`}
                      id="phone"
                      value={phone}
                      onChange={HandlerChange}
                      name="phone"
                      aria-describedby="name"
                    />
                    <div
                      id="validationServerUsernameFeedback"
                      class="invalid-feedback"
                    >
                      {validationError.phone}
                    </div>
                  </>
                ) : (
                  <>
                    <label for="Full Name" class="form-label">
                      {"Enter OTP"}
                    </label>
                    <input
                      type="tel"
                      class={`form-control rounded-1 ${
                        validationError.otp && "is-invalid"
                      }`}
                      id="otp"
                      value={otp}
                      onChange={HandlerChange}
                      name="otp"
                      aria-describedby="name"
                    />
                    <div
                      id="validationServerUsernameFeedback"
                      class="invalid-feedback"
                    >
                      {validationError.otp}
                    </div>
                  </>
                )}
              </div>
            </div>
            {isOTPsent && (
              <div className="col-12 d-flex flex-col gap-1 justify-content-end">
                {!isResend ? (
                  `Resend OTP after ${count} seconds`
                ) : (
                  <Link
                    to={""}
                    className="text-decoration-none"
                    onClick={sendOTPHandler}
                  >
                    {" "}
                    Resend OTP
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="col-12 d-flex justify-content-between mt-4 flex-row-reverse">
            <button
              type="submit"
              disabled={isloading}
              class="btn col-5 p-2 btn-primary d-flex justify-content-center align-items-center rounded-2 text-light"
              //onClick={() => setActivesteps((prev) => prev + 1)}
            >
              {isloading && (
                <Spinner animation="border" className="m-1" size="sm" />
              )}
              {isOTPsent ? "Next" : "Send OTP"}
            </button>
            <button
              type="submit"
              class="btn col-5 btn-outline-primary p-2 rounded-2"
              onClick={prevHandler}
            >
              Back
            </button>
          </div>
        </form>
      </div>
      <div className="snakbar-conatiner">
        <Snackbar
          onClose={() => setisFailure(false)}
          autoHideDuration={6000}
          open={isfailure}
        >
          <Alert severity="error" sx={{ color: "red" }}>
            {msg}
          </Alert>
        </Snackbar>
        <Snackbar
          onClose={() => setisSuccess(false)}
          autoHideDuration={6000}
          open={isSuccess}
        >
          <Alert severity="success">{msg}</Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Step6;
