import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ExpenseLoader({ classes, type }) {
  return (
    <div className={["card", "p-3", classes["expence-card"]].join(" ")}>
      <div className="row">
        <div className="col-md-7">
         
        <Skeleton style={{width:'70%'}} height={10} />
       
       <Skeleton style={{width:'50%'}} height={10} />
        </div>
        <div className="col-md-5">
       
                <Skeleton style={{width:'100%'}} height={10} />
       
                <Skeleton style={{width:'70%'}} height={10} />
        
        </div>
      </div>


      <hr></hr>

      {
        type != 'mobile' ?  <div className="row">
        <div className="col-md-12 d-flex justify-content-between">
          <div
            className={[
              "border",
              "d-flex",
              "justify-content-center",
              "rounded-1",
              classes["action-btn"],
            ].join(" ")}
          >
            <button className="btn fw-bold py-2 text-primary">
              <Skeleton width={100} height={30} />
            </button>
          </div>
          <div
            className={[
              "border",
              "d-flex",
              "justify-content-center",
              "rounded-1",
              classes["action-btn"],
            ].join(" ")}
          >
            <button className="btn fw-bold py-2 text-primary">
              <Skeleton width={100} height={30} />
            </button>
          </div>
          <div
            className={[
              "border",
              "d-flex",
              "justify-content-center",
              "rounded-1",
              classes["action-btn"],
            ].join(" ")}
          >
            <button className="btn fw-bold py-2 text-primary">
              <Skeleton width={100} height={30} />
            </button>
          </div>
        </div>
      </div>

      : null
      }
    
    </div>
  );
}

export default ExpenseLoader;
