import moment from "moment-timezone";

moment.updateLocale('en', {
  week: {
    dow: 1, // Set Monday as the first day of the week
  },
});
moment.tz.zonesForCountry('US');
let newDate = new Date();
let month = (0 + (newDate.getMonth() + 1).toString()).slice(-2);
let day = '01';
let year = newDate.getFullYear();

const FilterDate = (x) => {
  const today = new Date();
  const endDate = new Date(today);
  endDate.setDate(today.getDate() - Number(x));

  const startDate = today.toISOString().slice(0, 10); // Format as "yyyy-mm-dd"
  const endDateFormatted = endDate.toISOString().slice(0, 10); // Format as "yyyy-mm-dd"

  return {
    startDate: endDateFormatted,
    endDate: startDate,
  };
};

const FilterDateThis = (value) => {
  if (value === "This week") {
    // Calculate Monday of the current week
    const startDate = moment()
      .tz("America/Los_Angeles")
      .startOf("week")
      .format("YYYY-MM-DD");
    const endDate = moment()
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  }
  if (value === "This month") {
    // Set start date to the 1st day of the current month
    const startDate = `${year}-${month}-${day}`;
    const endDate = moment()
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  }
  if (value === "Last month") {
    // Calculate the start date for last month
    let lastMonthDate = new Date(year, newDate.getMonth() - 1, 1);
    let lastMonthYear = lastMonthDate.getFullYear();
    let lastMonthMonth = (0 + (lastMonthDate.getMonth() + 1).toString()).slice(-2);
    const lastMonthStartDate = `${lastMonthYear}-${lastMonthMonth}-${day}`;
    
    // Calculate the end date for last month
    let lastMonthEndDate = new Date(year, newDate.getMonth(), 0);
    let lastMonthEndDateFormatted = moment(lastMonthEndDate).format("YYYY-MM-DD");
    
    return {
        startDate: lastMonthStartDate,
        endDate: lastMonthEndDateFormatted
    };
  }
  if (value === "This quarter") {
    // Calculate the start date of the current quarter
    let quarterStartMonth = (0 + (Math.floor(newDate.getMonth() / 3) * 3 + 1).toString()).slice(-2); // Determine the start month of the quarter
    const startDate = `${year}-${quarterStartMonth}-${day}`;
    
    const endDate = moment()
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  }
  if (value === "This year") {
    // Set start date to the 1st day of the current year
    const startDate = `${year}-${'01'}-${'01'}`;
    const endDate = moment()
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  }
  // Handle unknown value or default case
  console.error("Invalid value:", value);
  return null;
};

const FilterDateReports = (value) => {
  const today = new Date();
  if (value === "This year") {
    const startDate = `${year}-${'01'}-${'01'}`;
    const endDate = moment()
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  } else if (value == "2023") {
    let startDate = new Date(today.getFullYear() - 1, 0, 2);
    let endDate = new Date(today.getFullYear() - 1, 11, 32);
    startDate = startDate.toISOString().slice(0, 10);
    endDate = endDate.toISOString().slice(0, 10);
    return {
      startDate,
      endDate,
    };
  } else if (value == "2022") {
    let startDate = new Date(today.getFullYear() - 2, 0, 2);
    let endDate = new Date(today.getFullYear() - 2, 11, 32);
    startDate = startDate.toISOString().slice(0, 10);
    endDate = endDate.toISOString().slice(0, 10);
    return {
      startDate,
      endDate,
    };
  } else if (value == "Q1 24") {
    let startDate = `${year}-${'01'}-${'01'}`;
    let endDate = moment()
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD");
    return {
      startDate,
      endDate,
    };
  } else if (value == "Q2 23") {
    let startDate = new Date(today.getFullYear() - 1, 3, 2);
    let endDate = new Date(today.getFullYear() - 1, 5, 31);
    startDate = startDate.toISOString().slice(0, 10);
    endDate = endDate.toISOString().slice(0, 10);
    return {
      startDate,
      endDate,
    };
  } else if (value == "Q3 23") {
    let startDate = new Date(today.getFullYear() - 1, 6, 2);
    let endDate = new Date(today.getFullYear() - 1, 8, 31);
    startDate = startDate.toISOString().slice(0, 10);
    endDate = endDate.toISOString().slice(0, 10);
    return {
      startDate,
      endDate,
    };
  } else if (value == "Q4 23") {
    let startDate = new Date(today.getFullYear() - 1, 9, 2);
    let endDate = new Date(today.getFullYear() - 1, 11, 32);
    startDate = startDate.toISOString().slice(0, 10);
    endDate = endDate.toISOString().slice(0, 10);
    return {
      startDate,
      endDate,
    };
  }
  // Handle unknown value or default case
  console.error("Invalid value:", value);
  return null;
};

export { FilterDate, FilterDateThis, FilterDateReports };
