import React, { useState } from "react";
import Advertise from "../../assets/icon/SVG/category/Advertisement.svg";
import Assets from "../../assets/icon/SVG/category/Assets.svg";
import Car_And_Truck from "../../assets/icon/SVG/category/Car and Truck.svg";
import Comissions_and_Fees from "../../assets/icon/SVG/category/Commission & Fees.svg";
import Contract_Labor from "../../assets/icon/SVG/category/Contract Labor.svg";
import Home_Office_Expenses from "../../assets/icon/SVG/category/Home Office.svg";
import Insurance from "../../assets/icon/SVG/category/Insurance.svg";
import Interest_Paid from "../../assets/icon/SVG/category/Interest Paid.svg";
import Meals from "../../assets/icon/SVG/category/Meals.svg";
import Office_Expenses from "../../assets/icon/SVG/category/Office Expenses.svg";
import Other from "../../assets/icon/SVG/category/Other.svg";
import Professional_Services from "../../assets/icon/SVG/category/Professional Services.svg";
import Repairs from "../../assets/icon/SVG/category/Repairs.svg";
import Rent_and_Lease from "../../assets/icon/SVG/category/Rent and Lease.svg";
import Supplies from "../../assets/icon/SVG/category/Supplies.svg";
import Taxes_and_Licenses from "../../assets/icon/SVG/category/Taxes and Licenses.svg";
import Travel from "../../assets/icon/SVG/category/Travel.svg";
import Utilities from "../../assets/icon/SVG/category/Utilities.svg";

import card_repayment from "../../assets/icon/SVG/category/Car Repayment.svg";
import Charitable_Contribution from "../../assets/icon/SVG/category/Charitable Contributions.svg";
import Dividends_income from "../../assets/icon/SVG/category/Dividends_Income.svg";
import Entertainment from "../../assets/icon/SVG/category/Entertainment.svg";
import Home_material from "../../assets/icon/SVG/category/Home Material.svg";
import House_repair from "../../assets/icon/SVG/category/House Repair.svg";
import Income from "../../assets/icon/SVG/category/Income.svg";
import Interest_Income from "../../assets/icon/SVG/category/Interest Income.svg";
import Loan_Proceeds from "../../assets/icon/SVG/category/Loan Proceeds.svg";
import Loan_Repayment from "../../assets/icon/SVG/category/Loan Repayment.svg";
import Non_deductible from "../../assets/icon/SVG/category/Non Deductible.svg";
import Retirement_Income from "../../assets/icon/SVG/category/Retirement Income.svg";
import taxrefund from "../../assets/icon/SVG/category/Tax Refund.svg";
import Transfer_in from "../../assets/icon/SVG/category/Transfer In.svg";
import Transfer_out from "../../assets/icon/SVG/category/Transfer Out.svg";

function FilterRadio({ changeCategoryHandler, changeCatLoader, classes,selectedTranx }) {
  const [filter, setFilter] = useState("");
  const [seleted, setSelected] = useState("");

  const onChangeHandler = (e, naam) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (name == "find_ele") {
      value = value.toLowerCase();
      setFilter(value);
    } else if (name == "filter") {
      setSelected(naam);
    }
  };
  const data = [
    { id: 1, name: "Advertising", Icons: Advertise, type: "radio" },
    // { id: 2, name: "Assert", Icons: Assets, type: "radio" },
    { id: 3, name: "Car and Truck", Icons: Car_And_Truck, type: "radio" },
    { id: 4, name: "Card Repayment", Icons: card_repayment, type: "radio" },
    {
      id: 5,
      name: "Charitable Contributions",
      Icons: Charitable_Contribution,
      type: "radio",
    },
    {
      id: 6,
      name: "Commission & Fees",
      Icons: Comissions_and_Fees,
      type: "radio",
    },
    { id: 7, name: "Contract Labor", Icons: Contract_Labor, type: "radio" },
    { id: 8, name: "Dividends Income", Icons: Dividends_income, type: "radio" },
    { id: 9, name: "Entertainment", Icons: Entertainment, type: "radio" },
    { id: 10, name: "Home Material", Icons: Home_material, type: "radio" },
    { id: 11, name: "Home Office", Icons: Home_Office_Expenses, type: "radio" },
    { id: 12, name: "House Repair", Icons: House_repair, type: "radio" },
    { id: 13, name: "Insurance", Icons: Insurance, type: "radio" },
    { id: 14, name: "Income", Icons: Income, type: "radio" },
    { id: 15, name: "Interest Paid", Icons: Interest_Paid, type: "radio" },
    { id: 16, name: "Interest Income", Icons: Interest_Income, type: "radio" },
    { id: 17, name: "Loan Repayment", Icons: Loan_Repayment, type: "radio" },
    { id: 18, name: "Loan Proceeds", Icons: Loan_Proceeds, type: "radio" },
    { id: 19, name: "Meals", Icons: Meals, type: "radio" },
    { id: 20, name: "Non-Deductible", Icons: Non_deductible, type: "radio" },
    { id: 21, name: "Office Expenses", Icons: Office_Expenses, type: "radio" },
    { id: 22, name: "Other", Icons: Other, type: "radio" },
    {
      id: 23,
      name: "Professional Services",
      Icons: Professional_Services,
      type: "radio",
    },
    { id: 24, name: "Rent and Lease", Icons: Rent_and_Lease, type: "radio" },
    { id: 25, name: "Repairs", Icons: Repairs, type: "radio" },
    {
      id: 26,
      name: "Retirement Income",
      Icons: Retirement_Income,
      type: "radio",
    },
    { id: 27, name: "Supplies", Icons: Supplies, type: "radio" },
    {
      id: 28,
      name: "Taxes and Licenses",
      Icons: Taxes_and_Licenses,
      type: "radio",
    },
    { id: 29, name: "Tax Refunds", Icons: taxrefund, type: "radio" },
    { id: 30, name: "Travel", Icons: Travel, type: "radio" },
    { id: 31, name: "Transfer Out", Icons: Transfer_out, type: "radio" },
    { id: 32, name: "Transfer In", Icons: Transfer_in, type: "radio" },
    { id: 33, name: "Utilities", Icons: Utilities, type: "radio" },
  ];
  return (
    <div
      class="modal-dialog modal-sm modal-dialog-scrollable p-3 rounded-3"
      style={{ backgroundColor: "#FFFFFF", minWidth: "19rem" }}
    >
      {changeCatLoader && (
        <div className={[classes["cat-change-loader"]].join(" ")}>
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-1" id="exampleModalLabel">
              Search Category
            </h6>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  name="find_ele"
                  onChange={(e) => onChangeHandler(e, "")}
                />
              </div>
              <div
                style={{
                  maxHeight: "20rem",
                  minHeight: "10rem",
                  overflowY: "scroll",
                  width:"auto"
                }}
              >
                {data
                  .filter((fil) => fil.name.toLowerCase().includes(filter))
                  .map((ele) => {
                    return (
                      <div
                        key={ele.id}
                        className="col-12 d-flex align-items-center"
                        onClick={() => {
                          changeCategoryHandler(ele.name);
                        }}
                      >
                        <input
                          type={ele.type}
                          className="form-check-input"
                          id={`exampleCheck${ele.id}`} // Use a unique id for each input
                          name="filter"
                          checked = {ele.name == selectedTranx.client_category}
                        />
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: "55px", height: "45px" }}
                        >
                          <img
                            src={ele.Icons}
                            style={{ width: "30px", height: "30px" }}
                          />
                        </div>
                        <label
                          className="form-check-label ms-2"
                          htmlFor={`exampleCheck${ele.id}`} // Use htmlFor for label association
                        >
                          {ele.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterRadio;

{
  /* <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label" for="flexCheckDefault">
    Default checkbox
  </label>
</div> */
}
