import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { trackUserMP, trackUserMPSignup } from "services/mixpanel";

function Step1({
  formData,
  setFormData,
  activeSteps,
  prevHandler,
  setActivesteps,
}) {
  const [isError, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const Data = [
    "File my taxes",
    "Track my expenses",
    "Find new tax write-offs",
    "Predict my tax refund",
    "Get personalized tax advice",
  ];

  const Headings = [
    "Select below, what we can help you with.",
    "Select all that apply, you can update this later.",
  ];
  
  const onChangeHandler = (e, idx) => {
    const  name  = "what we can help you with";
    const value = Data[idx];
    if(formData.Questions[name]?.includes(value)){
      const msg = formData?.Questions[name]?.replace(`${value},`,"");
      setFormData((prev) => ({
        ...prev,
        Questions: {
          [name]: msg
        },
      }));
    }else{
      const prevMsg = formData.Questions[name]? formData.Questions[name] :""
      setFormData((prev) => ({
        ...prev,
        Questions: {
          [name]: prevMsg + value + "," ,
        },
      }));
    }
    console.log(formData)
  };

  const nextHandler = (event) => {

    trackUserMPSignup("Signup Step 100","Platform Use",formData.Questions["what we can help you with"])

    event.preventDefault();
    if (formData.Questions["what we can help you with"]) {
      console.log(formData);
      setActivesteps((prev) => prev + 1);
    
    }
    setError(true);
    setMsg("Please select atleast one.");
    //console.log("object : ",formData.Questions["what we can help you with"])
    //trackUserMPSignup("Signup Step 1","what we can help you with",formData.Questions["what we can help you with"])
  };
  const setShow = () => {
    setError(false);
  };

  return (
    <>
      <div class="container step-container">
        <div class="row justify-content-center">
          <form onSubmit={nextHandler}>
            <div className="form-container">
              <div class="text-center col-12 d-flex-col mb-3 ">
                <div className="fw-medium fs-6 heading">{Headings[0]}</div>
                <div className="sub-heading">{Headings[1]}</div>
              </div>
              {Data.map((ele, id) => {
                return (
                  <>
                    <div key={id} class={`col-12 mb-3 p-3 border rounded-1 ${isError &&"border-danger"}`}
                      onClick={(ele) => onChangeHandler(ele, id)}
                    >
                      <input
                        type="checkbox"
                        class="form-check-input "
                        id="flexCheckDefault"
                        name="what we can help you with"
                        //onChange={(ele) => onChangeHandler(ele, id)}
                        checked={formData.Questions["what we can help you with"]?.includes(ele)}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                        />
                      </svg>
                      <label class="form-check-label ms-2" for="exampleCheck1">
                        {ele}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="col-12 d-flex justify-content-between">
              <button
                type="submit"
                class="btn col-5 btn-outline-primary p-2 rounded-2"
                onClick={prevHandler}
                disabled={activeSteps==0}
              >
                Back
              </button>
              <button
                type="submit"
                class="btn col-5 p-2 btn-primary rounded-2 text-light"
                //onClick={nextHandler}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        onClose={() => setError(false)}
        autoHideDuration={6000}
        open={isError}
      >
        <Alert severity="error" sx={{ color: "red" }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Step1;
