import React from "react";
import "./Responsive_navItem.scss";

function Responsive_navItem({ page_name, start_icon, end_icon }) {
  return (
    <div className="navItem-container-res">
      <div className="navItem-subcontainer-res">
        <div className="navItem-res">
          <img className="sidebar-icons-res" src={start_icon} />
        </div>
        <div className="navItem-text-res">{page_name}</div>
      </div>
      <div className="navItem-sidebar-icons-res">
        <img className="sidebar-icons-res" src={end_icon} />
      </div>
    </div>
  );
}

export default Responsive_navItem;
