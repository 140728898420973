import React, { useMemo, useState } from "react";
import "./Header.scss";
import { FilterDate, FilterDateReports } from "Util/FilterDate";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, Tree } from "rsuite";
import { useAppContext } from "Store/AppContext";
import { MenuItem, TextField } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const dateConverter = (str) => {
  var date = new Date(str);
  var mnth = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  var year = date.getFullYear();
  return `${year}-${mnth}-${day}`;
};

const DateData = [
  {
    key: "All dates",
    value: FilterDate(730),
  },
  {
    key: "Custom",
    value: "custom",
  },
  {
    key: "This year",
    value: FilterDateReports("This year"),
  },
  {
    key: "2023",
    value: FilterDateReports("2023"),
  },
  {
    key: "2022",
    value: FilterDateReports("2022"),
  },
  {
    key: "Q1 24",
    value: FilterDateReports("Q1 24"),
  },
  {
    key: "Q2 23",
    value: FilterDateReports("Q2 23"),
  },
  {
    key: "Q3 23",
    value: FilterDateReports("Q3 23"),
  },
  {
    key: "Q4 23",
    value: FilterDateReports("Q4 23"),
  },
];

function HeaderReport({ heading, isHide }) {
  const [state, dispatch] = useAppContext();

  const name = useMemo(() => {
    const keyArr = [
      "All dates",
      "Custom",
      "This year",
      "2023",
      "2022",
      "Q2 23",
      "Q3 23",
      "Q4 23",
    ];
    return keyArr.includes(state.globalFilter.name)
      ? state.globalFilter.name
      : "Custom";
  }, []);
  const isSmallScreen = useMediaQuery({ maxWidth: 600 }); //
  const [isShow, setShow] = useState(true);
  const [date, setDate] = useState(name);

  const changeHandler = (e) => {
    const startDate = dateConverter(e[0]);
    const endDate = dateConverter(e[1]);
    dispatch({
      type: "SET_FILTER",
      payload: {
        endDate: endDate,
        startDate: startDate,
        name: "Custom",
      },
    });
    setShow(true);
  };

  const dateFormat = (str) => {
    const array = str.split("-");
    let date = array[2];
    let month = array[1];
    let year = array[0];

    return `${month}-${date}-${year}`;
  };

  const DatechangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDate(value);
    if (value == "Custom") {
      setShow(false);
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: {
          ...DateData.filter((ele) => ele.key == value)[0].value,
          name: value,
        },
      });
    }
  };

  return (
    <div className="header-container p-2 d-flex flex-row justify-content-between">
      {heading && (
        <div className="header-left">
          <h4 className="header-heading">{heading}</h4>
        </div>
      )}
      {!isHide && (
        <div className="header-right d-flex flex-column-reverse justify-content-center align-items-end flex-md-row  gap-2">
          <div
            className=" p-1 d-flex justify-content-center align-items-center w-100 h-100"
            style={{ padding: "18px", height: "10rem" }}
          >
            <h6 className="m-0 p-0 ps-2 ">{`${dateFormat(
              state.globalFilter.startDate
            )} to ${dateFormat(state.globalFilter.endDate)}`}</h6>
          </div>
          <div>
            <div
              className="navbar-date-picker d-sm-none"
              style={{ fontFamily: "system-ui" }}
            >
              {isShow ? (
                <TextField
                  size="small"
                  sx={{
                    width: "10rem",
                    backgroundColor: "white",
                    textAlign: "center",
                  }}
                  value={date}
                  name="Date"
                  onChange={DatechangeHandler}
                  select
                  className="date-box-textfield"
                >
                  {DateData.map((ele, idx) => {
                    return (
                      <MenuItem value={ele.key} checked id={idx} key={idx}>
                        {ele.key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                <DateRangePicker
                  onChange={changeHandler}
                  editable={true}
                  showOneCalendar
                  value={[
                    new Date(state.globalFilter.startDate),
                    new Date(state.globalFilter.endDate),
                  ]}
                  character="  To  "
                  format="yyyy-MM-dd"
                  cleanable={false}
                  placement={"bottomEnd"}
                  open
                  onOk={() => setShow(true)}
                />
              )}
            </div>
            <div className="navbar-date-picker-desk d-none d-sm-block">
              {isShow ? (
                <TextField
                  size="small"
                  sx={{
                    width: "10rem",
                    backgroundColor: "white",
                    textAlign: "center",
                  }}
                  value={date}
                  name="Date"
                  onChange={DatechangeHandler}
                  select
                  className="date-box-textfield"
                >
                  {DateData.map((ele, idx) => {
                    return (
                      <MenuItem value={ele.key} checked id={idx} key={idx}>
                        {ele.key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                <DateRangePicker
                  onChange={changeHandler}
                  editable={true}
                  value={[
                    new Date(state.globalFilter.startDate),
                    new Date(state.globalFilter.endDate),
                  ]}
                  character="  To  "
                  format="yyyy-MM-dd"
                  cleanable={false}
                  placement={"bottomEnd"}
                  open={!isSmallScreen}
                  onOk={() => setShow(true)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderReport;
