import React, { useState } from "react";
import expenseBlueBtn from "../../assets/icon/PNG/expense-blue.png";
import notExpenseBlueBtn from "../../assets/icon/PNG/Not-a-Expense-blue.png";
import notSureBlue from "../../assets/icon/SVG/Not-Sure.svg";
import Personal from "../../assets/icon/SVG/Personal.svg";
import notExpenseBlue from "../../assets/icon/SVG/Not-a-Expense.svg";
import expenseBlue from "../../assets/icon/SVG/expense.svg";
import FilterRadio from "../FilterCard/FilterRadio";
import Service from "services/services";
import nondeductibleIcon from "../../assets/icon/SVG/nondedutct-blue.svg";
import income from "../../assets/icon/SVG/income-blue.svg";

import moment from "moment";
import { categMap } from "../../data/data";
import { FormatNumber } from "Util/FormatName";

function ExpenseMainCard({
  classes,
  each,
  expenseHandler,
  type,
  refrence,
  markingProgress,
  isBulkMarking,
  expenseBulkingHandler,
  expenseBulkingIds,
  markIncome,
  getExpencesCategoryChange
}) {
  let isMarking = false;

  if (markingProgress.id == each.transaction_id) {
    isMarking = true;
  }

  const [selectCatModal, setSelectCatModal] = useState(false);
  const [selectedTranx, setSelectedTranx] = useState();

  const [changeCatLoader, setChangeCatLoader] = useState(false);

  const changeCategoryHandler = (clientCategory) => {
    console.log("calling on click change category")
    setChangeCatLoader(true);
    const servies = new Service();
    const selectedTrans_ID = selectedTranx.transaction_id;
    const payload = {
      clientCategory,
      tranxIds: [selectedTranx.transaction_id],
      sourceCategory: type,
    };

    servies
      .markCategory(payload)
      .then((res) => {
        setChangeCatLoader(false);
        setSelectCatModal(false);
        if (type == "NON_DEDUCTIVES") {
          expenseHandler(
            "NEEDS_REVIEW",
            { ...selectedTranx, client_category: clientCategory },
            false,
            false
          );
        } else {
          console.log("calling on click change category else case")
          getExpencesCategoryChange(selectedTrans_ID);
        }
      })
      .catch((err) => {
        setChangeCatLoader(false);
      });
  };

  


  let actionsJsx = (
    <div className="col-md-12 d-flex justify-content-between">
      <div
        className={[
          "border",
          "d-flex",
          "justify-content-center",
          "rounded-1",
          classes["action-btn"],
        ].join(" ")}
        onClick={expenseHandler.bind(this, "EXPENSE", each, false, false)}
      >
        <button
          disabled={type == "EXPENSE" || isMarking}
          className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
        >
          {isMarking && markingProgress.type == "EXPENSE" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <img
                src={expenseBlue}
                style={{ marginRight: "10px" }}
                alt="Mark"
              ></img>
              Expense
            </div>
          )}
        </button>
      </div>
      <div
        className={[
          "border",
          "d-flex",
          "justify-content-center",
          "rounded-1",
          classes["action-btn"],
        ].join(" ")}
        onClick={expenseHandler.bind(this, "NOT_EXPENSE", each, false, false)}
      >
        <button
          disabled={type == "NOT_EXPENSE" || isMarking}
          className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
        >
          {isMarking && markingProgress.type == "NOT_EXPENSE" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <img
                src={notExpenseBlue}
                style={{ marginRight: "10px" }}
                alt="Mark"
              ></img>
              Not an Expense
            </div>
          )}
        </button>
      </div>
      <div
        className={[
          "border",
          "d-flex",
          "justify-content-center",
          "rounded-1",
          classes["action-btn"],
        ].join(" ")}
        onClick={expenseHandler.bind(this, "NOT_SURE", each, false, false)}
      >
        <button
          disabled={type == "NOT_SURE" || isMarking}
          className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
        >
          {isMarking && markingProgress.type == "NOT_SURE" ? (
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <img
                src={notSureBlue}
                style={{ marginRight: "10px" }}
                alt="Mark"
              ></img>
              Not Sure
            </div>
          )}
        </button>
      </div>
    </div>
  );
  const incomeTypes = [
    "Dividends Income",
    "Income",
    "Interest Income",
    "Retirement Income",
  ];
  if (incomeTypes.includes(each.client_category)) {
    actionsJsx = (
      <div className="col-md-12 d-flex  justify-content-between">
        <div
          className={[
            "border",
            "d-flex",
            "justify-content-center",
            "rounded-1",
            classes["action-btn"],
          ].join(" ")}
          onClick={
            type == "INCOMES"
              ? markIncome.bind(this, "INCOMES", each, false, "PERSONAL")
              : expenseHandler.bind(this, "INCOMES", each, false, "PERSONAL")
          }
        >
          <button
            disabled={each.type == "PERSONAL" || isMarking}
            className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
          >
            {isMarking && markingProgress.incomeType == "PERSONAL" ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={Personal}
                  style={{ marginRight: "10px" }}
                  alt="Mark"
                ></img>
                Personal
              </div>
            )}
          </button>
        </div>
        <div
          className={[
            "border",
            "d-flex",
            "justify-content-center",
            "rounded-1",
            classes["action-btn"],
          ].join(" ")}
          onClick={
            type == "INCOMES"
              ? markIncome.bind(this, "INCOMES", each, false, "BUSINESS")
              : expenseHandler.bind(this, "INCOMES", each, false, "BUSINESS")
          }
        >
          <button
            disabled={each.type == "BUSINESS" || isMarking}
            className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
          >
            {isMarking && markingProgress.incomeType == "BUSINESS" ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={income}
                  style={{ marginRight: "10px" }}
                  alt="Mark"
                ></img>
                Business
              </div>
            )}
          </button>
        </div>
        <div
          className={[
            "border",
            "d-flex",
            "justify-content-center",
            "rounded-1",
            classes["action-btn"],
          ].join(" ")}
          onClick={expenseHandler.bind(this, "NOT_SURE", each, false, false)}
        >
          <button
            disabled={type == "NOT_SURE" || isMarking}
            className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
          >
            {isMarking && markingProgress.type == "NOT_SURE" ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={notSureBlue}
                  style={{ marginRight: "10px" }}
                  alt="Mark"
                ></img>
                Not Sure
              </div>
            )}
          </button>
        </div>
      </div>
    );
  }

  if (each.client_category == "Non-Deductible") {
    actionsJsx = (
      <div className="col-md-12 d-flex justify-content-between">
        <div
          className={[
            "border",
            "d-flex",
            "justify-content-center",
            "rounded-1",
            classes["action-btn"],
          ].join(" ")}
          onClick={expenseHandler.bind(
            this,
            "NON_DEDUCTIVES",
            each,
            false,
            false
          )}
        >
          <button
            disabled={type == "NON_DEDUCTIVES" || isMarking}
            className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
          >
            {isMarking && markingProgress.type == "NON_DEDUCTIVES" ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={nondeductibleIcon}
                  style={{ marginRight: "10px" }}
                  alt="Mark"
                ></img>
                Non Deductible
              </div>
            )}
          </button>
        </div>
        <div
          className={[
            "border",
            "d-flex",
            "justify-content-center",
            "rounded-1",
            classes["action-btn"],
          ].join(" ")}
          // onClick={
          //   type == "INCOMES"
          //     ? markIncome.bind(this, "INCOMES", each, false, "BUSINESS")
          //     : expenseHandler.bind(this, "INCOMES", each, false, "BUSINESS")
          // }

          onClick={() => {
            setSelectedTranx(each);
            setSelectCatModal(true);
          }}
        >
          <div
            disabled={each.type == "BUSINESS" || isMarking}
            className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
          >
            {isMarking && markingProgress.incomeType == "BUSINESS" ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={expenseBlue}
                  style={{ marginRight: "10px" }}
                  alt="Mark"
                ></img>
                Deductible
              </div>
            )}
          </div>
        </div>
        <div
          className={[
            "border",
            "d-flex",
            "justify-content-center",
            "rounded-1",
            classes["action-btn"],
          ].join(" ")}
          onClick={expenseHandler.bind(this, "NOT_SURE", each, false, false)}
        >
          <button
            disabled={type == "NOT_SURE" || isMarking}
            className="btn border-0 fw-bold py-2 text-primary d-flex align-items-center"
          >
            {isMarking && markingProgress.type == "NOT_SURE" ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  src={notSureBlue}
                  style={{ marginRight: "10px" }}
                  alt="Mark"
                ></img>
                Not Sure
              </div>
            )}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={refrence}
      className={["card", "p-3", classes["expence-card"]].join(" ")}
    >
      {selectCatModal && (
        <div>
          <div
            onClick={() => {
              setSelectCatModal(false);
            }}
            className={[classes["back-drop"]].join(" ")}
          ></div>
          <div className={[classes["modal"]].join(" ")}>
            <FilterRadio
              changeCategoryHandler={changeCategoryHandler}
              changeCatLoader={changeCatLoader}
              classes={classes}
              selectedTranx={selectedTranx}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-7">
          <div className="align-items-center d-flex gap-3">
            <div>
              {![...incomeTypes, "Non-Deductible"].includes(
                each.client_category
              ) && (
                <input
                  className="border-2 border-secondary-subtle form-check-input fs-5"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={expenseBulkingIds.find(
                    (item) => item.transaction_id === each.transaction_id
                  )}
                  onClick={expenseBulkingHandler.bind(this, each)}
                />
              )}
            </div>
            <div className="">
              <p className="font-weight-bold fs-3 fw-bold mb-0 text-dark">
                ${FormatNumber(each.amount || 0)}
              </p>
              <p className="fs-6 text-secondary">
                {" "}
                {each.merchant_name} | {moment(each.date).format("MM-DD-YYYY")}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className={[classes["category-container"]].join(" ")}>
            <div
              className={classes["type"]}
              style={{
                backgroundColor:
                  categMap[each.client_category]?.backgroundColor,
              }}
            >
              <img src={categMap[each.client_category]?.icons} alt="" />
              <p
                className={[
                  classes["truncate-text"],
                  "font-weight-bold",
                  "mb-0",
                ].join(" ")}
                style={{ color: categMap[each.client_category]?.color }}
              >
                {each.client_category}
              </p>
            </div>
            <p
              className={[
                classes["category-text"],
                "font-weight-bold",
                " text-decoration-underline",
                "fs-6",
                "mb-0",
              ].join(" ")}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedTranx(each);
                setSelectCatModal(true);
              }}
            >
              Change Category 
            </p>
          </div>
        </div>
      </div>

      <hr></hr>
      <div className="row justify-content-center">
        {isBulkMarking && (
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {!isBulkMarking && actionsJsx}
      </div>
    </div>
  );
}

export default ExpenseMainCard;
