import icons from '../assets/icon/SVG/category/index'

const categMap = {
    "Advertising": {
      color: "#006400",
      icons: icons['Advertising'],
      backgroundColor: "#E6FFE6",
    },
    "Assets": {
      color: "#0000FF",
      icons: icons['Assets'],
      backgroundColor: "#E6E6FF",
    },
    "Car and Truck": {
      color: "#000000",
      icons: icons['Car and Truck'],
      backgroundColor: "#F5F5F5",
    },
    "Card Repayment": {
      color: "#E6FFE6",
      icons: icons['Card Repayment'],
      backgroundColor: "#10B981",
    },
    "Charitable Contributions": {
      color: "#C37024",
      icons: icons['Charitable Contributions'],
      backgroundColor: "#FFD9B6",
    },
  
    "Commission & Fees": {
      color: "#8B4513",
      icons: icons['Commission & Fees'],
      backgroundColor: "#FFE3BD",
    },
    "Contract Labor": {
      color: "#BA55D3",
      icons: icons['Contract Labor'],
      backgroundColor: "#F3E4F7",
    },
    "Dividends Income": {
      color: "#334F4D",
      icons: icons['Dividends Income'],
      backgroundColor: "#87D9D4",
    },
    "Entertainment": {
      color: "#5057FD",
      icons: icons['Entertainment'],
      backgroundColor: "#CBD9F2",
    },
    "Home Office": {
      color: "#FFA500",
      icons: icons['Home Office'],
      backgroundColor: "#FFF2CC",
    },
    "Home Material": {
      color: "#8B4513",
      icons: icons['Home Material'],
      backgroundColor: "#FFF2CC",
    },
    "House Repair": {
      color: "#404040",
      icons: icons['Home Material'],
      backgroundColor: "#A4C8FD",
    },
    "Insurance": {
      color: "#800020",
      icons: icons['Insurance'],
      backgroundColor: "#FFC2C6",
    },
    "Income": {
      color: "#BDE9D1",
      icons: icons['Income'],
      backgroundColor: "#38644C",
    },
    "Interest Paid": {
      color: "#E0BD00",
      icons: icons['Interest Paid'],
      backgroundColor: "#FFFAE6",
    },
    "Interest Income": {
      color: "#37BAB2",
      icons: icons['Interest Income'],
      backgroundColor: "#DBFFFD",
    },
    "Loan Repayment": {
      color: "#FFEDDF",
      icons: icons['Loan Repayment'],
      backgroundColor: "#DDAA85",
    },
    "Loan Proceeds": {
      color: "#008080",
      icons: icons['Loan Proceeds'],
      backgroundColor: "#F1F1F1",
    },
    "Meals": {
      color: "#008080",
      icons: icons['Meals'],
      backgroundColor: "#E6FFFF",
    },
    "Non-Deductible": {
      color: "#800020",
      icons: icons['Non Deductible'],
      backgroundColor: "#FFC2C6",
    },
    "Other": {
      color: "#AB7CAB",
      icons: icons['Other'],
      backgroundColor: "#F8E4F8",
    },
    "Office Expenses": {
      color: "#FF0000",
      icons: icons['Office Expenses'],
      backgroundColor: "#FFCCCC",
    },
    "Professional Services": {
      color: "#C4949C",
      icons: icons['Professional Services'],
      backgroundColor: "#FFE6F3",
    },
    "Rent and Lease": {
      color: "#00008B",
      icons: icons['Rent and Lease'],
      backgroundColor: "#E6E6FF",
    },
    "Repairs": {
      color: "#B2B200",
      icons: icons['Repairs'],
      backgroundColor: "#FFFFE6",
    },
    "Retirement Income": {
      color: "#816A9E",
      icons: icons['Retirement Income'],
      backgroundColor: "#E8C4FE",
    },
    "Supplies": {
      color: "#004080",
      icons: icons['Supplies'],
      backgroundColor: "#BFDFF7",
    },
    "Taxes and Licenses": {
      color: "#888894",
      icons: icons['Taxes and Licenses'],
      backgroundColor: "#F2F2FF",
    },
    "Tax Refunds": {
      color: "#800020",
      icons: icons['Tax Refunds'],
      backgroundColor: "#FFC2C6",
    },
    "Travel": {
      color: "#556B2F",
      icons: icons['Travel'],
      backgroundColor: "#D2D8B3",
    },
    "Transfer In": {
      color: "#AB7CAB",
      icons: icons['Transfer In'],
      backgroundColor: "#F8E4F8",
    },
    "Transfer Out": {
      color: "#8DADFF",
      icons: icons['Transfer Out'],
      backgroundColor: "#6B6D95",
    },
    "Utilities": {
      color: "#5D995D",
      icons: icons['Utilities'],
      backgroundColor: "#E6F9E6",
    },
  };


export {categMap};