import React, { useState } from "react";
import Advertise from "../../assets/icon/SVG/category/Advertisement.svg";
import Assets from "../../assets/icon/SVG/category/Assets.svg";
import Car_And_Truck from "../../assets/icon/SVG/category/Car and Truck.svg";
import Comissions_and_Fees from "../../assets/icon/SVG/category/Commission & Fees.svg";
import Contract_Labor from "../../assets/icon/SVG/category/Contract Labor.svg";
import Home_Office_Expenses from "../../assets/icon/SVG/category/Home Office.svg";
import Insurance from "../../assets/icon/SVG/category/Insurance.svg";
import Interest_Paid from "../../assets/icon/SVG/category/Interest Paid.svg";
import Meals from "../../assets/icon/SVG/category/Meals.svg";
import Office_Expenses from "../../assets/icon/SVG/category/Office Expenses.svg";
import Other from "../../assets/icon/SVG/category/Other.svg";
import Professional_Services from "../../assets/icon/SVG/category/Professional Services.svg";
import Repairs from "../../assets/icon/SVG/category/Repairs.svg";
import Rent_and_Lease from "../../assets/icon/SVG/category/Rent and Lease.svg";
import Supplies from "../../assets/icon/SVG/category/Supplies.svg";
import Taxes_and_Licenses from "../../assets/icon/SVG/category/Taxes and Licenses.svg";
import Travel from "../../assets/icon/SVG/category/Travel.svg";
import Utilities from "../../assets/icon/SVG/category/Utilities.svg";
import card_repayment from "../../assets/icon/SVG/category/Car Repayment.svg";
import Charitable_Contribution from "../../assets/icon/SVG/category/Charitable Contributions.svg";
import Dividends_income from "../../assets/icon/SVG/category/Dividends_Income.svg";
import Entertainment from "../../assets/icon/SVG/category/Entertainment.svg";
import Home_material from "../../assets/icon/SVG/category/Home Material.svg";
import House_repair from "../../assets/icon/SVG/category/House Repair.svg";
import Income from "../../assets/icon/SVG/category/Income.svg";
import Interest_Income from "../../assets/icon/SVG/category/Interest Income.svg";
import Loan_Proceeds from "../../assets/icon/SVG/category/Loan Proceeds.svg";
import Loan_Repayment from "../../assets/icon/SVG/category/Loan Repayment.svg";
import Non_deductible from "../../assets/icon/SVG/category/Non Deductible.svg";
import Retirement_Income from "../../assets/icon/SVG/category/Retirement Income.svg";
import taxrefund from "../../assets/icon/SVG/category/Tax Refund.svg";
import Transfer_in from "../../assets/icon/SVG/category/Transfer In.svg";
import Transfer_out from "../../assets/icon/SVG/category/Transfer Out.svg";

const data = [
  { id: 1, name: "Advertising", Icons: Advertise, type: "checkbox" },
  // { id: 2, name: "Assert", Icons: Assets, type: "checkbox" },
  { id: 3, name: "Car and Truck", Icons: Car_And_Truck, type: "checkbox" },
  { id: 4, name: "Card Repayment", Icons: card_repayment, type: "checkbox" },
  {
    id: 5,
    name: "Charitable Contributions",
    Icons: Charitable_Contribution,
    type: "checkbox",
  },
  {
    id: 6,
    name: "Commission & Fees",
    Icons: Comissions_and_Fees,
    type: "checkbox",
  },
  { id: 7, name: "Contract Labor", Icons: Contract_Labor, type: "checkbox" },
  {
    id: 8,
    name: "Dividends Income",
    Icons: Dividends_income,
    type: "checkbox",
  },
  { id: 9, name: "Entertainment", Icons: Entertainment, type: "checkbox" },
  { id: 10, name: "Home Material", Icons: Home_material, type: "checkbox" },
  {
    id: 11,
    name: "Home Office",
    Icons: Home_Office_Expenses,
    type: "checkbox",
  },
  { id: 12, name: "House Repair", Icons: House_repair, type: "checkbox" },
  { id: 13, name: "Insurance", Icons: Insurance, type: "checkbox" },
  { id: 14, name: "Income", Icons: Income, type: "checkbox" },
  { id: 15, name: "Interest Paid", Icons: Interest_Paid, type: "checkbox" },
  { id: 16, name: "Interest Income", Icons: Interest_Income, type: "checkbox" },
  { id: 17, name: "Loan Repayment", Icons: Loan_Repayment, type: "checkbox" },
  { id: 18, name: "Loan Proceeds", Icons: Loan_Proceeds, type: "checkbox" },
  { id: 19, name: "Meals", Icons: Meals, type: "checkbox" },
  { id: 20, name: "Non-Deductible", Icons: Non_deductible, type: "checkbox" },
  { id: 21, name: "Office Expenses", Icons: Office_Expenses, type: "checkbox" },
  { id: 22, name: "Other", Icons: Other, type: "checkbox" },
  {
    id: 23,
    name: "Professional Services",
    Icons: Professional_Services,
    type: "checkbox",
  },
  { id: 24, name: "Rent and Lease", Icons: Rent_and_Lease, type: "checkbox" },
  { id: 25, name: "Repairs", Icons: Repairs, type: "checkbox" },
  {
    id: 26,
    name: "Retirement Income",
    Icons: Retirement_Income,
    type: "checkbox",
  },
  { id: 27, name: "Supplies", Icons: Supplies, type: "checkbox" },
  {
    id: 28,
    name: "Taxes and Licenses",
    Icons: Taxes_and_Licenses,
    type: "checkbox",
  },
  { id: 29, name: "Tax Refunds", Icons: taxrefund, type: "checkbox" },
  { id: 30, name: "Travel", Icons: Travel, type: "checkbox" },
  { id: 31, name: "Transfer Out", Icons: Transfer_out, type: "checkbox" },
  { id: 32, name: "Transfer In", Icons: Transfer_in, type: "checkbox" },
  { id: 33, name: "Utilities", Icons: Utilities, type: "checkbox" },
];

function FilterCheckbox({
  setCategoryFilters,
  categoryFilters,
  selectedAll = false,
}) {
  const [filter, setFilter] = useState("");
  const [seleted, setSelected] = useState("");
  const onChangeHandler = (e, naam) => {
    let { name, value } = e.target;
    if (name == "find_ele") {
      value = value.toLowerCase();
      setFilter(value);
    } else if (name == "filter") {
      setSelected(naam);
    }
  };
  const setAllcategoryHandler = (e) => {
    setCategoryFilters(null);
  };

  const setFilterElementHandler = (e, name) => {
    e.preventDefault();
    setCategoryFilters(name);
  };

  return (
    <div
      class="modal-dialog modal-sm modal-dialog-scrollable p-3 border m-2 rounded-3"
      style={{ backgroundColor: "#FFFFFF", minWidth: "18rem" }}
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-1" id="exampleModalLabel">
              Search Category
            </h6>
          </div>
          <div class="modal-body ">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  name="find_ele"
                  onChange={(e) => onChangeHandler(e, "")}
                />
              </div>
              <div className="px-1" onClick={setAllcategoryHandler}>
                <input
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  name="filter"
                  checked={selectedAll}
                />
                <span>Select All</span>
              </div>
              <div
                className="px-1"
                style={{
                  maxHeight: "20rem",
                  minHeight: "10rem",
                  overflowY: "scroll",
                }}
              >
                {data
                  .filter((fil) => fil.name.toLowerCase().includes(filter))
                  .map((ele) => {
                    return (
                      <div
                        key={ele.id}
                        class="col-12 d-flex align-items-center"
                        onClick={(e) => {
                          setFilterElementHandler(e, ele.name);
                        }}
                      >
                        <input
                          type={ele.type}
                          class="form-check-input"
                          id="exampleCheck1"
                          name="filter"
                          checked={categoryFilters.includes(ele.name)}
                        />
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: "55px", height: "45px" }}
                        >
                          <img
                            src={ele.Icons}
                            style={{ width: "30px", height: "30px" }}
                          />
                        </div>
                        <label
                          class="form-check-label ms-2"
                          for="exampleCheck1"
                        >
                          {ele.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterCheckbox;

{
  /* <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label" for="flexCheckDefault">
    Default checkbox
  </label>
</div> */
}
