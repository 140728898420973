import React, { useEffect, useState } from "react";
import "./Home.scss";
import Tiles from "@/components/Tiles/Tiles";
import DonutChart from "./Donut/DonutChart";
import ExpenseCard from "../../components/ExpenseCard/ExpenseCard";
import Bank from "../../assets/icon/SVG/card/Bank.svg";
import Master_Card from "../../Icons/cards/master card.png";
import Visa_Inc from "../../Icons/cards/Visa Inc.png";
import Chart from "@/components/Chart/LineChart";
import Service from "services/services";
import Spinners from "@/components/Loader/Spinner";
import { useAppContext } from "Store/AppContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FilterRadio from "@/components/FilterCard/FilterRadio";
import Loading from "@/components/Loader/Loading";
import { FormatNumber } from "Util/FormatName";
import { Link } from "react-router-dom";
import { Key } from "@mui/icons-material";
import { trackUserMP } from "services/mixpanel";

function Overview() {
  const [Cards, setCards] = useState([
    { icons: Bank, name: "AMEX ...0928", value: "$8788.17" },
    { icons: Bank, name: "VISA ...0928", value: "$3784.11" },
    { icons: Bank, name: "MC ...0928", value: "$174.11" },
  ]);

  const [dashboardData, setDashboardData] = useState(null);
  const [expensebyDay, setExpensebyDay] = useState(null);
  const [expensebyCat, setExpensebyCat] = useState(null);
  const [expensebyAcc, setExpensebyAcc] = useState(null);
  const [state, dispatch] = useAppContext();
  const [loading, setLoader] = useState(true);

  const service = new Service();

  const getData = async () => {
    setLoader(true);
    const payload = {
      startDate: state.globalFilter.startDate,
      endDate: state.globalFilter.endDate,
    };

    const getDashboardAnalytics = service.getDashboardAnalytics(payload);
    const getExpensesbyday = service.getExpensesbyday(payload);
    const getExpensesbyCategory = service.getExpensesbyCategory(payload);
    const getExpensesbyAccount = service.getExpensesbyAccount(payload);
    const getLinkedAccounts = service.getLinkedAccounts(payload);

    const [
      dashboardDataRes,
      expensebyDayRes,
      expencebyCatRes,
      expencebyAccRes,
      getLinkedAccRes,
    ] = await Promise.all([
      getDashboardAnalytics,
      getExpensesbyday,
      getExpensesbyCategory,
      getExpensesbyAccount,
      getLinkedAccounts,
    ]);

    setDashboardData(dashboardDataRes.data.result);
    setExpensebyDay(expensebyDayRes.data.result);
    setExpensebyAcc(expencebyAccRes.data.result);
    setExpensebyCat(expencebyCatRes.data.result);
    const cardList = [];
    const accounts = (getLinkedAccRes?.data?.accounts || []).map((ele) => {
      ele = ele?.bankAccount;
      const card = expencebyAccRes.data.result.filter(
        (e) => e.account_id == ele.account_id
      )[0];
      cardList.push({
        ...card,
        ...ele,
        name: `${ele.name.split(" ")[0]}...${ele.mask}`,
        icons: Bank,
      });
    });

    setCards(cardList);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [state.globalFilter]);

  useEffect(()=>{
    trackUserMP("Overview Page")
  },[])

  return (
    <div className="home-container pe-md-2 ps-md-2">
      <div className="row mb-1">
        <div className="col-sm-6 col-md-6 mt-1 col">
          <Tiles
            loading={loading}
            name="Biggest Expense"
            value={`$${FormatNumber(dashboardData?.biggestExpense || 0)}`}
          />
        </div>
        <div className="col-sm-6 col-md-6 mt-1 col ">
          <Link to={"/transaction"} className="text-decoration-none">
            <Tiles
              loading={loading}
              name="Expenses to Review"
              value={dashboardData?.needsReviewCount}
            />
          </Link>
        </div>
      </div>
      <div className="graph-container">
        <h5 className="card-title mb-2">Expenses by Day</h5>
        <Chart loader={loading} data={expensebyDay} />
      </div>
      <div className="chart-container">
        <div className="chart-container-left">
          <DonutChart laoding={loading} data={expensebyCat} />
        </div>
        <div className="chart-container-right">
          <div className="chart-container-right-head">
            <p className="chart-container-right-heading">
              Expense Totals by Account
            </p>
          </div>
          <div className="chart-container-right-card-list">
            {!Cards ? (
              <div className="donut-expense-item">
                <div className="left-item">
                  <div className="left-item-icon">
                    <Skeleton circle={true} height={40} width={40} />
                  </div>
                  <div className="left-item-text">
                    <Skeleton width={100} />
                  </div>
                </div>
                {/* <div className="right-item">
                <div className="right-item-text">
                  <Skeleton width={50} />
                </div>
              </div> */}
              </div>
            ) : (
              Cards?.map((ele, idx) => {
                return (
                  <ExpenseCard
                    loading={loading}
                    icons={ele.icons}
                    name={ele.name}
                    value={"$" + FormatNumber(ele.totalAmount || "0")}
                    key={idx}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
