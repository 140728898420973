import React, { useState } from "react";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Loading from "@/components/Loader/Loading";
import { trackUserMPSignup } from "services/mixpanel";
function Step5({
  formData,
  activeStep,
  setActivesteps,
  prevHandler,
  setFormData,
}) {
  const [isError, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isShowPS, setShowPS] = useState(false);
  const [isShowPSC, setShowPSC] = useState(false);
  const [isfailure, setisFailure] = useState(false);
  const [validationError, setValidationErrors] = useState({});
  const [passValid, setValidPass] = useState({
    length: false,
    Uchar: false,
    Nchar: false,
  });

  const submitHandler = (event) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    event.preventDefault();
    if (!formData.password || !formData.email || !formData.name) {
      for (const [key, value] of Object.entries(formData)) {
        if (!formData[key]) {
          setValidationErrors((prev) => ({
            ...prev,
            [key]: true,
          }));
        }
      }
      setMsg("Please enter all field");
      setisFailure(true);
      return;
    }
    if (!passValid.length || !passValid.Nchar || !passValid.Uchar) {
      setValidationErrors((prev) => ({
        ...prev,
        password: true,
      }));

      return;
    }

    if (!emailPattern.test(formData.email)) {
      setValidationErrors({
        ...validationError,
        email: "Please enter a valid email address.",
      });
      return;
    }
    if (formData.password !== formData.comfirmPassword) {
      setValidationErrors((prev) => ({
        ...prev,
        comfirmPassword: true,
      }));
      return;
    }

    setLoading(true);
    trackUserMPSignup("Signup Step 300", "Name", formData.name);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/email/register`,
        {
          email: formData.email,
        }
      )
      .then((res) => {
        const { data } = res;
        if (!data.success) {
          setMsg(data.message);
          setValidationErrors((prev) => ({
            ...prev,
            ...data.error,
          }));
          setLoading(false);
        //  trackUserMPSignup("Signup Step 3", "Name", formData.name);
          console.log("step 2", formData.name);
          return;
        }

        // Add the user to the welcome contacts list
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/contact`,
            {
              contacts: [
                {
                  email: formData.email,
                  first_name: formData.name,
                  custom_fields: {
                    taxfluence_use:
                      formData["Questions"]["what we can help you with"],
                    account_type: formData["accountType"],
                    profession:
                      formData["Questions"][
                        "What profession did you earn income with?"
                      ],
                    Bank_account_added: "false",
                  },
                },
              ],
              list_id: "e8af9bb5-b276-47f1-8923-dcf48b29ffbf"
            },
            {
              headers: {
                "Content-Type": "application/json"
              },
            }
          )
          .catch((error) => {
            setisFailure(true);
            setMsg(error.message);
            setLoading(false);
            return;
          });

        setTimeout(() => {
          setActivesteps((prev) => prev + 1);
        }, 1000);
      })
      .catch((error) => {
        setisFailure(true);
        setMsg(error.message);
        setLoading(false);
        return;
      });
  };

  const Headings = [
    "Let’s create your account",
    "Set up your profile to start saving. As per our Terms of Service, we respect your privacy, and we don’t spam.",
  ];

  const onChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setValidationErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name == "password") {
      if (/[A-Z]/.test(value)) {
        setValidPass((prev) => ({
          ...prev,
          ["Uchar"]: true,
        }));
      } else {
        setValidPass((prev) => ({
          ...prev,
          ["Uchar"]: false,
        }));
      }
      if (/[0-9]/.test(value)) {
        setValidPass((prev) => ({
          ...prev,
          ["Nchar"]: true,
        }));
      } else {
        setValidPass((prev) => ({
          ...prev,
          ["Nchar"]: false,
        }));
      }
      if (value.length > 7) {
        setValidPass((prev) => ({
          ...prev,
          ["length"]: true,
        }));
      } else {
        setValidPass((prev) => ({
          ...prev,
          ["length"]: false,
        }));
      }
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div class="container step-container">
      <div class="row justify-content-center">
        <form onSubmit={submitHandler}>
          <div className="form-container">
            <div class="text-center col-12 d-flex-col  mb-3 ">
              <div className="fw-medium fs-6">{Headings[0]}</div>
              <div className="sub-heading">{Headings[1]}</div>
            </div>
            <div class="col-12 mb-2">
              <div class="mb-2">
                <label for="Full Name" class="form-label">
                  Full Name
                </label>
                <input
                  type="name"
                  name="name"
                  id="name"
                  value={formData.name}
                  validationError={"name"}
                  aria-describedby="name"
                  required={true}
                  class={`form-control rounded-1 ${
                    validationError.name && "is-invalid"
                  }`}
                  onChange={onChangeHandler}
                />
                <div
                  id="validationServerUsernameFeedback"
                  class="invalid-feedback"
                >
                  {validationError.name}
                </div>
              </div>
              <div class="mb-2">
                <label for="exampleInputEmail1" class="form-label">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  class={`form-control rounded-1 ${
                    validationError.email && "is-invalid"
                  }`}
                  id="exampleInputEmail1"
                  validationError={"email"}
                  onChange={onChangeHandler}
                  // pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  aria-describedby="emailHelp"
                  required=""
                />
                <div
                  id="validationServerUsernameFeedback"
                  class="invalid-feedback"
                >
                  {validationError.email}
                </div>
              </div>
              <div class="mb-2 position-relative">
                <label for="exampleInputPassword1" class="form-label">
                  Password
                </label>
                <input
                  type={isShowPS ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  class={`form-control rounded-1  ${
                    validationError.password && "is-invalid"
                  }`}
                  validationError={"password"}
                  onChange={onChangeHandler}
                  id="exampleInputPassword1"
                  required=""
                />
                <i
                  class={`passwordEye bi ${
                    isShowPS ? "bi-eye-slash-fill" : "bi-eye-fill"
                  }`}
                  onClick={() => setShowPS(!isShowPS)}
                ></i>
                <div
                  id="validationServerUsernameFeedback"
                  class="invalid-feedback"
                >
                  Password does not meet requirements. Must be at least 8
                  characters long and include an uppercase letter and a number.
                </div>
              </div>
              <div class="mb-3 d-flex row color-secondry">
                <label
                  for="exampleInputPassword1"
                  class={`form-label ${
                    passValid.length ? "text-success" : "text-body-secondary"
                  }`}
                >
                  At least 8 characters
                </label>
                <label
                  for="exampleInputPassword1"
                  class={`form-label ${
                    passValid.Uchar ? "text-success" : "text-body-secondary"
                  }`}
                >
                  At least one capital letter
                </label>
                <label
                  for="exampleInputPassword1"
                  class={`form-label ${
                    passValid.Nchar ? "text-success" : "text-body-secondary"
                  }`}
                >
                  At least one number
                </label>
              </div>
              {passValid.Nchar && passValid.Uchar && passValid.length && (
                <div class="mb-4 has-validation position-relative">
                  <label for="exampleInputPassword1" class="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={isShowPSC ? "text" : "password"}
                    name="comfirmPassword"
                    validationError={"comfirmPassword"}
                    onChange={onChangeHandler}
                    class={`form-control rounded-1 ${
                      validationError.comfirmPassword && "is-invalid"
                    }`}
                    id="exampleInputPassword1"
                    required=""
                  />
                  <i
                    class={`passwordEye bi ${
                      isShowPSC ? "bi-eye-slash-fill" : "bi-eye-fill"
                    }`}
                    onClick={() => setShowPSC(!isShowPSC)}
                  ></i>
                  <div
                    id="validationServerUsernameFeedback"
                    class="invalid-feedback"
                  >
                    The passwords don’t match
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <button
              type="submit"
              class="btn col-5 btn-outline-primary p-2 rounded-2"
              onClick={prevHandler}
            >
              Back
            </button>
            <button
              type="submit"
              class="btn col-5 p-2 btn-primary rounded-2 text-light"
              disabled={isLoading}
            >
              {isLoading && (
                <Spinner animation="border" className="me-2" size="sm" />
              )}
              {"Next"}
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        onClose={() => setisFailure(false)}
        autoHideDuration={4000}
        open={isfailure}
      >
        <Alert severity="error" sx={{ color: "red" }}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Step5;
