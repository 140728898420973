import Header from "@/components/Header";
import Sidebar from "@/components/Sidebar";
import React from "react";
import "./Layout.scss";
import HeaderReport from "@/components/Header/HeaderReport";

function Layout({ children, heading, isHide, isReport = false }) {
  return (
    <div className="layout-container">
      <div className="layout-sidebar">
        <Sidebar />
      </div>
      {isReport ? (
        <div className="layout-right">
          {(heading !== undefined || !isHide) && (
            <div className="layout-header">
              <HeaderReport heading={heading} isHide={isHide} />
            </div>
          )}
          <div className="layout-children">{children}</div>
        </div>
      ) : (
        <div className="layout-right">
          {(heading !== undefined || !isHide) && (
            <div className="layout-header">
              <Header heading={heading} isHide={isHide} />
            </div>
          )}
          <div className="layout-children">{children}</div>
        </div>
      )}
    </div>
  );
}

export default Layout;
