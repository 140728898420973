import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function CreditCardLoader() {
  return (
    <div
          
  className='card p-3 mt-3'
  >
    <small className="fw-light text-muted">
      <Skeleton width={100} height={15} />
    </small>
    <div className="fs-4 fw-bold mb-0">
      <Skeleton width={150} height={30} />
    </div>

    <div className='row'>
            <div className='col-md-6'>
            <small className="fw-light text-muted">
      <Skeleton width={100} height={15} />
    </small>
            </div>
            <div className='col-md-6 d-flex justify-content-end'>
            <small className="fw-light text-muted">
      <Skeleton width={100} height={15} />
    </small>
            </div>
    </div>
  </div>
  )
}

export default CreditCardLoader