import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "../../Icons/home 2.png";
import Taxfluence from "../../assets/icon/SVG/SVG_ICONS/Taxfluence.svg";
import axios from "axios";
import "./Login.scss";

import {
  Box,
  Button,
  TextField,
  Typography,
  Hidden,
  Alert,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const defaultTheme = createTheme();

export default function ForgotPassword() {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isOTPVerified, setIsVerified] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [count,setCount] = useState(30);
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const [validationError, setValidationErrors] = useState({});
  const [isResendOTP, setResendOTP] = useState(false);
  const [passValid, setValidPass] = useState({
    length: false,
    Uchar: false,
    Nchar: false,
  });
  const [formData, setFormData] = useState({
    phone: "",
    otp: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    event.preventDefault();
    setIsError(false);
    setisSuccess(false);
    setMessage("");
    const { name, value } = event.target;

    if (name == "password") {
      if (/[A-Z]/.test(value)) {
        setValidPass((prev) => ({
          ...prev,
          ["Uchar"]: true,
        }));
      } else {
        setValidPass((prev) => ({
          ...prev,
          ["Uchar"]: false,
        }));
      }
      if (/[0-9]/.test(value)) {
        setValidPass((prev) => ({
          ...prev,
          ["Nchar"]: true,
        }));
      } else {
        setValidPass((prev) => ({
          ...prev,
          ["Nchar"]: false,
        }));
      }
      if (value.length > 7) {
        setValidPass((prev) => ({
          ...prev,
          ["length"]: true,
        }));
      } else {
        setValidPass((prev) => ({
          ...prev,
          ["length"]: false,
        }));
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitvalidate = (event) => {
    event.preventDefault();
    //API for post data to backend
    try {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/password`,
          formData
        )
        .then((res) => {
          const { data } = res;
          if (!data.success) {
            setIsError(true);
            setMessage(data.message);
            setLoading(false);
            return;
          }
          setIsVerified(true);
          setMessage(data.message);
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 1000);
          return;
        });
    } catch (error) {
      console.log(error);
      setMessage("Failed!");
      setIsError(true);
      setLoading(false);
      return;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setResendOTP(false);
    setCount(30);
    try {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/user/otp/send`,
          formData
        )
        .then((res) => {
          const { data } = res;
          if (!data.success) {
            setIsError(true);
            setMessage(data.message);
            setLoading(false);
            return;
          }
          setisSuccess(true);
          setMessage(data.message);
          setIsOTPSent(true);
          setLoading(false);
          //navigate("/dashboard");
          const newInterval = setInterval(() => {
            setCount((prev)=>prev-1)
          }, 1000);
          setTimeout(() => {
            setResendOTP(true);
          }, 30000);
          return;
        });
    } catch (error) {
      console.log(error);
      setMessage("Failed! to send OTP");
      setIsError(true);
      setLoading(false);
      return;
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        maxHeight: "100vh",
        display: "flex",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          minWidth: "100%",
          minHeight: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box
          item
          sx={{
            backgroundColor: "#B0D3FF",
            height: "100%",
            width: "130%",
            paddingTop: "8rem",
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Box
            component="img"
            alt="side-image"
            src={Home}
            sx={{
              width: "90%",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100",
            width: "100%",
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            justifyContent: {
              xs: "center",
            },
            marginTop: {
              sm: "2%",
              md: "0px",
            },
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={isSuccess}
            autoHideDuration={4000}
          >
            <Alert severity="success">{message}</Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mt: "30px" }}>
                <img
                  style={{ height: { xs: "1.4rem", md: "1.6rem" } }}
                  src={Taxfluence}
                />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "14px", sm: "18px" },
                  lineHeight: "36px",
                  height: "40px",
                  mt: "25px",
                }}
              >
                Let us help you to recover your password
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: {
                    xs: "12px",
                    sm: "15px",
                  },
                  lineHeight: "20px",
                  textAlign: "center",
                  color: "#404040",
                  mb: "25px",
                }}
              >
                We’ll send a code to validate your account
              </Typography>
            </Box>
            {isOTPSent ? (
              <form onSubmit={handleSubmitvalidate}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: { xs: 1, sm: 0 },
                    width: { xs: "100vw", sm: "55vw", md: "25vw" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      gap: "25px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ marginBottom: "7px" }}>Code</Typography>
                      <TextField
                        name="otp"
                        value={formData.otp}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        type="tel"
                        placeholder="The Code from your Email"
                        error={isError}
                        helperText={message}
                        disabled={isOTPVerified}
                      />
                    </Box>
                    <Box>
                      <div class="mb-2 position-relative">
                        <label for="exampleInputPassword1" class="form-label">
                          New Password
                        </label>
                        <input
                          type={isShow ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          class={`form-control rounded-1 ${
                            validationError.password && "is-invalid"
                          }`}
                          validationError={"password"}
                          onChange={handleChange}
                          id="exampleInputPassword1"
                          required=""
                        />
                        <i
                          class={`passwordEye bi ${
                            isShow ? "bi-eye-slash-fill" : "bi-eye-fill"
                          }`}
                          onClick={() => setShow(!isShow)}
                        ></i>
                        <div
                          id="validationServerUsernameFeedback"
                          class="invalid-feedback"
                        >
                          The passwords don’t match with characters patters
                        </div>
                      </div>
                      <div class="mb-3 d-flex row color-secondry">
                        <label
                          for="exampleInputPassword1"
                          class={`form-label ${
                            passValid.length
                              ? "text-success"
                              : "text-body-secondary"
                          }`}
                        >
                          At least 8 characters
                        </label>
                        <label
                          for="exampleInputPassword1"
                          class={`form-label ${
                            passValid.Uchar
                              ? "text-success"
                              : "text-body-secondary"
                          }`}
                        >
                          At least one capital letter
                        </label>
                        <label
                          for="exampleInputPassword1"
                          class={`form-label ${
                            passValid.Nchar
                              ? "text-success"
                              : "text-body-secondary"
                          }`}
                        >
                          At least one number
                        </label>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    {isOTPSent && (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#2C96EA",
                          margin: "50px 0 0 0",
                          fontWeight: "600",
                          textTransform: "none",
                          width: "100%",
                        }}
                        disabled={!isResendOTP}
                        onClick={handleSubmit}
                      >
                        {`Resend OTP ${count > 0 ? count : ""}`}
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#2C96EA",
                        margin: "50px 0 0 0",
                        fontWeight: "600",
                        textTransform: "none",
                        width: "100%",
                      }}
                    >
                      {isLoading && (
                        <Spinner
                          animation="border"
                          className="me-2"
                          size="sm"
                        />
                      )}
                      {"Reset Password"}
                    </Button>
                  </Box>
                </Box>
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: { xs: 1, sm: 0 },
                    width: { xs: "100vw", sm: "55vw", md: "25vw" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      gap: "25px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ marginBottom: "7px" }}>
                        Email Address / Phone
                      </Typography>
                      <TextField
                        name="phone"
                        onChange={handleChange}
                        placeholder="Email/Phone"
                        size="small"
                        fullWidth
                        error={isError}
                        helperText={message}
                      />
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "#2C96EA",
                      margin: "50px 0 0 0",
                      fontWeight: "600",
                      textTransform: "none",
                    }}
                  >
                    {isLoading && (
                      <Spinner animation="border" className="me-2" size="sm" />
                    )}
                    {"Send Code"}
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
