import React, { useEffect, useState } from "react";
import NavItem from "./NavItem";
import "./Sidebar.scss";
import actionIcon from "../../assets/icon/SVG/SVG_ICONS/Action.svg";
import editIcon from "../../assets/icon/SVG/SVG_ICONS/Edit.svg"
import adminIcon from "../../assets/icon/SVG/SVG_ICONS/User-Profile.svg"
import helpIcon from "../../assets/icon/SVG/SVG_ICONS/Help_Center.svg"
import homeIcon from "../../assets/icon/SVG/SVG_ICONS/Home.svg"
import bankIcon from "../../assets/icon/SVG/SVG_ICONS/Link-Bank.svg" 
import reportIcon from "../../assets/icon/SVG/SVG_ICONS/Reporting.svg";
import taxcalIcon from "../../assets/icon/SVG/SVG_ICONS/Calculator.svg"
import transIcon from "../../assets/icon/SVG/SVG_ICONS/Transaction.svg"
import humburgIcon from "../../assets/icon/SVG/SVG_ICONS/Hamburguer.svg"
import closeIcon from "../../assets/icon/SVG/SVG_ICONS/close.svg"
import Responsive_Navbar from "./Responsive_Navbar";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const [ActionToggle, setactionToggle] = useState(true);
  const [isCloseHum, setCloseHum] = useState(false);
  const [routeLocation,setLocation] = useState("/");

  useEffect(()=>{
    const link = window.location.pathname
    setLocation(link);
  },[window.location.href])
  const navigate = useNavigate();

  const ToggleHandler = () => {
    setactionToggle((prev) => !prev);
  };
  const closeHumHandler = () => {
    setCloseHum((prev) => !prev);
  };

  const Style = {
    containerWidth: !ActionToggle
      ? {
          width: "5vw",
          padding: 0,
          alignItems: "center",
          paddingTop: "1rem",
        }
      : {},
    bottomStyle: !ActionToggle ? { padding: 0,color:"#000", } : {},
    active : {
      backgroundColor: "#F3F4F6",
      borderRadius:"0.4rem",
      color:"#000",
    }
  };

  const sidebar = [
    { start_icon: homeIcon, page_name: "Home", end_icon: "", link: "/" },
    {
      start_icon: transIcon,
      page_name: "Transactions",
      end_icon: "",
      link: "/transaction",
    },
    {
      start_icon: reportIcon,
      page_name: "Reports",
      end_icon: "",
      link: "/reports",
    },
    // {
    //   start_icon: taxcalIcon,
    //   page_name: "Tax Calculators",
    //   end_icon: editIcon,
    //   link: "/taxcalculation",
    // },
    // {
    //   start_icon: helpIcon,
    //   page_name: "Help",
    //   end_icon: editIcon,
    //   link: "/help",
    // },
  ];

  const sidebar_bottom = [
    {
      start_icon: adminIcon,
      page_name: "Settings",
      end_icon: "",
      link: "/settings",
    },
    {
      start_icon: bankIcon,
      page_name: "Account(s)",
      end_icon: "",
      link: "/linkaccount",
    },
  ];

  return (
    <>
      <div className="logo-container">
        {!isCloseHum && (
          <div className="humburg" onClick={closeHumHandler}>
            <img src={humburgIcon} />
          </div>
        )}
        <div>
          <h5 className="logo-heading">Taxfluence</h5>
        </div>
      </div>
      {isCloseHum && <Responsive_Navbar setClose={setCloseHum} />}
      <div
        className="sidebar-container  side-mobile-contaner"
        style={Style.containerWidth}
      >
        <div className="container-top">
          <div className="logo-container">
            <div className="humburg">
              <img src={humburgIcon} />
            </div>
            {ActionToggle && <h4 className="logo-heading">Taxfluence</h4>}
            <div className="close-icon">
              <img src={closeIcon} />
            </div>
            <div className="action-btn" onClick={ToggleHandler}>
              <img src={actionIcon} />
            </div>
          </div>
          {sidebar?.map((ele,id) => {
            const isClicable = routeLocation == ele.link
            return (
              <div key={id} id={id} style={ isClicable? Style.active:{}} onClick={()=>navigate(`${ele.link}`)}>
                <NavItem
                  start_icon={ele.start_icon}
                  page_name={ele.page_name}
                  end_icon={ele.end_icon}
                  ActionToggle={ActionToggle}
                />
              </div>
            );
          })}
        </div>
        <div className="container-bottom">
          {ActionToggle && <h6 className="bottom-text">My Account</h6>}
          <div className="bottom-element" style={Style.bottomStyle}>
            {sidebar_bottom?.map((ele,id) => {
              const isClicable = routeLocation == ele.link
              return (
                <div id={id} key={id} style={ isClicable? Style.active:{}} onClick={()=>navigate(`${ele.link}`)}>
                  <NavItem
                    start_icon={ele.start_icon}
                    page_name={ele.page_name}
                    end_icon={ele.end_icon}
                    ActionToggle={ActionToggle}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
