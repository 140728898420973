import React, { useState } from "react";
import {
  Box,
  Typography,
  Radio,
  TextField,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import "./Signup.scss";
import { trackUserMPSignup } from "services/mixpanel";

function Step3({
  formData,
  activeStep,
  setActivesteps,
  prevHandler,
  setFormData,
}) {
  const [isError, setError] = useState(false);
  const [msg, setMsg] = useState(false);
  const Data = [
    "Actor or model",
    // "Adult entertainer",
    "Content creator",
    // "Makeup artist",
    // "Musician",
    // "Photographer/Videographer",
    // "Physical trainer",
    "Other"
  ];
  const Headings = [
    "What profession did you earn income with?",
    "This info allows us to find tax write-offs for the months you’ve been working.",
  ];

  const onChangeHandler = (e, idx) => {
    const name = "What profession did you earn income with?";
    const value = Data[idx];
    const prevAns = formData[name] ? formData[name] + ", " : "";
    const question = formData.Questions ? formData.Questions : {};
    setFormData((prev) => ({
      ...prev,
      Questions: {
        ...question,
        [name]: prevAns + value,
      },
    }));
  };

  const nextHandler = (event) => {
    event.preventDefault();
    console.log(formData);
    if (formData.Questions["What profession did you earn income with?"]) {
      setActivesteps((prev) => prev + 1);
      trackUserMPSignup("Signup Step 2","Profession",formData.Questions["What profession did you earn income with?"])
    }
    setError(true);
    setMsg("Please select atleast one.");
  };
  const setShow = () => {
    setError(false);
  };

  return (
    <div class="container step-container">
      <div class="row justify-content-center">
        <form onSubmit={nextHandler}>
          <div className="form-container">
            <div class="text-center col-12 d-flex-col  mb-3 ">
              <div className="fw-medium fs-6">{Headings[0]}</div>
              <div className="sub-heading">{Headings[1]}</div>
            </div>
            <div className="mb-4 occ-container" >
              {Data.map((ele, id) => {
                return (
                  <>
                    <div
                      key={id}
                      class={`col-12 mb-3 p-3 border rounded-1  ${
                        isError && "border-danger"
                      }`}
                      onClick={(ele) => onChangeHandler(ele, id)}
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="What profession did you earn income with?"
                        id="inlineRadio2"
                        // onChange={(ele) => onChangeHandler(ele, id)}
                        checked={
                          formData.Questions[
                            "What profession did you earn income with?"
                          ] == ele
                        }
                        required
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-plus-lg ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                        />
                      </svg>
                      <label class="form-check-label ms-2" for="exampleCheck1">
                        {ele}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <button
              type="submit"
              class="btn col-5 btn-outline-primary p-2 rounded-2"
              onClick={prevHandler}
            >
              Back
            </button>
            <button
              type="submit"
              class="btn col-5 p-2 btn-primary rounded-2 text-light"
              //onClick={nextHandler}
            >
              Next
            </button>
          </div>
        </form>
      </div>

      <Snackbar onClose={setShow} autoHideDuration={12000} open={isError}>
        <Alert severity="error" sx={{ color: "red" }}>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Step3;
