import React, { useState, useEffect } from "react";
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import { redirect, useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { useAppContext } from "Store/AppContext";
import Spinner from "react-bootstrap/Spinner";
import Loading from "@/components/Loader/Loading";
import { trackUserMPSignup } from "services/mixpanel";

function Step7({
  formData,
  activeStep,
  setActivesteps,
  prevHandler,
  setFormData,
}) {
  const [loading, setLoading] = useState(false);
  const [store, dispatch] = useAppContext();
  const [isSucc, setSucc] = useState(false);
  const [linkToken, setLinkToken] = useState("");
  const [isError, setError] = useState(false);
  const [publicToken, setPublicToken] = useState("");
  const [msg, setMsg] = useState("");
  const [isCardAdded, setCardAdded] = useState(false);
  const navigate = useNavigate();
  const headers = {
    Authorization: `Bearer ${formData.token}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    async function fetch() {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/create-link-token`,
        {},
        { headers }
      );
      setLinkToken(response.data.link_token);
    }
    fetch();
  }, []);

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      setLoading(true);
      setSucc(true);
      setMsg("Please wait data is fetching from Plaid!");
      let accessTokenResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/v1/banking/create-access-token`,
        { public_token: public_token },
        { headers }
      );
      setLoading(false);
      setCardAdded(true);
      window.location.href = "/";
    },
  });
  const AddCheckingAccount = () => {
    window.alert("AddCheckingAccount");
  };
  const Data = [
    { name: "Add Checking Accounts", func: open },
    { name: "Add Credit Card", func: open },
  ];

  const Headings = [
    "Let’s find your write-offs",
    "Link the accounts you use for work and we’ll automatically scan your past 18 months of purchases for eligible write-offs.",
  ];

  const submitHandler = (event) => {
    event.preventDefault();
    navigate("/");
  };

  useEffect(()=>{
    trackUserMPSignup("Signup Step 5","Plaid connection","Credit card added")
  },[])

  return loading ? (
    <Loading email={formData.email} />
  ) : (
    <div class="container step-container">
      <div class="row justify-content-center">
        <form onSubmit={submitHandler}>
          <div className="form-container">
            <div class="text-center col-12 d-flex-col  mb-3 ">
              <div className="fw-medium fs-6">{Headings[0]}</div>
              <div className="sub-heading">{Headings[1]}</div>
            </div>
            {Data.map((ele,idx) => {
              return (
                <>
                  <div
                    key={idx}
                    class="col-12 mb-3 p-3 border rounded-1"
                    disabled={!ready}
                    onClick={() => ele.func()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-lg ms-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                    <label class="form-check-label ms-2" for="exampleCheck1">
                      {ele.name}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-12 d-flex justify-content-between">
            <button
              type="submit"
              class="btn col-5 btn-outline-primary p-2 rounded-2"
              onClick={prevHandler}
            >
              Back
            </button>
            <button
              type="submit"
              disabled={!isCardAdded}
              class="btn col-5 p-2 btn-primary rounded-2 text-light"
              //onClick={() => setActivesteps((prev) => prev + 1)}
            >
              {loading && (
                <Spinner animation="border" className="me-2" size="sm" />
              )}
              {"Submit"}
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        onClose={() => setError(false)}
        autoHideDuration={30000}
        open={isError}
      >
        <Alert severity="error" sx={{ color: "red" }}>
          {msg}
        </Alert>
      </Snackbar>
      <Snackbar
        onClose={() => setSucc(false)}
        autoHideDuration={30000}
        open={isSucc}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
    </div>
  );
}

export default Step7;
