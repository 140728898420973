import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Notification from "./Notifications";
import LinkedAccounts from "./LinkedAccounts";
import rightArrowIcon from "../../assets/icon/SVG/rightArrow.svg";
import leftArrowIcon from "../../assets/icon/SVG/leftArrow.svg";
import Payment from "./Payment";
import Profile from "./Profile/Profile";
import User from "../../Icons/User-Profile.png";
import LinkedAcc from "../../Icons/Link-Bank.png";
import Bell from "../../Icons/bell.png";
import React, { useEffect, useRef, useState } from "react";
import { trackUserMP } from "services/mixpanel";


function Settings() {
  const [value, setValue] = useState("1");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    trackUserMP("Settings Page");
  }, []);

  useEffect(() => {
    window.window.location.pathname == "/linkaccount"
      ? setValue("2")
      : setValue("1");
  }, [window.window.location.pathname]);
  const handleChangeTab = (newValue) => {
    setValue(newValue);
  };

  const useWidth = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      useWidth.current = window.innerWidth;
      if(useWidth.current < 500){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: { xs: 0, sm: 0 },
        paddingRight: { xs: 0, sm: 0 },
      }}
    
    >
      <Box sx={{ width: "100%", typography: "body1" }}   ref={useWidth}>
        <TabContext value={value}>
          <TabList
            onChange={(event, newValue) => handleChangeTab(newValue)}
            indicatorColor="primary"
            textColor="inherit"
            sx={{
              padding: 0,
              margin: 0,
              textDecoration: "none",
            }}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons={isMobile}
          >
            <Tab
              icon={
                <img
                  style={{
                    width: "1.2rem",
                    display: "flex",
                    height: "1.2rem",
                    gap: "15px",
                  }}
                  src={User}
                />
              }
              iconPosition="start"
              label="My Profile"
              value="1"
              sx={{
                fontWeight: "550",
                textTransform: "none",
                fontSize: "0.95rem",
              }}
            />
            <Tab
              icon={
                <img
                  style={{
                    width: "1.2rem",
                    display: "flex",
                    height: "1.2rem",
                    gap: "15px",
                  }}
                  src={LinkedAcc}
                />
              }
              iconPosition="start"
              label="Linked Account(s)"
              value="2"
              sx={{
                fontWeight: "550",
                textTransform: "none",
                fontSize: "0.95rem",
              }}
            />
            <Tab
              icon={
                <img
                  style={{
                    width: "1.2rem",
                    display: "flex",
                    height: "1.2rem",
                    gap: "15px",
                  }}
                  src={Bell}
                />
              }
              iconPosition="start"
              label="Notifications"
              value="3"
              sx={{
                fontWeight: "550",
                textTransform: "none",
                fontSize: "0.95rem",
              }}
            />
          </TabList>

          <TabPanel value="1" style={{ padding: 0, paddingTop: "1rem" }}>
            <Profile />
          </TabPanel>
          <TabPanel value="2" style={{ padding: 0, paddingTop: "1rem" }}>
            <LinkedAccounts />
          </TabPanel>
          <TabPanel value="3" style={{ padding: 0, paddingTop: "1rem" }}>
            <Notification />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default Settings;
