import React, { useEffect } from 'react'
import Tabs from '@/components/Tabs'

function index() {

  return (
    <Tabs/>
  )
}

export default index